import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import OperationService from '../../../../services/operation.service'
import CompanyService from '../../../../services/company.service'
import PeriodService from '../../../../services/period.service'
import UserService from '../../../../services/user.service'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Column, ColumnInstance } from 'react-table'
import {
  getTableState,
  setTableState,
  getHiddenColumns,
} from '../../../../helpers/utils'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Option } from '../../../../store/types'
import TableControlled from '../../../Table/TableControlled'
import OperationsToolbar from '../partials/OperationsToolbar'
import { Stack } from '@mui/system'
import { pick } from 'lodash'
import * as XLSX from 'xlsx'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import {
  CircularProgress,
  Grid,
  IconButton,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import { Period } from '../../../../store/Period/types'
import { UserFilter } from '../../../../store/User/types'
import { OperationList } from '../../../../store/Operation/types'
import moment from 'moment'
import OperationDetailsDialog from '../partials/OperationDetailsDialog'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'

type OperationListProps = {
  path: string
}

const OperationsList: FunctionComponent<OperationListProps> = ({ path }) => {
  const tableName = 'operations'
  const searchState = getTableState(tableName, 'search')

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredOperationList, setFilteredOperationList] = useState<
    OperationList[]
  >([])
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const fetchIdRef = useRef(0)
  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [searchValue, setSearchValue] = useState<string>(
    searchState ? searchState : '',
  )

  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])

  const [filterOperationTypes, setFilterOperationTypes] = useState<Option[]>([])
  const [selectedFilterOperationTypes, setSelectedFilterOperationTypes] =
    useState<Option[]>([])

  const [operationId, setOperationId] = useState<number | null>(null)

  const [users, setUsers] = useState<UserFilter[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([])
  const [filteredUsers, setFilteredUsers] = useState<Option[]>([])

  const [periodValue, setPeriodValue] = useState<string>('all')
  const [periods, setPeriods] = useState<Period[]>([])

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')

  const [dateFrom, setDateFrom] = useState<Date | null>(null)
  const [timeFrom, setTimeFrom] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [dateTo, setDateTo] = useState<Date | null>(null)
  const [timeTo, setTimeTo] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )

  const [totalXp, setTotalXp] = useState<number>(0)
  const [totalVp, setTotalVp] = useState<number>(0)
  const [totalEp, setTotalEp] = useState<number>(0)

  const [openOperationDetailsDialog, setOperationDetailsDialogOpen] =
    useState(false)

  const handleOperationDetailsDialogClickOpen = (operationId: number) => {
    setOperationId(operationId)
    setOperationDetailsDialogOpen(true)
  }

  const handleOperationDetailsDialogClose = () => {
    setOperationDetailsDialogOpen(false)
  }

  const generateTableColumns = useCallback(
    (operation: OperationList[]) => {
      const columns = []
      columns.push(
        {
          accessor: 'id',
          Header: t('pages.operations.table.id').toString(),
          width: 70,
        },
        {
          accessor: 'operationTypeName',
          Header: t('pages.operations.table.operationTypeName').toString(),
          width: 170,
        },
        {
          accessor: 'operationName',
          Header: t('pages.operations.table.operationName').toString(),
          width: 500,
        },
        {
          accessor: 'operationDate',
          Header: t('pages.operations.table.operationDate').toString(),
          width: 160,
        },
        {
          accessor: 'userCentralId',
          Header: t('pages.operations.table.userCentralId').toString(),
          width: 120,
        },
        {
          accessor: 'userFirstname',
          Header: t('pages.operations.table.userFirstname').toString(),
          width: 150,
        },
        {
          accessor: 'userLastname',
          Header: t('pages.operations.table.userLastname').toString(),
          width: 160,
        },
        {
          accessor: 'authorCentralId',
          Header: t('pages.operations.table.authorCentralId').toString(),
          width: 100,
        },
        {
          accessor: 'authorFirstname',
          Header: t('pages.operations.table.authorFirstname').toString(),
          width: 150,
        },
        {
          accessor: 'authorLastname',
          Header: t('pages.operations.table.authorLastname').toString(),
          width: 160,
        },
        {
          accessor: 'actions',
          Header: t('pages.operations.table.actions').toString(),
          disableSortBy: true,
          sticky: 'right',
          width: 55,
          Cell: (params: any) => (
            <Grid container>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={t('pages.operations.table.preview')}>
                  <IconButton
                    aria-label="show"
                    size="small"
                    style={{
                      padding: 0,
                      marginRight: '5px',
                    }}
                    onClick={() =>
                      handleOperationDetailsDialogClickOpen(
                        params.row.original.id,
                      )
                    }
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
      )
      isXpPointsVisible() &&
        columns.splice(-1, 0, {
          accessor: 'xpValue',
          Header: t('pages.operations.table.xpValue').toString(),
          disableSortBy: false,
          sticky: '',
          width: 80,
          Cell: (params: any) => (
            <Stack sx={{ color: params.row.original.xpValue < 0 ? 'red' : '' }}>
              {params.row.original.xpValue}
            </Stack>
          ),
        })
      isVpPointsVisible() &&
        columns.splice(-1, 0, {
          accessor: 'vpValue',
          Header: t('pages.operations.table.vpValue').toString(),
          disableSortBy: false,
          sticky: '',
          width: 80,
          Cell: (params: any) => (
            <Stack sx={{ color: params.row.original.vpValue < 0 ? 'red' : '' }}>
              {params.row.original.vpValue}
            </Stack>
          ),
        })
      isEpPointsVisible() &&
        columns.splice(-1, 0, {
          accessor: 'epValue',
          Header: t('pages.operations.table.epValue').toString(),
          disableSortBy: false,
          sticky: '',
          width: 80,
          Cell: (params: any) => (
            <Stack sx={{ color: params.row.original.epValue < 0 ? 'red' : '' }}>
              {params.row.original.epValue}
            </Stack>
          ),
        })
      return columns
    },
    [t],
  )

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)

        const filterOperationTypesResponse =
          await OperationService.getOperationTypes()

        if (filterOperationTypesResponse.data.operationTypes) {
          const multiSelectOptions: Option[] = []
          filterOperationTypesResponse.data.operationTypes.forEach(
            (operation) =>
              multiSelectOptions.push({
                value: operation.id,
                label: operation.name,
              }),
          )
          setFilterOperationTypes(multiSelectOptions)
          const filterOperationTypesState = getTableState(
            tableName,
            'filterOperationTypes',
          )
          if (filterOperationTypesState) {
            setSelectedFilterOperationTypes(
              JSON.parse(filterOperationTypesState),
            )
          } else {
            setSelectedFilterOperationTypes(multiSelectOptions)
          }
        }

        const periodListResponse = await PeriodService.getPeriodList()

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
          const periodState = getTableState(tableName, 'period')
          if (periodState) {
            setPeriodValue(periodState)
          } else {
            let activePeriod = null
            activePeriod = periodListResponse.data.periods.find(
              (period) => period.isActive,
            )
            setPeriodValue(activePeriod ? activePeriod.id.toString() : 'all')
          }
        }

        const operationListResponse = await CompanyService.getCompanyList()

        if (operationListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          operationListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)

          const selectedCompaniesState = getTableState(
            tableName,
            'selectedCompaniesState',
          )
          if (selectedCompaniesState) {
            setSelectedCompanies(JSON.parse(selectedCompaniesState))
          } else {
            setSelectedCompanies(multiSelectOptions)
          }
        }

        const userListResponse = await UserService.getUserListFilter()
        if (userListResponse.data.users) {
          const multiSelectOptions: Option[] = []
          userListResponse.data.users.forEach((user) =>
            multiSelectOptions.push({
              value: user.id,
              label: `${user.lastname} ${user.firstname}`,
            }),
          )
          setUsers(userListResponse.data.users)

          const selectedUsersState = getTableState(
            tableName,
            'selectedUsersState',
          )
          if (selectedUsersState) {
            setSelectedUsers(JSON.parse(selectedUsersState))
          } else {
            setSelectedUsers(multiSelectOptions)
          }

          const filteredUsersState = getTableState(
            tableName,
            'filteredUsersState',
          )
          if (filteredUsersState) {
            setFilteredUsers(JSON.parse(filteredUsersState))
          } else {
            setFilteredUsers(multiSelectOptions)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
  }, [t])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)

          const dateTimeFrom = dateFrom
            ? moment(dateFrom).format('YYYY-MM-DD') +
              ' ' +
              moment(timeFrom).format('HH:mm') +
              ':00'
            : null

          const dateTimeTo = dateTo
            ? moment(dateTo).format('YYYY-MM-DD') +
              ' ' +
              moment(timeTo).format('HH:mm') +
              ':59'
            : null
          const page = ++pageIndex
          const operationListResponse = await OperationService.getOperationList(
            selectedFilterOperationTypes.map((operationType) => ({
              id: operationType.value,
            })),
            selectedCompanies.map((company) => ({ id: company.value })),
            selectedUsers.map((user) => ({ id: user.value })),
            periodValue === 'all' ? null : parseInt(periodValue),
            dateTimeFrom,
            dateTimeTo,
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )
          if (operationListResponse.data) {
            if (isXpPointsVisible())
              setTotalXp(operationListResponse.data.totalXp)
            if (isVpPointsVisible())
              setTotalVp(operationListResponse.data.totalVp)
            if (isEpPointsVisible())
              setTotalEp(operationListResponse.data.totalEp)
          }

          if (operationListResponse.data.operations) {
            setFilteredOperationList(operationListResponse.data.operations)
            setTableColumns(
              generateTableColumns(operationListResponse.data.operations),
            )
            setTotalCount(operationListResponse.data.totalCount)
            setPageCount(
              Math.ceil(operationListResponse.data.totalCount / pageSize),
            )
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    [
      t,
      generateTableColumns,
      searchValue,
      periodValue,
      selectedCompanies,
      selectedUsers,
      selectedFilterOperationTypes,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
    ],
  )

  const handleFilterSelectedOperationtypesChange = (
    operationTypes: Option[],
  ) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setTableState(
      tableName,
      'filterOperationTypes',
      JSON.stringify(operationTypes),
    )
    setSelectedFilterOperationTypes(operationTypes)
  }

  const handleSelectedCompaniesChange = (companies: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setSelectedCompanies(companies)
    setTableState(
      tableName,
      'selectedCompaniesState',
      JSON.stringify(companies),
    )

    const filteredUsersForMultiselect = users
      .filter((user) =>
        companies.map((company) => company.value).includes(user.companyId),
      )
      .map((u) => ({
        value: u.id,
        label: `${u.lastname} ${u.firstname}`,
      }))
    setFilteredUsers(filteredUsersForMultiselect)
    setTableState(
      tableName,
      'filteredUsersState',
      JSON.stringify(filteredUsersForMultiselect),
    )
    setSelectedUsers(filteredUsersForMultiselect)
    setTableState(
      tableName,
      'selectedUsersState',
      JSON.stringify(filteredUsersForMultiselect),
    )
  }
  const handleSelectedUsersChange = (users: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setSelectedUsers(users)
    setTableState(tableName, 'selectedUsersState', JSON.stringify(users))
  }

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const dateTimeFrom = dateFrom
        ? moment(dateFrom).format('YYYY-MM-DD') +
          ' ' +
          moment(timeFrom).format('HH:mm') +
          ':00'
        : null

      const dateTimeTo = dateTo
        ? moment(dateTo).format('YYYY-MM-DD') +
          ' ' +
          moment(timeTo).format('HH:mm') +
          ':59'
        : null

      const operationListResponse = await OperationService.getOperationList(
        selectedFilterOperationTypes.map((operationType) => ({
          id: operationType.value,
        })),
        selectedCompanies.map((company) => ({ id: company.value })),
        selectedUsers.map((user) => ({ id: user.value })),
        periodValue === 'all' ? null : parseInt(periodValue),
        dateTimeFrom,
        dateTimeTo,
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
      )

      const dataOperation = operationListResponse.data.operations
      if (dataOperation) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'id',
            'operationTypeName',
            'operationName',
            'operationDate',
            'userCentralId',
            'userFirstname',
            'userLastname',
            'authorCentralId',
            'authorFirstname',
            'authorLastname',
            ...(isXpPointsVisible() ? ['xpValue'] : []),
            ...(isVpPointsVisible() ? ['vpValue'] : []),
            ...(isEpPointsVisible() ? ['epValue'] : []),
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredOperationData = dataOperation.map((operation) => {
          return pick(operation, visibleColumns)
        })

        const translatedHeaders = {
          id: t('excel.operations.id'),
          operationTypeName: t('excel.operations.operationTypeName'),
          operationName: t('excel.operations.operationName'),
          operationDate: t('excel.operations.operationDate'),
          userCentralId: t('excel.operations.userCentralId'),
          userFirstname: t('excel.operations.userFirstname'),
          userLastname: t('excel.operations.userLastname'),
          authorCentralId: t('excel.operations.authorCentralId'),
          authorFirstname: t('excel.operations.authorFirstname'),
          authorLastname: t('excel.operations.authorLastname'),
          ...(isXpPointsVisible()
            ? { xpValue: t('excel.operations.xpValue') }
            : {}),
          ...(isVpPointsVisible()
            ? { vpValue: t('excel.operations.vpValue') }
            : {}),
          ...(isEpPointsVisible()
            ? { epValue: t('excel.operations.epValue') }
            : {}),
        }

        const headers = [
          Object.keys(filteredOperationData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredOperationData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredOperationData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('operations')}
              sx={{ marginLeft: 'auto', marginTop: '8px', marginBottom: '7px' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <OperationsToolbar
            periods={periods}
            periodValue={periodValue}
            users={filteredUsers}
            selectedUsers={selectedUsers}
            filterOperationTypes={filterOperationTypes}
            selectedFilterOperationTypes={selectedFilterOperationTypes}
            companies={companies}
            selectedCompanies={selectedCompanies}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              setTableState(tableName, 'search', event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            setSelectedCompanies={(companies) =>
              handleSelectedCompaniesChange(companies)
            }
            setSelectedFilterOperationTypes={(operationTypes) =>
              handleFilterSelectedOperationtypesChange(operationTypes)
            }
            setSelectedUsers={(users: Option[]) => {
              handleSelectedUsersChange(users)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
              setTableState(tableName, 'period', event.target.value)
              setDateFrom(null)
              setTimeFrom(new Date(new Date().setHours(0, 0, 0, 0)))
              setDateTo(null)
              setTimeTo(new Date(new Date().setHours(0, 0, 0, 0)))
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            dateFrom={dateFrom}
            timeFrom={timeFrom}
            dateTo={dateTo}
            timeTo={timeTo}
            setDateFrom={(date) => {
              setDateFrom(date)
            }}
            setTimeFrom={(time) => {
              setTimeFrom(time)
            }}
            setDateTo={(date) => {
              setDateTo(date)
            }}
            setTimeTo={(time) => {
              setTimeTo(time)
            }}
            totalXp={totalXp}
            totalVp={totalVp}
            totalEp={totalEp}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredOperationList}
            height="calc(100vh - 330px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'id',
              'authorCentralId',
              'authorFirstname',
              'authorLastname',
              ...(isXpPointsVisible() ? ['xpValue'] : []),
              ...(isVpPointsVisible() ? ['vpValue'] : []),
              ...(isEpPointsVisible() ? ['epValue'] : []),
            ]}
            toggleVisibility={setColumnsVisibility}
          />
          {operationId && (
            <OperationDetailsDialog
              open={openOperationDetailsDialog}
              handleClose={handleOperationDetailsDialogClose}
              operationId={operationId}
            />
          )}
        </>
      )}
    </>
  )
}

export default OperationsList
