import axios from 'axios'
import {
  LoyaltyOperationDetailsResponse,
  LoyaltyOperationListReponse,
  LoyaltyOperationTypesFilterListResponse,
} from '../store/LoyaltyOperation/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getLoyaltyOperationList = async (
  operationTypes: { id: number }[],
  companies: { id: number }[],
  users: { id: number }[],
  loyaltyPeriod: number | null,
  operationDateFrom: string | null,
  operationDateTo: string | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<LoyaltyOperationListReponse> => {
  return axios.post(
    API_URL + '/api/admin/loyalty/operation/list',
    {
      operationTypes,
      companies,
      users,
      loyaltyPeriod,
      operationDateFrom,
      operationDateTo,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getLoyaltyOperationDetails = async (
  operationId: number,
): Promise<LoyaltyOperationDetailsResponse> => {
  return await axios.get(
    API_URL + `/api/admin/loyalty/operation/preview/${operationId}`,
    {
      headers: authHeader(),
    },
  )
}

const getLoyaltyOperationTypes =
  (): Promise<LoyaltyOperationTypesFilterListResponse> => {
    return axios.get(
      API_URL + '/api/admin/loyalty/operation/filter/type/list',
      {
        headers: authHeader(),
      },
    )
  }

const exportedObject = {
  getLoyaltyOperationList,
  getLoyaltyOperationDetails,
  getLoyaltyOperationTypes,
}

export default exportedObject
