import { IconButton, Stack } from '@mui/material'
import {
  BorderLinearProgress,
  PhoneAppBarDetails,
  PhoneCard,
  PhoneContent,
} from '../styles'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ReactComponent as ListIcon } from '../../../assets/images/icons/list.svg'
import { ReactComponent as DifficultyLevelIcon } from '../../../assets/images/icons/difficulty-level.svg'
import { ReactComponent as RulesIcon } from '../../../assets/images/icons/rules.svg'
import { UserChallengesPreview } from '../../../store/Game/types'
import { thousandsSeparator } from '../../../helpers/utils'
import moment from 'moment'

type ChallengeOptionsScreenProps = {
  userId: number
  challengeId: number
  challenge: UserChallengesPreview
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>
}

const ChallengeOptionsScreen: React.FunctionComponent<
  ChallengeOptionsScreenProps
> = ({ userId, challengeId, challenge, setCurrentScreen }) => {
  const { t } = useTranslation()
  const getChallengeStatus = (
    dateFrom: string,
    dateTo: string,
    completed: boolean,
  ) => {
    if (moment(new Date()).isBefore(dateFrom) && completed === false)
      return t(
        'pages.game.phone.screens.challengesScreen.challengeStatus.notStarted',
      )
    if (moment(new Date()).isBetween(dateFrom, dateTo) && completed === false)
      return t(
        'pages.game.phone.screens.challengesScreen.challengeStatus.ongoing',
      )
    if (moment(new Date()).isAfter(dateTo) && completed === false)
      return t(
        'pages.game.phone.screens.challengesScreen.challengeStatus.finished',
      )
    if (moment(new Date()).isAfter(dateTo) && completed === true)
      return t(
        'pages.game.phone.screens.challengesScreen.challengeStatus.completed',
      )
  }
  return (
    <>
      <PhoneAppBarDetails>
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          px="8px"
        >
          <IconButton onClick={() => setCurrentScreen('challenges')}>
            <ArrowBackIcon htmlColor="#FFD748" />
          </IconButton>
          {challenge.name}
          <Stack width={40}></Stack>
        </Stack>
      </PhoneAppBarDetails>
      <PhoneContent>
        <PhoneCard style={{ marginTop: '36px' }}>
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={'flex-end'}
            fontSize={'12px'}
            borderBottom="1px solid #e4e4e4"
            paddingBottom={'10px'}
          >
            {t('pages.game.phone.screens.challengeOptionsScreen.timeScope')}{' '}
            <strong style={{ marginLeft: '5px' }}>{challenge.timeScope}</strong>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            fontSize="12px"
            justifyContent="space-between"
            alignItems="center"
            fontWeight="600"
            marginTop="10px"
            marginBottom="4px"
          >
            <div>
              {getChallengeStatus(
                challenge.validFrom,
                challenge.validTo,
                challenge.isCompleted,
              )}{' '}
              &#40;
              {challenge.challengeLevelCode
                ? challenge.challengeLevelCode
                : 'N/D'}
              &#41;
            </div>
            <div>{challenge.challengePercentResultValue}%</div>
          </Stack>
          <BorderLinearProgress
            variant="determinate"
            value={
              challenge.challengePercentResultValue <= 100
                ? challenge.challengePercentResultValue
                : 100
            }
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor:
                  challenge.challengePercentResultValue < 100 ? 'red' : 'green',
              },
            }}
          />
          <Stack
            display="flex"
            flexDirection="row"
            fontSize="12px"
            justifyContent="space-between"
            alignItems="center"
            fontWeight="400"
            marginTop="4px"
            marginBottom="16px"
          >
            <div>
              {t('pages.game.phone.screens.challengeOptionsScreen.resultValue')}{' '}
              {thousandsSeparator(challenge.challengeResultValue)}
            </div>
            <div>
              {t('pages.game.phone.screens.challengeOptionsScreen.planValue')}{' '}
              {thousandsSeparator(challenge.challengeTargetValue)}
            </div>
          </Stack>
        </PhoneCard>
        <PhoneCard sx={{ cursor: 'pointer' }} onClick={() => {}}>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Stack
              fontWeight={'bold'}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
            >
              <Stack
                width={25}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
              >
                <RulesIcon />
              </Stack>
              <Stack marginLeft={'10px'}>
                {t('pages.game.phone.screens.challengeOptionsScreen.rules')}
              </Stack>
            </Stack>

            <ExpandMoreIcon />
          </Stack>
        </PhoneCard>
        <PhoneCard sx={{ cursor: 'pointer' }} onClick={() => {}}>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Stack
              fontWeight={'bold'}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
            >
              <Stack
                width={25}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
              >
                <DifficultyLevelIcon />
              </Stack>
              <Stack marginLeft={'10px'}>
                {t(
                  'pages.game.phone.screens.challengeOptionsScreen.difficultyLevel',
                )}
              </Stack>
            </Stack>

            <ExpandMoreIcon />
          </Stack>
        </PhoneCard>
        <PhoneCard
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setCurrentScreen('challenge-stores')
          }}
        >
          <Stack
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Stack
              fontWeight={'bold'}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
            >
              <Stack
                width={25}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
              >
                <ListIcon />
              </Stack>
              <Stack marginLeft={'10px'}>
                {t(
                  'pages.game.phone.screens.challengeOptionsScreen.storesList',
                )}
              </Stack>
            </Stack>

            <ChevronRightIcon />
          </Stack>
        </PhoneCard>
      </PhoneContent>
    </>
  )
}

export default ChallengeOptionsScreen
