import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import OperationService from '../../../../services/operation.service'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'
import { DetailsData } from '../../../../store/Details/types'
import DetailsDialog from '../../../shared/DetailsDialog'

type OperationDetailsDialogProps = {
  operationId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const OperationDetailsDialog: React.FunctionComponent<
  OperationDetailsDialogProps
> = ({ operationId, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [detailsParamsForDialog, setDetailsParamsForDialog] =
    useState<DetailsData>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const operationDetailsResponse =
          await OperationService.getOperationDetails(operationId)
        if (operationDetailsResponse.data) {
          const operationsDetailsParams: DetailsData = {
            title: {
              label: `${t(
                'pages.operations.operationDetailsDialog.id',
              )}: ${operationId.toString()}`,
              value: operationDetailsResponse.data.operationTypeName,
            },
            data: [
              {
                label: t(
                  'pages.operations.operationDetailsDialog.operationTypeId',
                ),
                value: operationDetailsResponse.data.operationTypeId,
              },
              {
                label: t(
                  'pages.operations.operationDetailsDialog.operationTypeName',
                ),
                value: operationDetailsResponse.data.operationTypeName,
              },
              {
                label: t(
                  'pages.operations.operationDetailsDialog.operationName',
                ),
                value: operationDetailsResponse.data.name,
              },
              {
                label: t(
                  'pages.operations.operationDetailsDialog.operationDate',
                ),
                value: operationDetailsResponse.data.operationDate,
                bottomSeparator: true,
              },
              {
                label: t(
                  'pages.operations.operationDetailsDialog.userCentralId',
                ),
                value: operationDetailsResponse.data.userCentralId,
              },
              {
                label: t(
                  'pages.operations.operationDetailsDialog.userFirstname',
                ),
                value: operationDetailsResponse.data.userFirstname,
              },
              {
                label: t(
                  'pages.operations.operationDetailsDialog.userLastname',
                ),
                value: operationDetailsResponse.data.userLastname,
                bottomSeparator: true,
              },
              {
                label: t(
                  'pages.operations.operationDetailsDialog.authorCentralId',
                ),
                value: operationDetailsResponse.data.authorCentralId,
              },
              {
                label: t(
                  'pages.operations.operationDetailsDialog.authorFirstname',
                ),
                value: operationDetailsResponse.data.authorFirstname,
              },
              {
                label: t(
                  'pages.operations.operationDetailsDialog.authorLastname',
                ),
                value: operationDetailsResponse.data.authorLastname,
                bottomSeparator:
                  operationDetailsResponse.data.xpValue ||
                  operationDetailsResponse.data.vpValue ||
                  operationDetailsResponse.data.epValue
                    ? true
                    : false,
              },
            ],
          }
          if (isXpPointsVisible()) {
            operationsDetailsParams.data.push({
              label: t('pages.operations.operationDetailsDialog.xpValue'),
              value: operationDetailsResponse.data.xpValue,
            })
          }
          if (isVpPointsVisible()) {
            operationsDetailsParams.data.push({
              label: t('pages.operations.operationDetailsDialog.vpValue'),
              value: operationDetailsResponse.data.vpValue,
            })
          }
          if (isEpPointsVisible()) {
            operationsDetailsParams.data.push({
              label: t('pages.operations.operationDetailsDialog.epValue'),
              value: operationDetailsResponse.data.epValue,
            })
          }
          setDetailsParamsForDialog(operationsDetailsParams)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, operationId])

  return (
    <>
      {detailsParamsForDialog && (
        <DetailsDialog
          detailsData={detailsParamsForDialog}
          open={open}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default OperationDetailsDialog
