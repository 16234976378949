import axios from 'axios'
import authHeader from './authHeader'
import {
  LoyaltyAwardCatalogTypeResponse,
  LoyaltyAwardDetailsResponse,
  LoyaltyAwardListResponse,
  LoyaltyAwardStateResponse,
  LoyaltyAwardTypeResponse,
  LoyaltyAwardsActionsResponse,
} from '../store/LoyaltyAward/types'

const API_URL = process.env.REACT_APP_API_URL

const getAwardList = async (
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
  awardStateId: number | null,
  awardCode: string,
  companies: { id: number }[],
  period: number | null,
  loyaltyPeriod: number | null,
  awardCatalog: number | null,
  awardDateFrom: string | null,
  awardDateTo: string | null,
  users: { id: number }[],
): Promise<LoyaltyAwardListResponse> => {
  return axios.post(
    API_URL + '/api/admin/award/list',
    {
      search,
      sortBy,
      sortOrder,
      per,
      page,
      awardStateId,
      awardCode,
      companies,
      period,
      loyaltyPeriod,
      awardCatalog,
      awardDateFrom,
      awardDateTo,
      users,
    },
    {
      headers: authHeader(),
    },
  )
}

const acceptAwards = async (
  userAwards: { id: number }[],
  adminComment: string | null,
): Promise<LoyaltyAwardsActionsResponse> => {
  return axios.post(
    API_URL + '/api/admin/award/accept',
    {
      userAwards,
      adminComment,
    },
    {
      headers: authHeader(),
    },
  )
}

const declineAwards = async (
  userAwards: { id: number }[],
  adminComment: string | null,
): Promise<LoyaltyAwardsActionsResponse> => {
  return axios.post(
    API_URL + '/api/admin/award/decline',
    {
      userAwards,
      adminComment,
    },
    {
      headers: authHeader(),
    },
  )
}

const closeAwards = async (
  userAwards: { id: number }[],
  superAdminComment: string | null,
): Promise<LoyaltyAwardsActionsResponse> => {
  return axios.post(
    API_URL + '/api/admin/award/close',
    {
      userAwards,
      superAdminComment,
    },
    {
      headers: authHeader(),
    },
  )
}

const getAwardTypesList = async (): Promise<LoyaltyAwardTypeResponse> => {
  return axios.get(API_URL + '/api/admin/award/type', {
    headers: authHeader(),
  })
}
const getAwardStatesList = async (): Promise<LoyaltyAwardStateResponse> => {
  return axios.get(API_URL + '/api/admin/award/state', {
    headers: authHeader(),
  })
}

const getAwardCatalogTypeList =
  async (): Promise<LoyaltyAwardCatalogTypeResponse> => {
    return axios.get(API_URL + '/api/admin/award/catalog/list/simple', {
      headers: authHeader(),
    })
  }

const getAwardDetails = async (
  awardId: number,
): Promise<LoyaltyAwardDetailsResponse> => {
  return await axios.get(API_URL + `/api/admin/award/preview/${awardId}`, {
    headers: authHeader(),
  })
}

const exportedObject = {
  getAwardList,
  getAwardTypesList,
  getAwardStatesList,
  acceptAwards,
  declineAwards,
  closeAwards,
  getAwardDetails,
  getAwardCatalogTypeList,
}

export default exportedObject
