import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import ManufacturerService from '../../../../services/manufacturer.service'
import { DetailsData } from '../../../../store/Details/types'
import DetailsDialog from '../../../shared/DetailsDialog'

type ManufacturerDetailsDialogProps = {
  manufacturerId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const ManufacturerDetailsDialog: React.FunctionComponent<
  ManufacturerDetailsDialogProps
> = ({ manufacturerId, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [detailsParamsForDialog, setDetailsParamsForDialog] =
    useState<DetailsData>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const manufacturerDetailsResponse =
          await ManufacturerService.getManufacturerDetails(manufacturerId)
        if (manufacturerDetailsResponse.data) {
          setDetailsParamsForDialog({
            title: {
              label: `${t(
                'pages.manufacturers.manufacturerDetailsDialog.manufacturerId',
              )}: ${manufacturerId.toString()}`,
              value: manufacturerDetailsResponse.data.name,
            },
            data: [
              {
                label: t(
                  'pages.manufacturers.manufacturerDetailsDialog.shortName',
                ),
                value: manufacturerDetailsResponse.data.shortName,
              },
              {
                label: t(
                  'pages.manufacturers.manufacturerDetailsDialog.centralId',
                ),
                value: manufacturerDetailsResponse.data.centralId,
                bottomSeparator: true,
              },
              {
                label: t(
                  'pages.manufacturers.manufacturerDetailsDialog.isActive',
                ),
                value: manufacturerDetailsResponse.data.isActive
                  ? t('common.yes')
                  : t('common.no'),
              },
              {
                label: t(
                  'pages.manufacturers.manufacturerDetailsDialog.isVisible',
                ),
                value: manufacturerDetailsResponse.data.isVisible
                  ? t('common.yes')
                  : t('common.no'),
              },
            ],
          })
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, manufacturerId])

  return (
    <>
      {detailsParamsForDialog && (
        <DetailsDialog
          detailsData={detailsParamsForDialog}
          open={open}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default ManufacturerDetailsDialog
