import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import LoyaltyParticipantPlansService from '../../../../services/loyaltyParticipantPlans.service'
import LoyaltyPeriodService from '../../../../services/loyaltyPeriod.service'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Column, ColumnInstance } from 'react-table'
import {
  getTableState,
  setTableState,
  getHiddenColumns,
  thousandsSeparator,
} from '../../../../helpers/utils'
import { errorHandler } from '../../../../helpers/errorHandler'
import TableControlled from '../../../Table/TableControlled'
import LoyaltyParticipantPlansToolbar from '../partials/LoyaltyParticipantPlansToolbar'
import { Stack } from '@mui/system'
import { pick } from 'lodash'
import * as XLSX from 'xlsx'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { CircularProgress, SelectChangeEvent } from '@mui/material'
import { LoyaltyPeriod } from '../../../../store/LoyaltyPeriod/types'
import { LoyaltyParticipantPlansListParams } from '../../../../store/LoyaltyParticipantPlans/types'
import {
  isLpLoyaltyPointsVisible,
  isVpLoyaltyPointsVisible,
  isXpLoyaltyPointsVisible,
} from '../../../../helpers/environment'

type LoyaltyParticipantPlansListProps = {
  path: string
}

const LoyaltyParticipantPlansList: FunctionComponent<
  LoyaltyParticipantPlansListProps
> = ({ path }) => {
  const tableName = 'loyaltyParticipantPlans'
  const searchState = getTableState(tableName, 'search')

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredOperationList, setFilteredOperationList] = useState<
    LoyaltyParticipantPlansListParams[]
  >([])
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const fetchIdRef = useRef(0)
  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [searchValue, setSearchValue] = useState<string>(
    searchState ? searchState : '',
  )
  const [participantCentralId, setParticipantCentralId] = useState<
    string | null
  >(null)
  const [storeCentralId, setStoreCentralId] = useState<string | null>(null)
  const [storeName, setStoreName] = useState<string | null>(null)
  const [storeDataLoading, setStoreDataLoading] = useState<boolean>(false)

  const [periodValue, setPeriodValue] = useState<string>('all')
  const [periods, setPeriods] = useState<LoyaltyPeriod[]>([])

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')

  const handleParticipantCentralIdChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setParticipantCentralId(event.target.value)
    setControlledPageIndex(0)
    setSkipPageReset(false)
  }

  const generateTableColumns = useCallback(
    (operation: LoyaltyParticipantPlansListParams[]) => {
      const columns = []
      columns.push(
        {
          accessor: 'loyaltyPeriodName',
          Header: t(
            'pages.loyaltyParticipantPlans.table.loyaltyPeriodName',
          ).toString(),
          width: 170,
        },
        {
          accessor: 'planId',
          Header: t('pages.loyaltyParticipantPlans.table.planId').toString(),
          width: 70,
        },
        {
          accessor: 'planName',
          Header: t('pages.loyaltyParticipantPlans.table.planName').toString(),
          width: 170,
        },
        {
          accessor: 'participantCentralId',
          Header: t(
            'pages.loyaltyParticipantPlans.table.participantCentralId',
          ).toString(),
          width: 120,
        },
        {
          accessor: 'participantFirstname',
          Header: t(
            'pages.loyaltyParticipantPlans.table.participantFirstname',
          ).toString(),
          width: 150,
        },
        {
          accessor: 'participantLastname',
          Header: t(
            'pages.loyaltyParticipantPlans.table.participantLastname',
          ).toString(),
          width: 150,
        },
        {
          accessor: 'storeCentralId',
          Header: t(
            'pages.loyaltyParticipantPlans.table.storeCentralId',
          ).toString(),
          width: 150,
        },
        {
          accessor: 'storeName',
          Header: t('pages.loyaltyParticipantPlans.table.storeName').toString(),
          width: 190,
        },
        {
          accessor: 'countryName',
          Header: t(
            'pages.loyaltyParticipantPlans.table.countryName',
          ).toString(),
          width: 190,
        },
        {
          accessor: 'planValue',
          Header: t('pages.loyaltyParticipantPlans.table.planValue').toString(),
          width: 100,
          Cell: (params: any) => (
            <>
              {params.value !== null
                ? thousandsSeparator(params.value)
                : params.value}
            </>
          ),
        },
        {
          accessor: 'resultValue',
          Header: t(
            'pages.loyaltyParticipantPlans.table.resultValue',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <>
              {params.value !== null
                ? thousandsSeparator(params.value)
                : params.value}
            </>
          ),
        },
        {
          accessor: 'resultPercentValue',
          Header: t(
            'pages.loyaltyParticipantPlans.table.resultPercentValue',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <>
              {params.value !== null
                ? thousandsSeparator(params.value)
                : params.value}
            </>
          ),
        },
        {
          accessor: 'bronzeTarget',
          Header: t(
            'pages.loyaltyParticipantPlans.table.bronzeTarget',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <>
              {params.value !== null
                ? thousandsSeparator(params.value)
                : params.value}
            </>
          ),
        },
        {
          accessor: 'bronzeRealization',
          Header: t(
            'pages.loyaltyParticipantPlans.table.bronzeRealization',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <>
              {params.value !== null
                ? thousandsSeparator(params.value)
                : params.value}
            </>
          ),
        },
        {
          accessor: 'silverTarget',
          Header: t(
            'pages.loyaltyParticipantPlans.table.silverTarget',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <>
              {params.value !== null
                ? thousandsSeparator(params.value)
                : params.value}
            </>
          ),
        },
        {
          accessor: 'silverRealization',
          Header: t(
            'pages.loyaltyParticipantPlans.table.silverRealization',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <>
              {params.value !== null
                ? thousandsSeparator(params.value)
                : params.value}
            </>
          ),
        },
        {
          accessor: 'goldTarget',
          Header: t(
            'pages.loyaltyParticipantPlans.table.goldTarget',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <>
              {params.value !== null
                ? thousandsSeparator(params.value)
                : params.value}
            </>
          ),
        },
        {
          accessor: 'goldRealization',
          Header: t(
            'pages.loyaltyParticipantPlans.table.goldRealization',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <>
              {params.value !== null
                ? thousandsSeparator(params.value)
                : params.value}
            </>
          ),
        },
      )
      isXpLoyaltyPointsVisible() &&
        columns.push({
          accessor: 'xpValue',
          Header: t('pages.loyaltyParticipantPlans.table.xpValue').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack sx={{ color: params.row.original.xpValue < 0 ? 'red' : '' }}>
              {params.row.original.xpValue}
            </Stack>
          ),
        })
      isVpLoyaltyPointsVisible() &&
        columns.push({
          accessor: 'vpValue',
          Header: t('pages.loyaltyParticipantPlans.table.vpValue').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack sx={{ color: params.row.original.vpValue < 0 ? 'red' : '' }}>
              {params.row.original.vpValue}
            </Stack>
          ),
        })
      isLpLoyaltyPointsVisible() &&
        columns.push({
          accessor: 'lpValue',
          Header: t('pages.loyaltyParticipantPlans.table.lpValue').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack sx={{ color: params.row.original.lpValue < 0 ? 'red' : '' }}>
              {params.row.original.lpValue}
            </Stack>
          ),
        })

      return columns
    },
    [t],
  )

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)

        const periodListResponse = await LoyaltyPeriodService.getPeriodList()

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
          const periodState = getTableState(tableName, 'period')
          if (periodState) {
            setPeriodValue(periodState)
          } else {
            let activePeriod = null
            activePeriod = periodListResponse.data.periods.find(
              (period) => period.isActive,
            )
            setPeriodValue(activePeriod ? activePeriod.id.toString() : 'all')
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
  }, [t])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        setStoreDataLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)
          const page = ++pageIndex
          const loyaltyParticipantPlansListResponse =
            await LoyaltyParticipantPlansService.getLoyaltyParticipantPlansList(
              periodValue === 'all' ? null : parseInt(periodValue),
              participantCentralId === '' ? null : participantCentralId,
              searchValue,
              sortColumn,
              sortDirection,
              pageSize,
              page,
            )

          if (loyaltyParticipantPlansListResponse.data) {
            setFilteredOperationList(
              loyaltyParticipantPlansListResponse.data.participantPlans,
            )
            setTableColumns(
              generateTableColumns(
                loyaltyParticipantPlansListResponse.data.participantPlans,
              ),
            )
            setTotalCount(loyaltyParticipantPlansListResponse.data.totalCount)
            setPageCount(
              Math.ceil(
                loyaltyParticipantPlansListResponse.data.totalCount / pageSize,
              ),
            )
            setStoreCentralId(
              loyaltyParticipantPlansListResponse.data.storeCentralId,
            )
            if (
              loyaltyParticipantPlansListResponse.data.storeName &&
              participantCentralId
            ) {
              setStoreName(loyaltyParticipantPlansListResponse.data.storeName)
            } else if (
              !loyaltyParticipantPlansListResponse.data.storeName &&
              participantCentralId
            ) {
              setStoreName(
                t('pages.loyaltyParticipantPlans.toolbar.filters.storeMessage'),
              )
            } else {
              setStoreName(null)
            }
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
          setStoreDataLoading(false)
        }
      }
    },
    [t, generateTableColumns, searchValue, periodValue, participantCentralId],
  )

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const loyaltyParticipantPlansListResponse =
        await LoyaltyParticipantPlansService.getLoyaltyParticipantPlansList(
          periodValue === 'all' ? null : parseInt(periodValue),
          participantCentralId === '' ? null : participantCentralId,
          searchValue,
          downloadSortBy,
          downloadSortOrder,
          100000,
          1,
        )

      const dataOperation =
        loyaltyParticipantPlansListResponse.data.participantPlans
      if (dataOperation) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'loyaltyPeriodName',
            'planId',
            'planName',
            'participantCentralId',
            'participantFirstname',
            'participantLastname',
            'storeCentralId',
            'storeName',
            'countryName',
            'planValue',
            'resultValue',
            'resultPercentValue',
            'bronzeTarget',
            'bronzeRealization',
            'silverTarget',
            'silverRealization',
            'goldTarget',
            'goldRealization',
            ...(isXpLoyaltyPointsVisible() ? ['xpValue'] : []),
            ...(isVpLoyaltyPointsVisible() ? ['vpValue'] : []),
            ...(isLpLoyaltyPointsVisible() ? ['lpValue'] : []),
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredLoyaltyParticipantPlansData = dataOperation.map(
          (participantPlan) => {
            return pick(participantPlan, visibleColumns)
          },
        )

        const translatedHeaders = {
          loyaltyPeriodName: t(
            'excel.loyaltyParticipantPlans.loyaltyPeriodName',
          ),
          planId: t('excel.loyaltyParticipantPlans.planId'),
          planName: t('excel.loyaltyParticipantPlans.planName'),
          participantCentralId: t(
            'excel.loyaltyParticipantPlans.participantCentralId',
          ),
          participantFirstname: t(
            'excel.loyaltyParticipantPlans.participantFirstname',
          ),
          participantLastname: t(
            'excel.loyaltyParticipantPlans.participantLastname',
          ),
          storeCentralId: t('excel.loyaltyParticipantPlans.storeCentralId'),
          storeName: t('excel.loyaltyParticipantPlans.storeName'),
          countryName: t('excel.loyaltyParticipantPlans.countryName'),
          planValue: t('excel.loyaltyParticipantPlans.planValue'),
          resultValue: t('excel.loyaltyParticipantPlans.resultValue'),
          resultPercentValue: t(
            'excel.loyaltyParticipantPlans.resultPercentValue',
          ),
          bronzeTarget: t('excel.loyaltyParticipantPlans.bronzeTarget'),
          bronzeRealization: t(
            'excel.loyaltyParticipantPlans.bronzeRealization',
          ),
          silverTarget: t('excel.loyaltyParticipantPlans.silverTarget'),
          silverRealization: t(
            'excel.loyaltyParticipantPlans.silverRealization',
          ),
          goldTarget: t('excel.loyaltyParticipantPlans.goldTarget'),
          goldRealization: t('excel.loyaltyParticipantPlans.goldRealization'),
          ...(isXpLoyaltyPointsVisible()
            ? { xpValue: t('excel.loyaltyParticipantPlans.xpValue') }
            : {}),
          ...(isVpLoyaltyPointsVisible()
            ? { vpValue: t('excel.loyaltyParticipantPlans.vpValue') }
            : {}),
          ...(isLpLoyaltyPointsVisible()
            ? { lpValue: t('excel.loyaltyParticipantPlans.lpValue') }
            : {}),
        }

        const headers = [
          Object.keys(filteredLoyaltyParticipantPlansData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredLoyaltyParticipantPlansData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredLoyaltyParticipantPlansData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('loyaltyParticipantPlans')}
              sx={{ marginLeft: 'auto', marginTop: '8px', marginBottom: '7px' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <LoyaltyParticipantPlansToolbar
            periods={periods}
            periodValue={periodValue}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              setTableState(tableName, 'search', event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            participantCentralId={participantCentralId}
            setParticipantCentralId={(item) =>
              handleParticipantCentralIdChange(item)
            }
            storeCentralId={storeCentralId}
            storeName={storeName}
            storeDataLoader={storeDataLoading}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredOperationList}
            height="calc(100vh - 330px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'participantCentralId',
              'participantFirstname',
              'participantLastname',
              'countryName',
              'bronzeTarget',
              'silverTarget',
              'goldTarget',
              'bronzeRealization',
              'silverRealization',
              'goldRealization',
              ...(isXpLoyaltyPointsVisible() ? ['xpValue'] : []),
              ...(isVpLoyaltyPointsVisible() ? ['vpValue'] : []),
              ...(isLpLoyaltyPointsVisible() ? ['lpValue'] : []),
            ]}
            toggleVisibility={setColumnsVisibility}
          />
        </>
      )}
    </>
  )
}

export default LoyaltyParticipantPlansList
