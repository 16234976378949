import axios from 'axios'
import authHeader from './authHeader'
import {
  SetUpdateDateResponse,
  UpdateDateResponse,
} from '../store/ConfigLoyalty/types'

const API_URL = process.env.REACT_APP_API_URL

const getUpdateDate = async (): Promise<UpdateDateResponse> => {
  return await axios.get(
    API_URL + `/api/admin/config/parameter/LOYALTY_LAST_UPDATE_DATE`,
    {
      headers: authHeader(),
    },
  )
}

const setUpdateDate = async (
  loyaltyLastUpdateDate: string,
): Promise<SetUpdateDateResponse> => {
  return await axios.post(
    API_URL + `/api/admin/loyalty/config/parameter/update/last-update-date`,
    { loyaltyLastUpdateDate },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  setUpdateDate,
  getUpdateDate,
}

export default exportedObject
