import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import CompanyService from '../../../../../services/company.service'
import { DetailsData } from '../../../../../store/Details/types'
import DetailsDialog from '../../../../shared/DetailsDialog'

type CompanyDetailsDialogProps = {
  companyId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const CompanyDetailsDialog: React.FunctionComponent<
  CompanyDetailsDialogProps
> = ({ companyId, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [detailsParamsForDialog, setDetailsParamsForDialog] =
    useState<DetailsData>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const companyDetailsResponse = await CompanyService.getCompanyDetails(
          companyId,
        )
        if (companyDetailsResponse.data) {
          setDetailsParamsForDialog({
            title: {
              label: `${t(
                'pages.company.companyDetailsDialog.companyId',
              )}: ${companyId.toString()}`,
              value: companyDetailsResponse.data.name,
            },
            data: [
              {
                label: t('pages.company.companyDetailsDialog.centralId'),
                value: companyDetailsResponse.data.centralId,
              },
              {
                label: t('pages.company.companyDetailsDialog.companyName'),
                value: companyDetailsResponse.data.name,
                bottomSeparator: true,
              },
              {
                label: t('pages.company.companyDetailsDialog.regionCentralId'),
                value: companyDetailsResponse.data.regionCentralId,
              },
              {
                label: t('pages.company.companyDetailsDialog.regionName'),
                value: companyDetailsResponse.data.regionName,
                bottomSeparator: true,
              },
              {
                label: t('pages.company.companyDetailsDialog.userCentralId'),
                value: companyDetailsResponse.data.userCentralId,
              },
              {
                label: t('pages.company.companyDetailsDialog.firstname'),
                value: companyDetailsResponse.data.firstname,
              },
              {
                label: t('pages.company.companyDetailsDialog.lastname'),
                value: companyDetailsResponse.data.lastname,
              },
              {
                label: t('pages.company.companyDetailsDialog.email'),
                value: companyDetailsResponse.data.email,
              },
            ],
          })
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, companyId])

  return (
    <>
      {detailsParamsForDialog && (
        <DetailsDialog
          detailsData={detailsParamsForDialog}
          open={open}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default CompanyDetailsDialog
