import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'
import UpdateDate from './partials/UpdateDate'
import { Grid } from '@mui/material'
import { ApplicationModule } from '../../../store/Config/types'
import { isModuleVisible } from '../../../helpers/utils'
import ImportFromFTP from './partials/ImportFromFTP'

type ConfigurationLoyaltyProps = {
  applicationModules: ApplicationModule[]
}

const ConfigurationLoyalty: React.FC<ConfigurationLoyaltyProps> = ({
  applicationModules,
}) => {
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.auth)

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser || !isLoggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <UpdateDate />
      </Grid>
      {isModuleVisible('FTP_IMPORT', applicationModules, currentUser) && (
        <Grid item xs={12} mb={2}>
          <ImportFromFTP />
        </Grid>
      )}
    </Grid>
  )
}

export default ConfigurationLoyalty
