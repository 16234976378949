import React, { useCallback, useRef, useState } from 'react'
import {
  CircularProgress,
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ManufacturersToolbar from '../partials/ManufacturersToolbar'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Column, ColumnInstance } from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import ManufacturerService from '../../../../services/manufacturer.service'
import { getHiddenColumns } from '../../../../helpers/utils'
import { User } from '../../../../store/Auth/types'
import { pick } from 'lodash'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import ImportDialog from '../../../shared/ImportDialog'
import { ManufacturerItem } from '../../../../store/Manufacturer/types'
import ManufacturerEditDialog from '../partials/ManufacturerEditDialog'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import ManufacturerImageDialog from '../partials/ManufacturerImageDialog'
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'
import ManufacturerDetailsDialog from '../partials/ManufacturerDetailsDialog'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'

type ManufacturersListProps = {
  path: string
  user: User
}

const ManufacturersList: React.FC<ManufacturersListProps> = ({
  path,
  user,
}) => {
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'manufacturers'

  const [searchText, setSearchText] = useState<string>('')

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [filteredManufacturersList, setFilteredManufacturersList] = useState<
    ManufacturerItem[]
  >([])
  const [searchValue, setSearchValue] = useState<string>('')

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [openImportDialog, setImportDialogOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [importName, setImportName] = useState<string>('')
  const [importTitle, setImportTitle] = useState<string>('')
  const [visibilityValue, setVisibilityValue] = useState<string>('all')
  const [activityValue, setActivityValue] = useState<string>('all')

  const [controlledPageIndex, setControlledPageIndex] = useState(0)

  const [manufacturerId, setManufacturerId] = useState<number | null>(null)
  const [manufacturerImageId, setManufacturerImageId] = useState<number>()

  const [manufacturerImageDialogOpen, setManufacturerImageDialogOpen] =
    useState<boolean>(false)

  const [editManufacturerDialogOpen, setEditManufacturerDialogOpen] =
    useState(false)

  const [manufacturerDetailsDialogOpen, setManufacturerDetailsDialogOpen] =
    useState<boolean>(false)

  const handleManufacturerImageDialogClickOpen = (imageId: number) => {
    setManufacturerImageId(imageId)
    setManufacturerImageDialogOpen(true)
  }

  const handleManufacturerImageDialogClose = () => {
    setManufacturerImageDialogOpen(false)
  }

  const handleManufacturerEditDialogClickOpen = (manufacturerId: number) => {
    setManufacturerId(manufacturerId)
    setEditManufacturerDialogOpen(true)
  }

  const handleManufacturerEditDialogClose = (refreshTable: boolean = false) => {
    setEditManufacturerDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleManufacturerDetailsDialogClickOpen = (manufacturerId: number) => {
    setManufacturerId(manufacturerId)
    setManufacturerDetailsDialogOpen(true)
  }

  const handleManufacturerDetailsDialogClose = () => {
    setManufacturerDetailsDialogOpen(false)
  }

  const handleImportDialogClose = () => {
    setImportDialogOpen(false)
    setRefresh((prevState) => !prevState)
  }

  const handleImportDialogClickOpen = (name: string, title: string) => {
    setImportName(name)
    setImportTitle(title)
    setImportDialogOpen(true)
  }

  const generateTableColumns = useCallback(
    (manufacturers: ManufacturerItem[]) => {
      const columns = []
      columns.push(
        {
          Header: t('pages.manufacturers.table.centralId').toString(),
          accessor: 'centralId',
          width: 160,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.manufacturers.table.shortName').toString(),
          accessor: 'shortName',
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.manufacturers.table.name').toString(),
          accessor: 'name',
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.manufacturers.table.isActive').toString(),
          accessor: 'isActive',
          width: 150,
          Cell: (params: any) => (
            <>
              {params.row.original.isActive ? t('common.yes') : t('common.no')}
            </>
          ),
        },
        {
          accessor: 'isVisible',
          Header: t('pages.manufacturers.table.isVisible').toString(),
          width: 150,
          Cell: (params: any) => (
            <>
              {params.row.original.isVisible ? t('common.yes') : t('common.no')}
            </>
          ),
        },
        {
          accessor: 'modified',
          Header: t('pages.manufacturers.table.modified').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'actions',
          Header: t('pages.manufacturers.table.actions').toString(),
          disableSortBy: true,
          sticky: 'right',
          width: 130,
          Cell: (params: any) => (
            <Grid container>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('common.edit')}`}>
                  <IconButton
                    onClick={() =>
                      handleManufacturerEditDialogClickOpen(
                        params.row.original.id,
                      )
                    }
                    size="small"
                    style={{ padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('common.preview')}`}>
                  <IconButton
                    onClick={() =>
                      handleManufacturerDetailsDialogClickOpen(
                        params.row.original.id,
                      )
                    }
                    size="small"
                    style={{ padding: 0 }}
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip
                  title={t(
                    `pages.manufacturers.table.${
                      params.row.original.hasImage
                        ? 'imagePreview'
                        : 'lackOfImage'
                    }`,
                  )}
                >
                  <span>
                    <IconButton
                      aria-label="show"
                      size="small"
                      style={{
                        padding: 0,
                        marginRight: '5px',
                        opacity: params.row.original.hasImage ? '1' : '0.3',
                      }}
                      onClick={() =>
                        handleManufacturerImageDialogClickOpen(
                          params.row.original.imageId,
                        )
                      }
                      disabled={!params.row.original.hasImage}
                    >
                      <ImageSearchIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
      )

      return columns
    },
    [t],
  )

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)
          const page = ++pageIndex
          const response = await ManufacturerService.getManufacturers(
            null,
            null,
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )

          if (response.data.manufacturers) {
            setTableColumns(generateTableColumns(response.data.manufacturers))

            setFilteredManufacturersList(response.data.manufacturers)

            setTotalCount(response.data.totalCount)
            setPageCount(Math.ceil(response.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    [
      t,
      searchValue,
      generateTableColumns,
      // selectedGroups,
      // selectedManufacturers,
      // selectedCategories,
      refresh,
    ],
  )

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const response = await ManufacturerService.getManufacturers(
        null,
        null,
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
      )

      const dataManufacturers = response.data.manufacturers
      if (dataManufacturers) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'centralId',
            'shortName',
            'name',
            'isActive',
            'isVisible',
            'modified',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredManufacturersList = dataManufacturers.map(
          (manufacturer) => {
            manufacturer.isActive = manufacturer.isActive
              ? t('common.yes').toString()
              : t('common.no').toString()
            manufacturer.isVisible = manufacturer.isVisible
              ? t('common.yes').toString()
              : t('common.no').toString()

            return pick(manufacturer, visibleColumns)
          },
        )

        const translatedHeaders = {
          id: t('excel.manufacturers.id'),
          centralId: t('excel.manufacturers.centralId'),
          name: t('excel.manufacturers.name'),
          shortName: t('excel.manufacturers.shortName'),
          description: t('excel.manufacturers.description'),
          isActive: t('excel.manufacturers.isActive'),
          isVisible: t('excel.manufacturers.isVisible'),
          created: t('excel.manufacturers.created'),
          modified: t('excel.manufacturers.modified'),
        }

        const headers = [
          Object.keys(filteredManufacturersList[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredManufacturersList, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      <Stack
        display="flex"
        alignContent="space-between"
        flexDirection="row"
        marginBottom={1}
      >
        <PrimaryButton
          variant="contained"
          onClick={() =>
            handleImportDialogClickOpen(
              'manufacturers',
              t('pages.manufacturers.import'),
            )
          }
        >
          {t('pages.manufacturers.import')}
        </PrimaryButton>
        <SecondaryButton
          variant="contained"
          onClick={() => downloadXLSX('manufacturers')}
          sx={{ marginLeft: 'auto' }}
          disabled={isDownloading}
        >
          {isDownloading && (
            <CircularProgress
              style={{ height: 12, width: 12, marginRight: 10 }}
            />
          )}
          {isDownloading
            ? t('common.generatingFile')
            : t('common.downloadTableAsXLSX')}
        </SecondaryButton>
      </Stack>

      <ManufacturersToolbar
        value={searchText}
        onChange={(event: { target: { value: string } }) => {
          setSearchText(event.target.value)
        }}
        submitSearch={(searchValue) => {
          setSkipPageReset(false)
          setSearchValue(searchValue)
        }}
        clearSearch={() => {
          setSkipPageReset(false)
          setSearchText('')
          setSearchValue('')
        }}
        visibilityValue={visibilityValue}
        filterVisibility={(event: SelectChangeEvent) => {
          setSkipPageReset(false)
          setControlledPageIndex(0)
          setVisibilityValue(event.target.value)
        }}
        activityValue={activityValue}
        filterActivity={(event: SelectChangeEvent) => {
          setSkipPageReset(false)
          setControlledPageIndex(0)
          setActivityValue(event.target.value)
        }}
        user={user}
      />
      <TableControlled
        name={tableName}
        columns={tableColumns}
        data={filteredManufacturersList}
        height="calc(100vh - 280px)"
        fetchData={fetchData}
        loading={tableLoading}
        pageIndex={controlledPageIndex}
        pageCount={pageCount}
        totalCount={totalCount}
        skipPageReset={skipPageReset}
        columnsVisibility={[]}
        toggleVisibility={setColumnsVisibility}
      />
      <ImportDialog
        open={openImportDialog}
        handleClose={handleImportDialogClose}
        name={importName}
        title={importTitle}
      />
      {manufacturerId && (
        <ManufacturerEditDialog
          manufacturerId={manufacturerId}
          open={editManufacturerDialogOpen}
          handleClose={handleManufacturerEditDialogClose}
        />
      )}
      {manufacturerImageId && (
        <ManufacturerImageDialog
          open={manufacturerImageDialogOpen}
          handleClose={handleManufacturerImageDialogClose}
          imageId={manufacturerImageId}
        />
      )}
      {manufacturerId && (
        <ManufacturerDetailsDialog
          manufacturerId={manufacturerId}
          open={manufacturerDetailsDialogOpen}
          handleClose={handleManufacturerDetailsDialogClose}
        />
      )}
    </>
  )
}

export default ManufacturersList
