import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import './Donuts.css'

type ChallengeRealizationMainDonutProps = {
  percent: number
}

const ChallengeRealizationMainDonut: React.FunctionComponent<ChallengeRealizationMainDonutProps> =
  ({percent}) => {
    const { t } = useTranslation()
    const chartEl = useRef<SVGSVGElement>(null)
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
      const fetchData = async () => {
        try {
          setTimeout(() => {
            var backgroundArc = d3
              .arc()
              .innerRadius(45)
              .outerRadius(70)
              .startAngle(0)
              .endAngle(Math.PI * 2)

            var mainArc = d3
              .arc()
              .innerRadius(45)
              .outerRadius(70)
              .cornerRadius(20)
              .startAngle(0)
              .endAngle(function (d: any) {
                return (d / 100) * Math.PI * 2
              })

            var data = [percent] // percents.

            const svg = d3.select(chartEl.current)

            svg
              .attr('width', 150)
              .attr('height', 150)
              .attr('viewBox', `0 0 150 150`)
              .attr('style', 'height: auto; height: intrinsic;')

            svg.selectAll('*').remove()

            var charts = svg
              .selectAll('g')
              .data(data)
              .enter()
              .append('g')
              .attr('transform', function (d, i) {
                return 'translate(' + (i * 150 + 75) + ', 75)'
              })

            charts
              .append('path')
              .attr('d', (d: any) => backgroundArc(d))
              .attr('fill', '#dcdcdc')

            charts
              .append('path')
              .attr('d', (d: any) => mainArc(d))
              .attr('fill', '#2367bf')

            let legend = svg
              .selectAll('.legend-main')
              .data(data)
              .enter()
              .append('g')
              .attr('class', 'legend-main')
              .attr('transform', function (d, i) {
                return 'translate(' + (i * 150 + 75) + ',' + 75 + ')'
              })
            legend
              .append('text')
              // .attr('x', 100)
              // .attr('y', 100)
              .attr('text-anchor', 'middle')
              .attr('dominant-baseline', 'central')
              .text(function (d) {
                return d + '%'
              })
          }, 0)
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setLoading(false)
        }
      }
      fetchData()
    }, [t, percent])

    return (
      <>
        {loading && <LoadingSpinner />}
        {!loading && <svg ref={chartEl} />}
      </>
    )
  }

export default ChallengeRealizationMainDonut
