import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import CompanyService from '../../../../../services/company.service'
import RegionService from '../../../../../services/region.service'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import { Column, ColumnInstance } from 'react-table'
import {
  getTableState,
  setTableState,
  getHiddenColumns,
} from '../../../../../helpers/utils'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { CompanyList } from '../../../../../store/Company/types'
import TableControlled from '../../../../Table/TableControlled'
import CompaniesToolbar from '../partials/CompaniesToolbar'
import { Stack } from '@mui/system'
import { pick } from 'lodash'
import * as XLSX from 'xlsx'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { CircularProgress, Grid, IconButton, Tooltip } from '@mui/material'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import CompanyImageDialog from '../partials/CompanyImageDialog'
import { Option } from '../../../../../store/types'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import CompanyAddDialog from '../partials/CompanyAddDialog'
import CompanyEditDialog from '../partials/CompanyEditDialog'
import { ReactComponent as EditIcon } from '../../../../../assets/images/icons/edit.svg'
import { ReactComponent as PreviewIcon } from '../../../../../assets/images/icons/preview.svg'
import CompanyDetailsDialog from '../partials/CompanyDetailsDialog'

type CompanyListProps = {
  path: string
}

const CompaniesList: FunctionComponent<CompanyListProps> = ({ path }) => {
  const tableName = 'company'
  const searchState = getTableState(tableName, 'search')

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredCompanyList, setFilteredCompanyList] = useState<CompanyList[]>(
    [],
  )
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [regions, setRegions] = useState<Option[]>([])
  const [selectedRegions, setSelectedRegions] = useState<Option[]>([])
  const [refresh, setRefresh] = useState(false)
  const fetchIdRef = useRef(0)
  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [searchValue, setSearchValue] = useState<string>(
    searchState ? searchState : '',
  )

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [companyImageDialogOpen, setCompanyImageDialogOpen] =
    useState<boolean>(false)
  const [companyImageId, setCompanyImageId] = useState<number>()

  const [companyId, setCompanyId] = useState<number | null>(null)
  const [addCompanyDialogOpen, setAddCompanyDialogOpen] = useState(false)
  const [editCompanyDialogOpen, setEditCompanyDialogOpen] = useState(false)

  const [companyDetailsDialogOpen, setCompanyDetailsDialogOpen] =
    useState(false)

  const handleCompanyImageDialogClickOpen = (imageId: number) => {
    setCompanyImageId(imageId)
    setCompanyImageDialogOpen(true)
  }

  const handleCompanyImageDialogClose = () => {
    setCompanyImageDialogOpen(false)
  }

  const handleCompanyAddDialogClickOpen = () => {
    setAddCompanyDialogOpen(true)
  }

  const handleCompanyAddDialogClose = (refreshTable: boolean = false) => {
    setAddCompanyDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleCompanyEditDialogClickOpen = (companyId: number) => {
    setCompanyId(companyId)
    setEditCompanyDialogOpen(true)
  }

  const handleCompanyEditDialogClose = (refreshTable: boolean = false) => {
    setEditCompanyDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleCompanyDetailsDialogClickOpen = (companyId: number) => {
    setCompanyId(companyId)
    setCompanyDetailsDialogOpen(true)
  }

  const handleCompanyDetailsDialogClose = () => {
    setCompanyDetailsDialogOpen(false)
  }

  const generateTableColumns = useCallback(
    (company: CompanyList[]) => {
      const columns = []
      columns.push(
        {
          accessor: 'companyId',
          Header: t('pages.company.table.companyId').toString(),
          width: 60,
        },
        {
          accessor: 'companyCentralId',
          Header: t('pages.company.table.companyCentralId').toString(),
          width: 140,
        },
        {
          accessor: 'companyName',
          Header: t('pages.company.table.companyName').toString(),
          width: 300,
        },
        {
          accessor: 'regionName',
          Header: t('pages.company.table.regionName').toString(),
          width: 160,
        },
        {
          accessor: 'actions',
          Header: t('pages.company.table.actions').toString(),
          disableSortBy: true,
          sticky: 'right',
          width: 130,
          Cell: (params: any) => (
            <Grid container>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.company.table.edit')}`}>
                  <IconButton
                    onClick={() =>
                      handleCompanyEditDialogClickOpen(
                        params.row.original.companyId,
                      )
                    }
                    size="small"
                    style={{ padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.company.table.preview')}`}>
                  <IconButton
                    onClick={() =>
                      handleCompanyDetailsDialogClickOpen(
                        params.row.original.companyId,
                      )
                    }
                    size="small"
                    style={{ padding: 0 }}
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip
                  title={t(
                    `pages.company.table.${
                      params.row.original.hasImage
                        ? 'imagePreview'
                        : 'lackOfImage'
                    }`,
                  )}
                >
                  <span>
                    <IconButton
                      aria-label="show"
                      size="small"
                      style={{
                        padding: 0,
                        marginRight: '5px',
                        opacity: params.row.original.hasImage ? '1' : '0.3',
                      }}
                      onClick={() =>
                        handleCompanyImageDialogClickOpen(
                          params.row.original.companyId,
                        )
                      }
                      disabled={!params.row.original.hasImage}
                    >
                      <ImageSearchIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
      )
      return columns
    },
    [t],
  )

  useEffect(() => {
    const fetchRegionsData = async () => {
      try {
        const regionListResponse = await RegionService.getRegionList()

        if (regionListResponse.data.regions) {
          const multiSelectOptions: Option[] = []
          regionListResponse.data.regions.forEach((region) =>
            multiSelectOptions.push({
              value: region.regionId,
              label: region.name,
            }),
          )
          setRegions(multiSelectOptions)
          const regionsState = getTableState(tableName, 'regions')
          if (regionsState) {
            setSelectedRegions(JSON.parse(regionsState))
          } else {
            setSelectedRegions(multiSelectOptions)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchRegionsData()
  }, [t])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      if (refresh) {
      }
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)

          const page = ++pageIndex
          const companyListResponse =
            await CompanyService.getPaginatedCompanyList(
              searchValue,
              sortColumn,
              sortDirection,
              pageSize,
              page,
              selectedRegions.map((region) => {
                return {
                  id: region.value,
                }
              }),
            )

          if (companyListResponse.data.companies) {
            setFilteredCompanyList(companyListResponse.data.companies)
            setTableColumns(
              generateTableColumns(companyListResponse.data.companies),
            )
            setTotalCount(companyListResponse.data.totalCount)
            setPageCount(
              Math.ceil(companyListResponse.data.totalCount / pageSize),
            )
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setTableLoading(false)
        }
      }
    },
    [t, generateTableColumns, selectedRegions, searchValue, refresh],
  )
  const handleSelectedRegionsChange = (regions: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setTableState(tableName, 'regions', JSON.stringify(regions))
    setSelectedRegions(regions)
  }

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const companyListResponse = await CompanyService.getPaginatedCompanyList(
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
        selectedRegions.map((region) => {
          return {
            id: region.value,
          }
        }),
      )

      const dataCompany = companyListResponse.data.companies
      if (dataCompany) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'companyId',
            'companyCentralId',
            'companyName',
            'regionName',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredCompanyData = dataCompany.map((store) => {
          return pick(store, visibleColumns)
        })

        const translatedHeaders = {
          companyId: t('excel.company.companyId'),
          companyCentralId: t('excel.company.companyCentralId'),
          companyName: t('excel.company.companyName'),
          regionName: t('excel.company.regionName'),
        }

        const headers = [
          Object.keys(filteredCompanyData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredCompanyData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredCompanyData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            width="100%"
            marginBottom={1}
          >
            <Stack
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              flexDirection="row"
            >
              <PrimaryButton
                variant="contained"
                onClick={handleCompanyAddDialogClickOpen}
              >
                {t('pages.company.addCompany')}
              </PrimaryButton>
            </Stack>
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('company')}
              sx={{ marginTop: '8px', marginBottom: '7px' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <CompaniesToolbar
            regions={regions}
            selectedRegions={selectedRegions}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              setTableState(tableName, 'search', event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            setSelectedRegions={(regions) =>
              handleSelectedRegionsChange(regions)
            }
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredCompanyList}
            height="calc(100vh - 345px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[]}
            toggleVisibility={setColumnsVisibility}
          />
          {companyImageId && (
            <CompanyImageDialog
              open={companyImageDialogOpen}
              handleClose={handleCompanyImageDialogClose}
              imageId={companyImageId}
            />
          )}
          <CompanyAddDialog
            open={addCompanyDialogOpen}
            handleClose={handleCompanyAddDialogClose}
          />
          {companyId && (
            <CompanyEditDialog
              companyId={companyId}
              open={editCompanyDialogOpen}
              handleClose={handleCompanyEditDialogClose}
            />
          )}
          {companyId && (
            <CompanyDetailsDialog
              companyId={companyId}
              open={companyDetailsDialogOpen}
              handleClose={handleCompanyDetailsDialogClose}
            />
          )}
        </>
      )}
    </>
  )
}

export default CompaniesList
