import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import ImportService from '../../../../services/import.service'
import ConfigService from '../../../../services/config.service'
import { Stack } from '@mui/system'
import {
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { toast } from 'react-toastify'

const ImportFromFTP: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [storesAvoidParameter, setStoresAvoidParameter] = useState<string>('1')

  const fetchData = useCallback(async () => {
    const fetchParameters = async () => {
      try {
        const getStoresParameter =
          await ConfigService.getStoresFtpImportParameter()
        if (getStoresParameter.data.ftpStoreImportType)
          setStoresAvoidParameter(
            getStoresParameter.data.ftpStoreImportType.toString(),
          )
      } catch (error) {
        errorHandler(error, t)
      }
    }

    setTimeout(() => {
      fetchParameters()
      setLoading(false)
    }, 500)
  }, [setLoading, t])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onSaveFtpImportParameter = async (storeFtpImportParameter: string) => {
    try {
      setIsSaving(true)
      const setFtpImportStoresParameterResponse =
        await ConfigService.setFtpStoreImport(parseInt(storeFtpImportParameter))
      if (setFtpImportStoresParameterResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsSaving(false)
    }
  }

  const startFtpImport = async (importType: string) => {
    try {
      setIsSaving(true)
      let importFromFtpResponse
      switch (importType) {
        case 'units':
          importFromFtpResponse =
            await ImportService.importOsUnitResultsFromFTP()
          break
        case 'plans':
          importFromFtpResponse = await ImportService.importPlanResultsFromFTP()
          break
        case 'stores':
          importFromFtpResponse = await ImportService.importStoresFromFTP()
          break
      }

      if (importFromFtpResponse && importFromFtpResponse.data.success) {
        toast.success(t('messages.success.importSuccessful'))
      }
      if (importFromFtpResponse && !importFromFtpResponse.data.success) {
        toast.error(t('messages.success.importFailed'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Stack p={3}>
      {loading && <LoadingSpinner />}
      {!loading && (
        <Card style={{ padding: '20px' }}>
          <Grid container>
            <Grid
              item
              md={12}
              sx={{
                fontSize: '20px',
                fontWeight: 'bolder',
                marginBottom: '20px',
              }}
            >
              {t('pages.configuration.importFromFtp.title')}
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <FormControl size="small">
                <Select
                  id="period-select"
                  onChange={(event: SelectChangeEvent) => {
                    setStoresAvoidParameter(event.target.value)
                  }}
                  value={storesAvoidParameter}
                  defaultValue={storesAvoidParameter}
                >
                  <MenuItem value="1">
                    {t('pages.configuration.importFromFtp.avoidStores')}
                  </MenuItem>
                  <MenuItem value="2">
                    {t('pages.configuration.importFromFtp.notAvoidStores')}
                  </MenuItem>
                </Select>
              </FormControl>
              <PrimaryButton
                style={{
                  marginLeft: '8px',
                  height: '40px',
                  opacity: isSaving ? '0.3' : '1',
                }}
                onClick={() => {
                  onSaveFtpImportParameter(storesAvoidParameter)
                }}
                disabled={isSaving}
              >
                {t('common.save')}
              </PrimaryButton>
            </Grid>

            <Grid
              item
              md={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    'pages.configuration.importFromFtp.importsParametersDescription',
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <PrimaryButton
                style={{ marginRight: '10px', opacity: isSaving ? '0.3' : '1' }}
                onClick={() => {
                  startFtpImport('units')
                }}
                disabled={isSaving}
              >
                {t('pages.configuration.importFromFtp.startImportLabel')}
              </PrimaryButton>
              <Typography variant="subtitle2" gutterBottom>
                {t('pages.configuration.importFromFtp.importUnitsLabel')}
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <PrimaryButton
                style={{ marginRight: '10px', opacity: isSaving ? '0.3' : '1' }}
                onClick={() => {
                  startFtpImport('plans')
                }}
                disabled={isSaving}
              >
                {t('pages.configuration.importFromFtp.startImportLabel')}
              </PrimaryButton>
              <Typography variant="subtitle2" gutterBottom>
                {t('pages.configuration.importFromFtp.importPlansResults')}
              </Typography>
            </Grid>
            <Grid item md={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <PrimaryButton
                style={{ marginRight: '10px', opacity: isSaving ? '0.3' : '1' }}
                onClick={() => {
                  startFtpImport('stores')
                }}
                disabled={isSaving}
              >
                {t('pages.configuration.importFromFtp.startImportLabel')}
              </PrimaryButton>
              <Typography variant="subtitle2" gutterBottom>
                {t('pages.configuration.importFromFtp.importStores')}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </Stack>
  )
}

export default ImportFromFTP
