import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import AwardService from '../../../../services/award.service'
import DetailsDialog from '../../../shared/DetailsDialog'
import { DetailsData } from '../../../../store/Details/types'

type AwardsDetailsDialogProps = {
  id: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const AwardsDetailsDialog: React.FunctionComponent<
  AwardsDetailsDialogProps
> = ({ id, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)

  const [detailsParamsForDialog, setDetailsParamsForDialog] =
    useState<DetailsData>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const detailsResponse = await AwardService.getAwardDetails(id)
        if (detailsResponse.data) {
          setDetailsParamsForDialog({
            title: {
              label: `${t(
                'pages.awards.awardsDetailsDialog.id',
              )}: ${id.toString()}`,
              value: detailsResponse.data.awardName,
            },
            data: [
              {
                label: t('pages.awards.awardsDetailsDialog.userFirstname'),
                value: detailsResponse.data.userFirstname,
              },
              {
                label: t('pages.awards.awardsDetailsDialog.userLastname'),
                value: detailsResponse.data.userLastname,
                bottomSeparator: true,
              },
              {
                label: t('pages.awards.awardsDetailsDialog.awardCatalogId'),
                value: detailsResponse.data.awardCatalogId,
              },
              {
                label: t('pages.awards.awardsDetailsDialog.awardName'),
                value: detailsResponse.data.awardName,
              },
              {
                label: t('pages.awards.awardsDetailsDialog.deliveryDetails'),
                value: detailsResponse.data.deliveryDetails,
                bottomSeparator: true,
              },
              {
                label: t('pages.awards.awardsDetailsDialog.userComment'),
                value: detailsResponse.data.userComment,
                bottomSeparator: true,
              },
              {
                label: t('pages.awards.awardsDetailsDialog.adminComment'),
                value: detailsResponse.data.adminComment,
                bottomSeparator: true,
              },
              {
                label: t('pages.awards.awardsDetailsDialog.superAdminComment'),
                value: detailsResponse.data.superAdminComment,
              },
            ],
          })
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, id])

  return (
    <>
      {detailsParamsForDialog && (
        <DetailsDialog
          detailsData={detailsParamsForDialog}
          open={open}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default AwardsDetailsDialog
