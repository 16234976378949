import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import {
  CellProps,
  Column,
  ColumnInstance,
  HeaderProps,
  Row,
} from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
} from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import { User } from '../../../../store/Auth/types'
import { pick } from 'lodash'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import AwardService from '../../../../services/award.service'
import CompanyService from '../../../../services/company.service'
import PeriodService from '../../../../services/period.service'
import {
  Award,
  AwardCatalogType,
  AwardState,
  GroupAwardActionsTypes,
} from '../../../../store/Award/types'
import IndeterminateCheckbox from '../../../Table/IndeterminateCheckbox'
import AwardsToolbar from '../partials/AwardsToolbar'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AwardsActionsDialog from '../partials/AwardsActionsDialog'
import { Period } from '../../../../store/Period/types'
import moment from 'moment'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import AwardDetailsDialog from '../partials/AwardsDetailsDialog'

type AwardsListProps = {
  user: User
}

const AwardsList: React.FC<AwardsListProps> = ({ user }) => {
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'awards'

  const [loading, setLoading] = useState<boolean>(true)
  const [searchText, setSearchText] = useState<string>('')

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [filteredAwardsList, setFilteredAwardsList] = useState<Award[]>([])
  const [searchValue, setSearchValue] = useState<string>('')

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])

  const [openDetailsDialog, setDetailsDialogOpen] = useState(false)

  const [controlledPageIndex, setControlledPageIndex] = useState(0)

  const [refresh, setRefresh] = useState(false)
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])

  const [awardTypeList, setAwardTypeList] = useState<AwardCatalogType[]>([])
  const [activeAwardTypeValue, setActiveAwardTypeValue] =
    useState<string>('all')

  const [awardStateList, setAwardStateList] = useState<AwardState[]>([])
  const [activeAwardStateValue, setActiveAwardStateValue] =
    useState<string>('all')

  const [awardsActionsDialogOpen, setAwardsActionsDialogOpen] = useState(false)
  const [awardsActionsDialogTitle, setAwardsActionsDialogTitle] =
    useState<string>('')

  const [groupActionType, setGroupActionType] =
    useState<GroupAwardActionsTypes | null>(null)

  const [periodValue, setPeriodValue] = useState<string>('all')
  const [periods, setPeriods] = useState<Period[]>([])

  const [dateFrom, setDateFrom] = useState<Date | null>(null)
  const [timeFrom, setTimeFrom] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [dateTo, setDateTo] = useState<Date | null>(null)
  const [timeTo, setTimeTo] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )

  const [selectedRows, setSelectedRows] = useState<Row<object>[]>([])
  const [selectedAwardIds, setSelectedAwardIds] = useState<Pick<Award, 'id'>[]>(
    [],
  )
  const [totalValue, setTotalValue] = useState<number>(0)

  // states for blocking group operations

  const [canClose, setCanClose] = useState<boolean>(true)
  const [canConfirm, setCanConfirm] = useState<boolean>(true)
  const [canDecline, setCanDecline] = useState<boolean>(true)

  const [id, setId] = useState<number | null>(null)

  const handleAwardsActionsClickOpen = (
    selectedRows: Row<any>[],
    operationType: GroupAwardActionsTypes,
    title: string,
  ) => {
    setSelectedAwardIds(selectedRows.map((row) => ({ id: row.original.id })))
    setAwardsActionsDialogTitle(title)
    setGroupActionType(operationType)
    setAwardsActionsDialogOpen(true)
  }

  const handleAwardsActionsClickClose = (refreshTable: boolean = false) => {
    setAwardsActionsDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }
  const handleSelectedCompaniesChange = (companies: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setTableState(tableName, 'companies', JSON.stringify(companies))
    setSelectedCompanies(companies)
  }

  const handleDetailsDialogClickOpen = (id: number) => {
    setId(id)
    setDetailsDialogOpen(true)
  }

  const handleDetailsDialogClose = () => {
    setDetailsDialogOpen(false)
  }

  const generateTableColumns = useCallback(
    (awards: Award[]) => {
      const columns = []
      columns.push(
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          width: 40,
          Cell: ({ row }: CellProps<any>) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          sticky: 'left',
        },
        {
          Header: t('pages.awards.table.id').toString(),
          accessor: 'id',
          width: 80,
        },
        {
          Header: t('pages.awards.table.created').toString(),
          accessor: 'created',
          width: 160,
        },
        {
          Header: t('pages.awards.table.userCentralId').toString(),
          accessor: 'userCentralId',
          width: 120,
        },
        {
          accessor: 'firstname',
          Header: t('pages.awards.table.firstname').toString(),
          width: 130,
        },
        {
          accessor: 'lastname',
          Header: t('pages.awards.table.lastname').toString(),
          width: 140,
        },
        {
          accessor: 'contactEmail',
          Header: t('pages.awards.table.contactEmail').toString(),
          width: 240,
        },
        {
          accessor: 'mobile',
          Header: t('pages.awards.table.mobile').toString(),
          width: 125,
        },
        {
          accessor: 'companyName',
          Header: t('pages.awards.table.companyName').toString(),
          width: 250,
        },
        {
          accessor: 'awardId',
          Header: t('pages.awards.table.awardId').toString(),
          width: 80,
        },
        {
          accessor: 'awardName',
          Header: t('pages.awards.table.awardName').toString(),
          width: 200,
        },

        {
          accessor: 'value',
          Header: t('pages.awards.table.value').toString(),
          width: 100,
        },
        {
          accessor: 'amount',
          Header: t('pages.awards.table.amount').toString(),
          width: 110,
        },
        {
          accessor: 'totalValue',
          Header: t('pages.awards.table.totalValue').toString(),
          width: 110,
        },
        {
          accessor: 'deliveryDetails',
          Header: t('pages.awards.table.deliveryDetails').toString(),
          width: 350,
        },
        {
          accessor: 'userComment',
          Header: t('pages.awards.table.userComment').toString(),
          width: 240,
        },
        {
          accessor: 'adminComment',
          Header: t('pages.awards.table.adminComment').toString(),
          width: 240,
        },
        {
          accessor: 'superAdminComment',
          Header: t('pages.awards.table.superAdminComment').toString(),
          width: 240,
        },
        {
          accessor: 'awardStateName',
          Header: t('pages.awards.table.awardStateName').toString(),
          width: 110,
        },
        {
          accessor: 'edit',
          Header: t('pages.awards.table.actions').toString(),
          width: 55,
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <Grid container>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.awards.table.show')}`}>
                  <IconButton
                    onClick={() =>
                      handleDetailsDialogClickOpen(params.row.original.id)
                    }
                    size="small"
                    style={{ padding: 0, marginRight: '5px' }}
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
      )

      return columns
    },
    [t],
  )
  useEffect(() => {
    if (selectedRows.length > 0) {
      let amountOfNew: number = 0
      let amountOfConfirmed: number = 0
      let amountOfDeclined: number = 0
      let amountOfClosed: number = 0
      const count = selectedRows.map((cell: any) => {
        return cell
      })
      for (let i = 0; i < count.length; i++) {
        //added eslint-disable-line becouse of wargnings about unused variables

        if (count[i].original.awardStateCode === 'NEW') amountOfNew++
        if (count[i].original.awardStateCode === 'CONFIRMED')
          amountOfConfirmed++ // eslint-disable-line
        if (count[i].original.awardStateCode === 'DECLINED') amountOfDeclined++ // eslint-disable-line
        if (count[i].original.awardStateCode === 'CLOSED') amountOfClosed++
      }

      amountOfNew > 0 ? setCanClose(false) : setCanClose(true)

      if (amountOfClosed > 0) {
        setCanDecline(false)
        setCanConfirm(false)
      } else {
        setCanDecline(true)
        setCanConfirm(true)
      }
    }
  }, [selectedRows])
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const awardStateListResponse = await AwardService.getAwardStatesList()
        if (awardStateListResponse.data.awardStates) {
          setAwardStateList(awardStateListResponse.data.awardStates)
          const activeAwardStateValue = getTableState(
            tableName,
            'activeAwardStateValue',
          )
          if (activeAwardStateValue) {
            setActiveAwardStateValue(activeAwardStateValue)
          } else {
            setActiveAwardStateValue('all')
          }
        }

        const awardTypeListResponse =
          await AwardService.getAwardCatalogTypeList()
        if (awardTypeListResponse.data.awardCatalog) {
          setAwardTypeList(awardTypeListResponse.data.awardCatalog)
          const activeAwardTypeValue = getTableState(
            tableName,
            'activeAwardTypeValue',
          )
          if (activeAwardTypeValue) {
            setActiveAwardTypeValue(activeAwardTypeValue)
          } else {
            setActiveAwardTypeValue('all')
          }
        }

        const periodListResponse = await PeriodService.getPeriodList()
        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
          const periodState = getTableState(tableName, 'period')
          if (periodState) {
            setPeriodValue(periodState)
          } else {
            let activePeriod = null
            activePeriod = periodListResponse.data.periods.find(
              (period) => period.isActive,
            )
            setPeriodValue(activePeriod ? activePeriod.id.toString() : 'all')
          }
        }

        const companyListResponse = await CompanyService.getCompanyList()
        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          const companiesState = getTableState(tableName, 'companies')
          if (companiesState) {
            setSelectedCompanies(JSON.parse(companiesState))
          } else {
            setSelectedCompanies(multiSelectOptions)
          }
        }

        const searchState = getTableState(tableName, 'search')

        if (searchState) {
          setSearchText(searchState)
          setSearchValue(searchState)
        } else {
          setSearchText('')
          setSearchValue('')
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFilters()
  }, [t, tableName])
  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      const fetchId = ++fetchIdRef.current
      if (refresh) {
      }
      // Give this fetch an ID

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        if (selectedCompanies.length) {
          setTableLoading(true)

          try {
            let sortColumn = ''
            let sortDirection = ''
            if (sortBy.length) {
              sortColumn = sortBy[0].id
              sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
            }

            setDownloadSortBy(sortColumn)
            setDownloadSortOrder(sortDirection)

            const dateTimeFrom = dateFrom
              ? moment(dateFrom).format('YYYY-MM-DD') +
                ' ' +
                moment(timeFrom).format('HH:mm') +
                ':00'
              : null

            const dateTimeTo = dateTo
              ? moment(dateTo).format('YYYY-MM-DD') +
                ' ' +
                moment(timeTo).format('HH:mm') +
                ':59'
              : null

            const page = ++pageIndex
            const awardsResponse = await AwardService.getAwardList(
              searchValue,
              sortColumn,
              sortDirection,
              pageSize,
              page,
              activeAwardStateValue ? parseInt(activeAwardStateValue) : null,
              'GAME',
              selectedCompanies.map((company) => {
                return {
                  id: company.value,
                }
              }),
              periodValue === 'all' ? null : parseInt(periodValue),
              null, //loyaltyPeriod,
              activeAwardTypeValue ? parseInt(activeAwardTypeValue) : null, //awardCatalog
              dateTimeFrom,
              dateTimeTo,
              [],
            )

            if (awardsResponse.data) {
              setTotalValue(awardsResponse.data.totalValue)
            }

            if (awardsResponse.data.awards) {
              setTableColumns(generateTableColumns(awardsResponse.data.awards))
              setFilteredAwardsList(awardsResponse.data.awards)

              setTotalCount(awardsResponse.data.totalCount)
              setPageCount(Math.ceil(awardsResponse.data.totalCount / pageSize))
            }
          } catch (error) {
            errorHandler(error, t)
          } finally {
            setSkipPageReset(true)
            setTableLoading(false)
          }
        } else {
          setTableColumns(generateTableColumns([]))

          setFilteredAwardsList([])

          setTotalCount(0)
          setPageCount(0)
        }
      }
    },
    [
      t,
      searchValue,
      generateTableColumns,
      selectedCompanies,
      activeAwardStateValue,
      refresh,
      dateFrom,
      dateTo,
      periodValue,
      timeFrom,
      timeTo,
      activeAwardTypeValue,
    ],
  )

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const dateTimeFrom = dateFrom
        ? moment(dateFrom).format('YYYY-MM-DD') +
          ' ' +
          moment(timeFrom).format('HH:mm') +
          ':00'
        : null

      const dateTimeTo = dateTo
        ? moment(dateTo).format('YYYY-MM-DD') +
          ' ' +
          moment(timeTo).format('HH:mm') +
          ':59'
        : null

      const awardsResponse = await AwardService.getAwardList(
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
        activeAwardStateValue ? parseInt(activeAwardStateValue) : null,
        'GAME',
        selectedCompanies.map((company) => {
          return {
            id: company.value,
          }
        }),
        periodValue === 'all' ? null : parseInt(periodValue),
        null, //loyaltyPeriod,
        activeAwardTypeValue ? parseInt(activeAwardTypeValue) : null, //awardCatalog
        dateTimeFrom,
        dateTimeTo,
        [],
      )

      const dataAwards = awardsResponse.data.awards
      if (dataAwards) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'id',
            'created',
            'userCentralId',
            'firstname',
            'lastname',
            'contactEmail',
            'mobile',
            'companyName',
            'awardId',
            'awardName',
            'value',
            'amount',
            'totalValue',
            'deliveryDetails',
            'userComment',
            'adminComment',
            'superAdminComment',
            'awardStateName',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredAwardsData = dataAwards.map((award) => {
          return pick(award, visibleColumns)
        })

        const translatedHeaders = {
          id: t('excel.awards.id'),
          created: t('excel.awards.created'),
          userCentralId: t('excel.awards.userCentralId'),
          firstname: t('excel.awards.firstname'),
          lastname: t('excel.awards.lastname'),
          contactEmail: t('excel.awards.contactEmail'),
          mobile: t('excel.awards.mobile'),
          companyName: t('excel.awards.companyName'),
          awardId: t('excel.awards.awardId'),
          awardName: t('excel.awards.awardName'),
          value: t('excel.awards.value'),
          amount: t('excel.awards.amount'),
          totalValue: t('excel.awards.totalValue'),
          deliveryDetails: t('excel.awards.deliveryDetails'),
          userComment: t('excel.awards.userComment'),
          adminComment: t('excel.awards.adminComment'),
          superAdminComment: t('excel.awards.superAdminComment'),
          awardStateName: t('excel.awards.awardStateName'),
        }

        const headers = [
          Object.keys(filteredAwardsData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredAwardsData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredUsersData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('awards')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>

          <AwardsToolbar
            periods={periods}
            periodValue={periodValue}
            companies={companies}
            selectedCompanies={selectedCompanies}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
              setTableState(tableName, 'search', searchValue)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
            setSelectedCompanies={(companies) =>
              handleSelectedCompaniesChange(companies)
            }
            awardTypes={awardTypeList}
            awardTypeValue={activeAwardTypeValue}
            awardTypeFilter={(event: SelectChangeEvent) => {
              setActiveAwardTypeValue(event.target.value)
              setTableState(
                tableName,
                'activeAwardTypeValue',
                event.target.value,
              )
            }}
            awardStates={awardStateList}
            awardStateValue={activeAwardStateValue}
            awardStateFilter={(event: SelectChangeEvent) => {
              setActiveAwardStateValue(event.target.value)
              setTableState(
                tableName,
                'activeAwardStateValue',
                event.target.value,
              )
            }}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
              setTableState(tableName, 'period', event.target.value)
              setDateFrom(null)
              setTimeFrom(new Date(new Date().setHours(0, 0, 0, 0)))
              setDateTo(null)
              setTimeTo(new Date(new Date().setHours(0, 0, 0, 0)))
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            dateFrom={dateFrom}
            timeFrom={timeFrom}
            dateTo={dateTo}
            timeTo={timeTo}
            setDateFrom={(date) => {
              setDateFrom(date)
            }}
            setTimeFrom={(time) => {
              setTimeFrom(time)
            }}
            setDateTo={(date) => {
              setDateTo(date)
            }}
            setTimeTo={(time) => {
              setTimeTo(time)
            }}
            totalValue={totalValue}
          />

          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            style={{ marginBottom: '-22px' }}
          >
            <Button
              size="small"
              endIcon={<ThumbUpAltIcon />}
              style={{ textTransform: 'none', marginRight: 5 }}
              disabled={selectedRows.length === 0 || !canConfirm}
              onClick={() =>
                handleAwardsActionsClickOpen(
                  selectedRows,
                  'ACCEPT',
                  t('pages.awards.awardsActionsDialog.titleAccept'),
                )
              }
            >
              {t('pages.awards.accept')}
            </Button>
            <Button
              size="small"
              endIcon={<ThumbDownAltIcon />}
              style={{ textTransform: 'none', marginRight: 5 }}
              disabled={selectedRows.length === 0 || !canDecline}
              onClick={() => {
                handleAwardsActionsClickOpen(
                  selectedRows,
                  'DECLINE',
                  t('pages.awards.awardsActionsDialog.titleDecline'),
                )
              }}
            >
              {t('pages.awards.decline')}
            </Button>
            <Button
              size="small"
              endIcon={<CheckCircleIcon />}
              style={{ textTransform: 'none', marginRight: 5 }}
              disabled={selectedRows.length === 0 || !canClose}
              onClick={() =>
                handleAwardsActionsClickOpen(
                  selectedRows,
                  'CLOSE',
                  t('pages.awards.awardsActionsDialog.titleClose'),
                )
              }
            >
              {t('pages.awards.close')}
            </Button>
          </Stack>

          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredAwardsList}
            height="calc(100vh - 280px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'id',
              'created',
              'contactEmail',
              'mobile',
              'companyName',
              'awardId',
              'awardName',
              'userComment',
              'adminComment',
              'superAdminComment',
            ]}
            toggleVisibility={setColumnsVisibility}
            handleSelectedRows={setSelectedRows}
          />
          <AwardsActionsDialog
            awards={selectedAwardIds}
            actionType={groupActionType}
            title={awardsActionsDialogTitle}
            open={awardsActionsDialogOpen}
            handleClose={handleAwardsActionsClickClose}
          />
          {id && (
            <AwardDetailsDialog
              open={openDetailsDialog}
              handleClose={handleDetailsDialogClose}
              id={id}
            />
          )}
        </>
      )}
    </>
  )
}

export default AwardsList
