import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import UserService from '../../../../services/user.service'
import { DetailsData } from '../../../../store/Details/types'
import DetailsDialog from '../../../shared/DetailsDialog'

type UserAddDialogProps = {
  userId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const UserAddDialog: React.FunctionComponent<UserAddDialogProps> = ({
  userId,
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [userFirstname, setUserFirstname] = useState<string>('')
  const [userLastname, setUserLastname] = useState<string>('')

  const [detailsParamsForDialog, setDetailsParamsForDialog] =
    useState<DetailsData>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const userDetailsResponse = await UserService.getUserDetails(userId)
        if (userDetailsResponse.data) {
          setDetailsParamsForDialog({
            title: {
              label: `${t(
                'pages.users.userDetailsDialog.userId',
              )}: ${userId.toString()}`,
              value: `${userDetailsResponse.data.firstname} ${userDetailsResponse.data.lastname}`,
            },
            data: [
              {
                label: t('pages.users.userDetailsDialog.userCentralId'),
                value: userDetailsResponse.data.userCentralId,
              },
              {
                label: t('pages.users.userDetailsDialog.firstname'),
                value: userDetailsResponse.data.firstname,
              },
              {
                label: t('pages.users.userDetailsDialog.lastname'),
                value: userDetailsResponse.data.lastname,
              },
              {
                label: t('pages.users.userDetailsDialog.email'),
                value: userDetailsResponse.data.email,
              },
              {
                label: t('pages.users.userDetailsDialog.username'),
                value: userDetailsResponse.data.username,
              },
              {
                label: t('pages.users.userDetailsDialog.phoneNumber'),
                value: userDetailsResponse.data.mobile,
              },
              {
                label: t('pages.users.userDetailsDialog.bankAccount'),
                value: userDetailsResponse.data.bankAccount,
              },
              {
                label: t('pages.users.userDetailsDialog.companyName'),
                value: userDetailsResponse.data.companyName,
              },
              {
                label: t('pages.users.userDetailsDialog.regionName'),
                value: userDetailsResponse.data.regionName,
              },
              {
                label: t('pages.users.userDetailsDialog.isActive'),
                value: t(
                  `pages.users.status.${userDetailsResponse.data.userStateCode}`,
                ),
              },
              {
                label: t('pages.users.userDetailsDialog.lastLoginDate'),
                value: userDetailsResponse.data.lastLoginDate,
              },
            ],
          })
          setUserFirstname(userDetailsResponse.data.firstname)
          setUserLastname(userDetailsResponse.data.lastname)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, userId])

  return (
    <>
      {detailsParamsForDialog && (
        <DetailsDialog
          userAvatarId={userId}
          detailsData={detailsParamsForDialog}
          userFirstname={userFirstname}
          userLastname={userLastname}
          open={open}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default UserAddDialog
