import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import CompanyService from '../../../../services/company.service'
import UserService from '../../../../services/user.service'
import StoreDiscountsService from '../../../../services/storeDiscounts.service'
import { useTranslation } from 'react-i18next'
import StoresDiscountsToolbar from '../partials/StoresDiscountsToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { User } from '../../../../store/Auth/types'
import {
  CellProps,
  Column,
  ColumnInstance,
  HeaderProps,
  Row,
} from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import { pick } from 'lodash'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
} from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import StoresDiscountsEditDialog from '../partials/StoresDiscountsEditDialog'
import StoresDiscountsDetailsDialog from '../partials/StoresDiscountsDetailsDialog'
import IndeterminateCheckbox from '../../../Table/IndeterminateCheckbox'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  isCentralAdmin,
  isCompanyAdmin,
  isSuperAdmin,
} from '../../../../helpers/checkRole'
import ImportDialog from '../../../shared/ImportDialog'
import {
  StoreDiscount,
  StoreDiscountType,
} from '../../../../store/StoreDiscount/types'
import StoresDiscountsDeactivationDialog from '../partials/StoresDiscountsDeactivationDialog'
import { UserFilter } from '../../../../store/User/types'

type StoresDiscountsListProps = {
  user: User
}

const StoresDiscountsList: FunctionComponent<StoresDiscountsListProps> = ({
  user,
}) => {
  const tableName = `storesDiscounts`

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredStoresDiscountsList, setFilteredStoresDiscountsList] =
    useState<StoreDiscount[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')

  const [stateValue, setStateValue] = useState<string>('all')
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])

  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const fetchIdRef = useRef(0)

  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [selectedRows, setSelectedRows] = useState<Row<object>[]>([])
  const [selectedStoresDiscountsIds, setSelectedStoresDiscountsIds] = useState<
    Pick<StoreDiscount, 'id'>[]
  >([])

  const [openStoresDiscountsEditDialog, setStoresDiscountsEditDialogOpen] =
    useState(false)
  const [
    openStoresDiscountsDetailsDialog,
    setStoresDiscountsDetailsDialogOpen,
  ] = useState(false)
  const [
    openStoresDiscountsDeactivationDialog,
    setStoresDiscountsDeactivationDialogOpen,
  ] = useState(false)
  const [discountId, setDiscountId] = useState<number | null>(null)
  const [refresh, setRefresh] = useState(false)

  const [users, setUsers] = useState<UserFilter[]>([])
  const [filteredUsers, setFilteredUsers] = useState<Option[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([])

  const [openImportDialog, setImportDialogOpen] = useState(false)

  const [discountTypes, setDiscountTypes] = useState<StoreDiscountType[]>([])
  const [discountTypeValue, setDiscountTypeValue] = useState<string>('')
  const [filteredDiscountTypes, setFilteredDiscountTypes] = useState<Option[]>(
    [],
  )
  const [selectedDiscountTypes, setSelectedDiscountTypes] = useState<Option[]>(
    [],
  )

  const [importName, setImportName] = useState<string | null>(null)
  const [importTitle, setImportTitle] = useState<string>()
  const [importParamDiscount, setImportParamDiscount] = useState<string>()

  const generateTableColumns = useCallback(
    (storesDiscounts: StoreDiscount[]) => {
      const columns = []
      columns.push(
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          width: 40,
          Cell: ({ row }: CellProps<any>) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          sticky: 'left',
        },
        {
          Header: t('pages.storesDiscounts.table.id').toString(),
          accessor: 'id',
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.storesDiscounts.table.storeInternalId').toString(),
          accessor: 'storeInternalId',
          width: 130,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeCentralId',
          Header: t('pages.storesDiscounts.table.storeCentralId').toString(),
          width: 130,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeName',
          Header: t('pages.storesDiscounts.table.storeName').toString(),
          width: 200,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'city',
          Header: t('pages.storesDiscounts.table.city').toString(),
          width: 270,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'companyName',
          Header: t('pages.storesDiscounts.table.companyName').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'countryName',
          Header: t('pages.storesDiscounts.table.countryName').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userFirstname',
          Header: t('pages.storesDiscounts.table.userFirstname').toString(),
          width: 130,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userLastname',
          Header: t('pages.storesDiscounts.table.userLastname').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'typeName',
          Header: t('pages.storesDiscounts.table.typeName').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'discountCentralId',
          Header: t('pages.storesDiscounts.table.discountCentralId').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'discountName',
          Header: t('pages.storesDiscounts.table.discountName').toString(),
          width: 200,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'discountValue',
          Header: t('pages.storesDiscounts.table.discountValue').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'validTo',
          Header: t('pages.storesDiscounts.table.validTo').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'isActive',
          Header: t('pages.storesDiscounts.table.isActive').toString(),
          width: 110,
          Cell: (params: any) =>
            params.value ? <>{t('common.yes')}</> : <>{t('common.no')}</>,
        },
        {
          accessor: 'edit',
          Header: t('pages.storesDiscounts.table.actions').toString(),
          width: 100,
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <>
              <Grid container>
                <Grid
                  item
                  sx={{
                    width: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title={`${t('pages.storesDiscounts.table.edit')}`}>
                    <IconButton
                      onClick={() =>
                        handleStoresDiscountsEditDialogClickOpen(
                          params.row.original.id,
                        )
                      }
                      size="small"
                      style={{ padding: 0 }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title={`${t('pages.storesDiscounts.table.show')}`}>
                    <IconButton
                      onClick={() =>
                        handleStoresDiscountsDetailsDialogClickOpen(
                          params.row.original.id,
                        )
                      }
                      size="small"
                      style={{ padding: 0, marginRight: '5px' }}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ),
        },
      )
      return columns
    },
    [t],
  )

  const handleStoresDiscountsEditDialogClickOpen = (discountId: number) => {
    setDiscountId(discountId)
    setStoresDiscountsEditDialogOpen(true)
  }

  const handleStoresDiscountsEditDialogClose = (
    refreshTable: boolean = false,
  ) => {
    setStoresDiscountsEditDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleStoresDiscountsDetailsDialogClickOpen = (discountId: number) => {
    setDiscountId(discountId)
    setStoresDiscountsDetailsDialogOpen(true)
  }

  const handleStoresDiscountsDetailsDialogClose = (
    refreshTable: boolean = false,
  ) => {
    setStoresDiscountsDetailsDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleStoreDiscountDeactivationDialogClickOpen = (
    selectedRows: Row<any>[],
  ) => {
    setSelectedStoresDiscountsIds(
      selectedRows.map((row) => ({ id: row.original.id })),
    )
    setStoresDiscountsDeactivationDialogOpen(true)
  }

  const handleStoresDiscountsDeactivationDialogClose = (
    refreshTable: boolean = false,
  ) => {
    setStoresDiscountsDeactivationDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleImportDialogClose = (refreshTable: boolean = false) => {
    setImportDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleImportDialogClickOpen = (discountType?: string) => {
    setImportTitle(
      t('pages.storesDiscounts.import.discountsTitle', {
        discountName: discountTypes.find((d) => d.code === discountType)?.name,
      }),
    )
    setImportParamDiscount(discountType)
    switch (discountType) {
      case 'GROUP':
        setImportName('group_discounts')
        break
      case 'MAND':
        setImportName('manufacturer_discounts')
        break
      case 'PRODD':
        setImportName('product_discounts')
        break
      default:
        break
    }
    setImportDialogOpen(true)
  }

  const handleSelectedCompaniesChange = (companies: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setSelectedCompanies(companies)
    setTableState(
      tableName,
      'selectedCompaniesState',
      JSON.stringify(companies),
    )

    const filteredUsersForMultiselect = users
      .filter((user) =>
        companies.map((company) => company.value).includes(user.companyId),
      )
      .map((u) => ({
        value: u.id,
        label: `${u.lastname} ${u.firstname}`,
      }))
    setFilteredUsers(filteredUsersForMultiselect)
    setTableState(
      tableName,
      'filteredUsersState',
      JSON.stringify(filteredUsersForMultiselect),
    )
    setSelectedUsers(filteredUsersForMultiselect)
    setTableState(
      tableName,
      'selectedUsersState',
      JSON.stringify(filteredUsersForMultiselect),
    )
  }
  const handleSelectedUsersChange = (users: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setSelectedUsers(users)
    setTableState(tableName, 'selectedUsersState', JSON.stringify(users))
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )

          setCompanies(multiSelectOptions)
          const selectedCompaniesState = getTableState(
            tableName,
            'selectedCompaniesState',
          )
          if (selectedCompaniesState) {
            setSelectedCompanies(JSON.parse(selectedCompaniesState))
          } else {
            setSelectedCompanies(multiSelectOptions)
          }
        }

        const userListResponse = await UserService.getUserListFilter()

        if (userListResponse.data.users) {
          const multiSelectOptions: Option[] = []
          userListResponse.data.users.forEach((user) =>
            multiSelectOptions.push({
              value: user.id,
              label: `${user.lastname} ${user.firstname}`,
            }),
          )

          setUsers(userListResponse.data.users)
          const selectedUsersState = getTableState(
            tableName,
            'selectedUsersState',
          )
          if (selectedUsersState) {
            setSelectedUsers(JSON.parse(selectedUsersState))
          } else {
            setSelectedUsers(multiSelectOptions)
          }

          const filteredUsersState = getTableState(
            tableName,
            'filteredUsersState',
          )
          if (filteredUsersState) {
            setFilteredUsers(JSON.parse(filteredUsersState))
          } else {
            setFilteredUsers(multiSelectOptions)
          }
        }

        const searchState = getTableState(tableName, 'search')
        const statusState = getTableState(tableName, 'status')

        if (searchState) {
          setSearchText(searchState)
          setSearchValue(searchState)
        } else {
          setSearchText('')
          setSearchValue('')
        }

        if (statusState) {
          setStateValue(statusState)
        } else {
          setStateValue('all')
        }

        const discountTypesListResponse =
          await StoreDiscountsService.getDiscountTypes()

        if (discountTypesListResponse.data.discountTypes) {
          const multiSelectOptions: Option[] = []
          discountTypesListResponse.data.discountTypes.forEach((discountType) =>
            multiSelectOptions.push({
              value: discountType.typeId,
              label: discountType.name,
            }),
          )
          const discountTypesState = getTableState(tableName, 'discountTypes')
          if (discountTypesState) {
            setSelectedDiscountTypes(JSON.parse(discountTypesState))
          } else {
            setSelectedDiscountTypes(multiSelectOptions)
          }
          setFilteredDiscountTypes(multiSelectOptions)
          setDiscountTypes(discountTypesListResponse.data.discountTypes)
          setDiscountTypeValue(
            discountTypesListResponse.data.discountTypes.find(
              (operation: { code: string }) => operation.code === 'GROUP',
            )?.code || '',
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [t, tableName, user])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        if (selectedCompanies.length) {
          setTableLoading(true)
          try {
            let sortColumn = ''
            let sortDirection = ''
            if (sortBy.length) {
              sortColumn = sortBy[0].id
              sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
            }

            setDownloadSortBy(sortColumn)
            setDownloadSortOrder(sortDirection)

            const page = ++pageIndex
            const storesDiscountsResponse =
              await StoreDiscountsService.getStoresDiscountsList(
                selectedCompanies.map((company) => {
                  return {
                    id: company.value,
                  }
                }),
                selectedUsers.map((user) => {
                  return {
                    id: user.value,
                  }
                }),
                selectedDiscountTypes.map((discountType) => {
                  return {
                    id: discountType.value,
                  }
                }), //discountTypes
                searchValue,
                sortColumn,
                sortDirection,
                pageSize,
                page,
                stateValue === 'all' ? null : stateValue === '0' ? false : true,
              )

            if (storesDiscountsResponse.data.discounts) {
              setTableColumns(
                generateTableColumns(storesDiscountsResponse.data.discounts),
              )
              setFilteredStoresDiscountsList(
                storesDiscountsResponse.data.discounts,
              )

              setTotalCount(storesDiscountsResponse.data.totalCount)
              setPageCount(
                Math.ceil(storesDiscountsResponse.data.totalCount / pageSize),
              )
            }
          } catch (error) {
            errorHandler(error, t)
          } finally {
            setSkipPageReset(true)
            setTableLoading(false)
          }
        } else {
          setTableColumns(generateTableColumns([]))

          setFilteredStoresDiscountsList([])

          setTotalCount(0)
          setPageCount(0)
        }
      }
    },
    [
      t,
      selectedCompanies,
      selectedUsers,
      selectedDiscountTypes,
      stateValue,
      searchValue,
      generateTableColumns,
      refresh,
    ],
  )

  const handleSelectedDiscountTypeChange = (discountTypes: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setTableState(tableName, 'discountTypes', JSON.stringify(discountTypes))
    setSelectedDiscountTypes(discountTypes)
  }

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const storesDiscountsResponse =
        await StoreDiscountsService.getStoresDiscountsList(
          selectedCompanies.map((company) => {
            return {
              id: company.value,
            }
          }),
          selectedUsers.map((user) => {
            return {
              id: user.value,
            }
          }),
          selectedDiscountTypes.map((discountType) => {
            return {
              id: discountType.value,
            }
          }),
          searchValue,
          downloadSortBy,
          downloadSortOrder,
          100000,
          1,
          stateValue === 'all' ? null : stateValue === '0' ? false : true,
        )

      const dataStoresDiscounts = storesDiscountsResponse.data.discounts
      if (dataStoresDiscounts) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'id',
            'storeInternalId',
            'storeCentralId',
            'storeName',
            'city',
            'companyName',
            'countryName',
            'userFirstname',
            'userLastname',
            'typeName',
            'discountCentralId',
            'discountName',
            'discountValue',
            'validTo',
            'isActive',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )
        const filteredStoresDiscountsData = dataStoresDiscounts.map(
          (discount) => {
            discount.isActive = discount.isActive
              ? t('common.yes').toString()
              : t('common.no').toString()
            return pick(discount, visibleColumns)
          },
        )

        const translatedHeaders = {
          id: t('excel.storesDiscounts.id'),
          storeInternalId: t('excel.storesDiscounts.storeInternalId'),
          storeCentralId: t('excel.storesDiscounts.storeCentralId'),
          storeName: t('excel.storesDiscounts.storeName'),
          city: t('excel.storesDiscounts.city'),
          companyName: t('excel.storesDiscounts.companyName'),
          countryName: t('excel.storesDiscounts.countryName'),
          userFirstname: t('excel.storesDiscounts.userFirstname'),
          userLastname: t('excel.storesDiscounts.userLastname'),
          typeName: t('excel.storesDiscounts.typeName'),
          discountCentralId: t('excel.storesDiscounts.discountCentralId'),
          discountName: t('excel.storesDiscounts.discountName'),
          discountValue: t('excel.storesDiscounts.discountValue'),
          validTo: t('excel.storesDiscounts.validTo'),
          isActive: t('excel.storesDiscounts.isActive'),
        }

        const headers = [
          Object.keys(filteredStoresDiscountsData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredStoresDiscountsData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresDiscountsData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            alignItems="center"
            flexDirection="row"
            justifyContent={isCompanyAdmin(user) ? 'flex-end' : ''}
            marginBottom={1}
          >
            {(isCentralAdmin(user) || isSuperAdmin(user)) && (
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding={'5px'}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 5,
                  marginRight: '10px',
                }}
              >
                <PrimaryButton
                  variant="contained"
                  onClick={() => handleImportDialogClickOpen(discountTypeValue)}
                  style={{ marginRight: 5 }}
                >
                  {t('pages.storesDiscounts.discountsImport')}
                </PrimaryButton>
                <FormControl size="small">
                  <Select
                    id="period-select"
                    onChange={(event: SelectChangeEvent) => {
                      setDiscountTypeValue(event.target.value)
                    }}
                    value={discountTypeValue}
                    defaultValue={discountTypeValue}
                    style={{ marginLeft: 5 }}
                  >
                    {discountTypes.map((discount) => (
                      <MenuItem value={discount.code} key={discount.code}>
                        {discount.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            )}
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('storesDiscounts')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <StoresDiscountsToolbar
            user={user}
            companies={companies}
            selectedCompanies={selectedCompanies}
            users={filteredUsers}
            selectedUsers={selectedUsers}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
              setTableState(tableName, 'search', searchValue)
            }}
            setSelectedCompanies={(companies: Option[]) => {
              handleSelectedCompaniesChange(companies)
            }}
            setSelectedUsers={(users: Option[]) => {
              handleSelectedUsersChange(users)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
            stateValue={stateValue}
            filterState={(event: SelectChangeEvent) => {
              setStateValue(event.target.value)
              setTableState(tableName, 'status', event.target.value)
            }}
            discountTypes={filteredDiscountTypes}
            selectedDiscountTypes={selectedDiscountTypes}
            setSelectedDiscountTypes={(discountTypes: Option[]) => {
              handleSelectedDiscountTypeChange(discountTypes)
            }}
          />
          {(isCentralAdmin(user) || isSuperAdmin(user)) && (
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
              style={{ marginBottom: '-22px' }}
            >
              <>
                <Button
                  size="small"
                  endIcon={<HighlightOffIcon />}
                  style={{ textTransform: 'none', marginRight: 5 }}
                  disabled={selectedRows.length === 0}
                  onClick={() =>
                    handleStoreDiscountDeactivationDialogClickOpen(selectedRows)
                  }
                >
                  {t('pages.storesDiscounts.deactivation')}
                </Button>
              </>
            </Stack>
          )}
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredStoresDiscountsList}
            height="calc(100vh - 320px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'storeInternalId',
              'city',
              'companyName',
              'countryName',
            ]}
            toggleVisibility={setColumnsVisibility}
            handleSelectedRows={setSelectedRows}
          />
          {discountId && (
            <StoresDiscountsEditDialog
              open={openStoresDiscountsEditDialog}
              handleClose={handleStoresDiscountsEditDialogClose}
              discountId={discountId}
            />
          )}
          {discountId && (
            <StoresDiscountsDetailsDialog
              open={openStoresDiscountsDetailsDialog}
              handleClose={handleStoresDiscountsDetailsDialogClose}
              discountId={discountId}
            />
          )}
          <StoresDiscountsDeactivationDialog
            storesDiscounts={selectedStoresDiscountsIds}
            open={openStoresDiscountsDeactivationDialog}
            handleClose={handleStoresDiscountsDeactivationDialogClose}
          />
          {importName && (
            <ImportDialog
              open={openImportDialog}
              handleClose={handleImportDialogClose}
              name={importName}
              param1={importParamDiscount}
              title={importTitle}
            />
          )}
        </>
      )}
    </>
  )
}

export default StoresDiscountsList
