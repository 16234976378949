import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Grid,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import MessengerService from '../../../../services/messenger.service'
import { toast } from 'react-toastify'

type ClearChatBoxDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  date: string
}

const ClearChatBoxDialog: React.FunctionComponent<ClearChatBoxDialogProps> = ({
  open,
  handleClose,
  date,
}) => {
  const { t } = useTranslation()

  const onClear = async () => {
    await MessengerService.clearMessages(date)
    toast.success(t('messages.success.operationInBackground'))
    handleClose(true)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography component={'span'} variant="body1" fontWeight="bold">
          {t('pages.configuration.clearChatBox.clearChatBoxDialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item sx={{ whiteSpace: 'pre-line', mt: 2 }}>
            {t(
              'pages.configuration.clearChatBox.clearChatBoxDialog.clearDescription',
            )}
          </Grid>
          <Grid
            item
            sx={{ fontStyle: 'italic', whiteSpace: 'pre-line', mb: 2 }}
          >
            {date}
          </Grid>
          <Grid
            item
            sx={{
              fontWeight: 'bold',
              color: 'red',
              whiteSpace: 'pre-line',
              my: 2,
            }}
          >
            {t(
              'pages.configuration.clearChatBox.clearChatBoxDialog.clearAlert',
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onClear}>{t('common.submit')}</PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default ClearChatBoxDialog
