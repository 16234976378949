import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { Period } from '../../../../store/Period/types'
import {
  ChallengeState,
  ChallengeTypes,
} from '../../../../store/Challenge/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'

interface ChallengesToolbarProps {
  clearSearch: () => void
  onChange: (event: { target: { value: string } }) => void
  submitSearch: (value: string) => void
  filterPeriod: (event: SelectChangeEvent) => void
  filterStatus: (event: SelectChangeEvent) => void
  filterChallengeTypes: (event: SelectChangeEvent) => void
  value: string
  periodValue: string
  statusValue: string
  challengeTypeValue: string
  periods: Period[]
  statuses: ChallengeState[]
  challengeTypes: ChallengeTypes[]
}

export default function ChallengesToolbar(props: ChallengesToolbarProps) {
  const { t } = useTranslation()

  const onSearchKeyDown = (e: { keyCode: number }) => {
    // on enter press
    if (e.keyCode === 13) {
      props.submitSearch(props.value)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 10,
        alignItems: 'flex-end',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl size="small" sx={{ marginRight: 1 }}>
          <label>{t('pages.challenge.toolbar.filters.status')}</label>
          <Select
            id="status-select"
            onChange={props.filterStatus}
            value={props.statusValue}
            defaultValue={props.statusValue}
          >
            <MenuItem value="all">{t('common.all')}</MenuItem>
            {props.statuses.map((status) => (
              <MenuItem value={status.code} key={status.code}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ marginRight: 1 }}>
          <label>{t('pages.challenge.toolbar.filters.period')}</label>
          <Select
            id="period-select"
            onChange={props.filterPeriod}
            value={props.periodValue}
            defaultValue={props.periodValue}
          >
            <MenuItem value="all">{t('common.all')}</MenuItem>
            {props.periods.map((period) => (
              <MenuItem value={period.id} key={period.id}>
                {period.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small">
          <label>{t('pages.challenge.toolbar.filters.challengeType')}</label>
          <Select
            id="period-select"
            onChange={props.filterChallengeTypes}
            value={props.challengeTypeValue}
            defaultValue={props.challengeTypeValue}
          >
            <MenuItem value="all">{t('common.all')}</MenuItem>
            {props.challengeTypes.map((challengeType) => (
              <MenuItem value={challengeType.id} key={challengeType.id}>
                {challengeType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex' }}>
        <TextField
          variant="outlined"
          size="small"
          value={props.value}
          onChange={props.onChange}
          placeholder={t('pages.challenge.table.search')}
          onKeyDown={onSearchKeyDown}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          inputProps={{ maxLength: 100 }}
        />
        <PrimaryButton
          onClick={() => props.submitSearch(props.value)}
          style={{
            padding: '7px 5px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: '40px',
          }}
        >
          {t('common.search')}
        </PrimaryButton>
      </div>
    </div>
  )
}
