import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import PlanService from '../../../../services/plan.service'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'
import { DetailsData } from '../../../../store/Details/types'
import DetailsDialog from '../../../shared/DetailsDialog'

type PlanDetailsDialogProps = {
  planId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const PlanDetailsDialog: React.FunctionComponent<PlanDetailsDialogProps> = ({
  planId,
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [detailsParamsForDialog, setDetailsParamsForDialog] =
    useState<DetailsData>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const planDetailsResponse = await PlanService.getPlanDetails(planId)

        if (planDetailsResponse.data) {
          const planDetailsParams: DetailsData = {
            title: {
              label: `${t(
                'pages.plans.planDetailsDialog.planId',
              )}: ${planId.toString()}`,
              value: planDetailsResponse.data.name,
            },
            data: [
              {
                label: t('pages.plans.planDetailsDialog.periodName'),
                value: planDetailsResponse.data.periodName,
              },
              {
                label: t('pages.plans.planDetailsDialog.validFrom'),
                value: planDetailsResponse.data.validFrom,
              },
              {
                label: t('pages.plans.planDetailsDialog.validTo'),
                value: planDetailsResponse.data.validTo,
              },
            ],
          }
          if (isXpPointsVisible()) {
            planDetailsParams.data.push({
              label: t('pages.plans.planDetailsDialog.xpStrategyDescription'),
              value: planDetailsResponse.data.xpStrategyDescription,
            })
          }
          if (isVpPointsVisible()) {
            planDetailsParams.data.push({
              label: t('pages.plans.planDetailsDialog.vpStrategyDescription'),
              value: planDetailsResponse.data.vpStrategyDescription,
            })
          }
          if (isEpPointsVisible()) {
            planDetailsParams.data.push({
              label: t('pages.plans.planDetailsDialog.epStrategyDescription'),
              value: planDetailsResponse.data.epStrategyDescription,
            })
          }

          setDetailsParamsForDialog(planDetailsParams)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, planId])

  return (
    <>
      {detailsParamsForDialog && (
        <DetailsDialog
          detailsData={detailsParamsForDialog}
          open={open}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default PlanDetailsDialog
