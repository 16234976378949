import { styled } from '@mui/material/styles'
import { Button, ButtonProps } from '@mui/material'
import { FunctionComponent } from 'react'

const NavButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.buttons.color,
  borderBottom: '2px solid #dcdcdc',
  borderBottomWidth: '3px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  svg: {
    marginRight: '5px;',
    height: '20px;',
  },
  '.navButton-txt': {
    lineHeight: '15px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  '&:hover': {
    borderBottomColor: theme.buttons.secondary.border.color,
    borderBottomWidth: '3px',
    borderBottomStyle: theme.buttons.border.style,
    svg: {
      fill: theme.buttons.secondary.border.color,
    },
  },
}))
const NavButtonContainer = styled('span')(({ theme }) => ({
  '.active-btn': {
    borderBottomColor: theme.buttons.secondary.border.color,
    borderBottomWidth: '3px',
    borderBottomStyle: theme.buttons.border.style,
    svg: {
      fill: theme.buttons.secondary.border.color,
    },
  },
}))

type InnerNavButtonProps = {
  name: string
  active: boolean
  icon?: React.ReactNode
  onClick: () => void
}
const InnerNavButton: FunctionComponent<InnerNavButtonProps> = ({
  name,
  active,
  icon,
  onClick,
}) => {
  return (
    <NavButtonContainer onClick={onClick}>
      <NavButton className={active ? `active-btn` : ''}>
        {icon} <span className="navButton-txt">{name}</span>
      </NavButton>
    </NavButtonContainer>
  )
}
export default InnerNavButton
