import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import * as XLSX from 'xlsx'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import ChallengeRealizationMainDonut from './partials/ChallengeRealizationMainDonut'
import TakenChallengesStackedBar, {
  LevelBadge,
} from './partials/TakenChallengesStackedBar'
import ChallengesStatisticsToolbar from './partials/ChallengesStatisticsToolbar'
import { Option } from '../../../../store/types'
import { Period } from '../../../../store/Period/types'
import {
  ChallengeAnalytics,
  ChallengeFilter,
  ChallengeTypes,
} from '../../../../store/Challenge/types'
import { User } from '../../../../store/Auth/types'
import PeriodService from '../../../../services/period.service'
import ChallengeService from '../../../../services/challenge.service'
import CompanyChart from './partials/CompanyChart'
import Image from '../../../shared/Image'
import NoImg from '../../../../assets/images/phone/no.png'
import { thousandsSeparator } from '../../../../helpers/utils'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'

const StyledDiv = styled('div')({
  background: '#f1f1f1',
  borderRadius: '3px',
  padding: '15px 20px',
  height: '100%',
})

type ChallengesStatisticsProps = {
  user: User
}

const ChallengesStatistics: React.FunctionComponent<
  ChallengesStatisticsProps
> = ({ user }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingChart, setLoadingChart] = useState<boolean>(true)
  const [searchText, setSearchText] = useState<string>('')
  const [periodValue, setPeriodValue] = useState<string | null>(null)

  const [periods, setPeriods] = useState<Period[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [challengesFilter, setChallengesFilter] = useState<Option[]>([])
  const [challenges, setChallenges] = useState<ChallengeFilter[]>([])
  const [selectedChallengesFilter, setSelectedChallengesFilter] = useState<
    Option[]
  >([])

  const [challengeAnalyticsData, setChallengeAnalyticsData] = useState<
    ChallengeAnalytics[]
  >([])
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadingChallengeId, setDownloadingChallengeId] = useState<
    number | null
  >(null)

  const [challengeTypes, setChallengeTypes] = useState<ChallengeTypes[]>([])
  const [challengeTypeValue, setChallengeTypeValue] = useState<string>('all')

  useEffect(() => {
    const filterData = async () => {
      try {
        const challengesTypesResponse =
          await ChallengeService.getChallengeTypes()

        if (challengesTypesResponse.data) {
          setChallengeTypes(challengesTypesResponse.data.challengeTypes)
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }

    filterData()
  }, [t])

  useEffect(() => {
    const fetchData = async () => {
      setLoadingChart(true)
      try {
        const challengeAnalyticsResponse =
          await ChallengeService.getChallengeChartResult(
            selectedChallengesFilter.map((challenge) => ({
              id: challenge.value,
            })),
            challengeTypeValue === 'all' ? null : parseInt(challengeTypeValue),
            searchValue,
            null,
            null,
            1000,
            1,
          )

        if (challengeAnalyticsResponse.data.challengeAnalytics) {
          setChallengeAnalyticsData(
            challengeAnalyticsResponse.data.challengeAnalytics,
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoadingChart(false)
      }
    }
    if (!loading) {
      fetchData()
    }
  }, [t, selectedChallengesFilter, searchValue, challengeTypeValue, loading])

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)

        const challengesFilterResponse =
          await ChallengeService.getChallengeFilter()

        if (challengesFilterResponse.data.challenges) {
          setChallenges(challengesFilterResponse.data.challenges)
          const periodListResponse = await PeriodService.getPeriodList()
          let activePeriod = null

          if (periodListResponse.data.periods) {
            setPeriods(periodListResponse.data.periods)
            activePeriod = periodListResponse.data.periods.find(
              (period) => period.isActive,
            )
            setPeriodValue(activePeriod ? activePeriod.id.toString() : null)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
  }, [t])

  useEffect(() => {
    const filterChallenges = async () => {
      const multiSelectOptions: Option[] = []
      if (periodValue === null) {
        challenges.forEach((challenge) =>
          multiSelectOptions.push({
            value: challenge.id,
            label: challenge.name,
          }),
        )
      } else {
        challenges
          .filter((challenge) => challenge.periodId === parseInt(periodValue))
          .forEach((challenge) =>
            multiSelectOptions.push({
              value: challenge.id,
              label: challenge.name,
            }),
          )
      }

      setSelectedChallengesFilter(multiSelectOptions)
      setChallengesFilter(multiSelectOptions)
    }
    if (periodValue !== '' && challenges.length > 0) {
      filterChallenges()
    }
  }, [periodValue, challenges])

  const downloadChallengeSummaryReportXLSX = async (
    name: string,
    id: number,
  ) => {
    try {
      setIsDownloading(true)

      const challengeSummaryResponse =
        await ChallengeService.getChallengeSummaryReport(id)
      let fileName = `filename.xlsx`
      const dataChallengeSummaryReport = challengeSummaryResponse.data.results
      if (dataChallengeSummaryReport) {
        fileName = `${challengeSummaryResponse.data.periodName}_${challengeSummaryResponse.data.challengeId}_${challengeSummaryResponse.data.challengeName}.xlsx`

        const translatedHeaders = {
          challenge: t('excel.challengesChart.challenge'),
          companyCentralId: t('excel.challengesChart.companyCentralId'),
          companyName: t('excel.challengesChart.companyName'),
          userCentralId: t('excel.challengesChart.userCentralId'),
          firstname: t('excel.challengesChart.firstname'),
          lastname: t('excel.challengesChart.lastname'),
          challengeDifficultyLevelName: t(
            'excel.challengesChart.challengeDifficultyLevelName',
          ),
          plan: t('excel.challengesChart.plan'),
          realization: t('excel.challengesChart.realization'),
          percentRealization: t('excel.challengesChart.percentRealization'),
        }
        /* flatten objects */
        const rows = dataChallengeSummaryReport.map((row) => ({
          challenge:
            challengeSummaryResponse.data.periodName +
            ' ' +
            challengeSummaryResponse.data.challengeId +
            ' ' +
            challengeSummaryResponse.data.challengeName,
          companyCentralId: row.companyCentralId,
          companyName: row.companyName,
          userCentralId: row.userCentralId,
          firstname: row.firstname,
          lastname: row.lastname,
          challengeDifficultyLevelName: row.challengeDifficultyLevelName
            ? row.challengeDifficultyLevelName
            : t('excel.challengesChart.noData'),
          plan: row.plan,
          realization: row.realization,
          percentRealization: row.percentRealization,
        }))
        const headers = [
          Object.keys(rows[0]).map((key) => (translatedHeaders as any)[key]),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, rows, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredChallengeSummaryReportData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && periodValue && (
        <Box style={{ margin: '0 20px' }}>
          <ChallengesStatisticsToolbar
            periods={periods}
            periodValue={periodValue}
            challengeTypes={challengeTypes}
            challengeTypeValue={challengeTypeValue}
            value={searchText}
            challengesFilter={challengesFilter}
            selectedChallengesFilter={selectedChallengesFilter}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSearchValue(searchValue)
            }}
            clearSearch={() => {
              setSearchText('')
              setSearchValue('')
            }}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
            }}
            setSelectedChallengesFilter={(challenges: Option[]) => {
              setSelectedChallengesFilter(challenges)
            }}
            filterChallengeTypes={(event: SelectChangeEvent) => {
              setChallengeTypeValue(event.target.value)
            }}
            user={user}
          />
        </Box>
      )}
      {loadingChart && <LoadingSpinner />}
      {!loadingChart && (
        <>
          {challengeAnalyticsData.length === 0 && (
            <Alert
              severity="info"
              variant="filled"
              style={{ margin: '0 20px' }}
            >
              {t('pages.challengesStatistics.noDataToDisplay')}
            </Alert>
          )}
          {challengeAnalyticsData.map((item) => (
            <Stack
              flexDirection="row"
              style={{ margin: '0 20px', borderBottom: '2px solid #fff' }}
              key={item.challengeId}
            >
              <Grid container>
                <Grid item width="500px">
                  <StyledDiv>
                    <Stack
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Image
                          imageId={item.logoId}
                          style={{
                            width: '150px',
                            height: '40px',
                            objectFit: 'contain',
                            margin: '0 auto',
                          }}
                        />
                        <Typography
                          variant="body2"
                          component="div"
                          fontWeight="bold"
                          style={{
                            background: '#fff',
                            padding: '3px 6px',
                            borderRadius: '4px',
                            border: '1px solid #e2e2e2',
                            marginLeft: 'auto',
                          }}
                        >
                          {item.challengeName}
                        </Typography>
                      </Stack>
                      <Stack
                        flexDirection="row"
                        alignItems="flex-end"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <div>
                          <ChallengeRealizationMainDonut
                            percent={item.totalPercentRealization}
                          />
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography variant="caption" component="div">
                              <strong>
                                {thousandsSeparator(item.totalPlan)}
                              </strong>
                              <br />
                              {t('pages.challengesStatistics.plan')}
                            </Typography>
                            <Typography
                              variant="caption"
                              component="div"
                              textAlign="right"
                            >
                              <strong>
                                {thousandsSeparator(item.totalRealization)}
                              </strong>
                              <br />
                              {t('pages.challengesStatistics.realization')}
                            </Typography>
                          </Stack>
                          <Stack width="90px" margin="0 auto">
                            <SecondaryButton
                              style={{ width: '90px' }}
                              variant="contained"
                              onClick={() => {
                                downloadChallengeSummaryReportXLSX(
                                  `challenge ${item.challengeId} summary report`,
                                  item.challengeId,
                                )
                                setDownloadingChallengeId(item.challengeId)
                              }}
                              sx={{
                                marginLeft: 'auto',
                              }}
                              disabled={
                                isDownloading &&
                                downloadingChallengeId === item.challengeId
                              }
                            >
                              {isDownloading &&
                                downloadingChallengeId === item.challengeId && (
                                  <CircularProgress
                                    style={{
                                      height: 12,
                                      width: 12,
                                      marginRight: 10,
                                    }}
                                  />
                                )}

                              {t('common.xlsx')}
                            </SecondaryButton>
                          </Stack>
                        </div>
                        <div style={{ width: '73px', marginLeft: 'auto' }}>
                          <Grid textAlign="center">
                            <div
                              style={{
                                width: '29px',
                                height: '90px',
                                background: 'transparent',
                                margin: '0 auto 5px',
                                borderRadius: '2px',
                              }}
                            ></div>
                            <Typography
                              variant="caption"
                              display="block"
                              fontWeight="bold"
                              letterSpacing={0}
                            >
                              <img style={{ width: 30 }} src={NoImg} alt="" />
                            </Typography>
                            <LevelBadge>{item.noAcceptances}</LevelBadge>
                          </Grid>
                        </div>
                        <TakenChallengesStackedBar
                          amateurAcceptances={item.amateurAcceptances}
                          amateurCompleted={item.amateurCompleted}
                          expertAcceptances={item.expertAcceptances}
                          expertCompleted={item.expertCompleted}
                          masterAcceptances={item.masterAcceptances}
                          masterCompleted={item.masterCompleted}
                        />
                      </Stack>
                    </Stack>
                  </StyledDiv>
                </Grid>
                <Grid item width="calc(100% - 500px)">
                  <StyledDiv>
                    <CompanyChart chartData={item.companies} user={user} />
                  </StyledDiv>
                </Grid>
              </Grid>
            </Stack>
          ))}
        </>
      )}
    </>
  )
}

export default ChallengesStatistics
