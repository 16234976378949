import {
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  BorderLinearProgress,
  PhoneAppBarDetails,
  PhoneContent,
  PhoneStoreCard,
} from '../styles'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import GameService from '../../../services/game.service'
import { useEffect, useState } from 'react'
import { errorHandler } from '../../../helpers/errorHandler'
import {
  UserChallengesPreview,
  ChallengeDetailsStores,
} from '../../../store/Game/types'
import {
  arraySearch,
  sortFunction,
  thousandsSeparator,
} from '../../../helpers/utils'
import PlaceIcon from '@mui/icons-material/Place'
import { ReactComponent as CompletedIcon } from '../../../assets/images/icons/completed_icon.svg'
import LoadingSpinner from '../../shared/LoadingSpinner'

type ChallengeStoresScreenProps = {
  userId: number
  challengeId: number
  challenge: UserChallengesPreview
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>
}

const ChallengeStoresScreen: React.FunctionComponent<
  ChallengeStoresScreenProps
> = ({ userId, challengeId, challenge, setCurrentScreen }) => {
  const { t } = useTranslation()

  const [storesList, setStoresList] = useState<ChallengeDetailsStores[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const handleOnSearchChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let value = e.target.value
    const challengeStoresDetailsForSearch =
      await GameService.getChallengeDetailsStores(userId, challengeId)
    let search = await arraySearch(
      challengeStoresDetailsForSearch.data.stores,
      value,
      'name',
    )
    setStoresList(sortFunction(search, 'name'))
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const challengesDetailsStoresResponse =
          await GameService.getChallengeDetailsStores(userId, challengeId)
        if (challengesDetailsStoresResponse.data.stores) {
          setStoresList(challengesDetailsStoresResponse.data.stores)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, userId, challengeId])

  const renderStore = (index: number, storeDetails: ChallengeDetailsStores) => {
    return (
      <>
        {loading && (
          <>
            <LoadingSpinner />
          </>
        )}
        {!loading && (
          <PhoneStoreCard even={index % 2 === 1 ? true : false}>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              marginBottom={'10px'}
            >
              <Stack>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  lineHeight="normal"
                  textAlign="left"
                >
                  {storeDetails.storeCentralId + ' ' + storeDetails.name}
                </Typography>
                <Stack display="flex" flexDirection="row" alignItems="center">
                  <PlaceIcon fontSize="small" htmlColor="#808080" />
                  <Typography variant="caption" color="#808080">
                    {storeDetails.city +
                      ', ' +
                      storeDetails.street +
                      ' ' +
                      storeDetails.homeNumber}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <CompletedIcon
                  fill={storeDetails.isChallengeCompleted ? 'green' : 'red'}
                />
              </Stack>
            </Stack>

            <BorderLinearProgress
              variant="determinate"
              value={
                storeDetails.resultPercentValue <= 100
                  ? storeDetails.resultPercentValue
                  : 100
              }
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor:
                    storeDetails.resultPercentValue < 100 ? 'red' : 'green',
                },
              }}
            />
            <Stack
              display="flex"
              flexDirection="row"
              fontSize="12px"
              justifyContent="space-between"
              alignItems="center"
              fontWeight="400"
              marginTop="4px"
            >
              <div>
                {t(
                  'pages.game.phone.screens.challengeStoresScreen.resultValue',
                )}{' '}
                {thousandsSeparator(storeDetails.resultValue)}
              </div>
              <div>
                {t('pages.game.phone.screens.challengeStoresScreen.planValue')}{' '}
                {thousandsSeparator(storeDetails.planValue)}
              </div>
            </Stack>
          </PhoneStoreCard>
        )}
      </>
    )
  }

  return (
    <>
      <PhoneAppBarDetails>
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          px="8px"
        >
          <IconButton onClick={() => setCurrentScreen('challenge-options')}>
            <ArrowBackIcon htmlColor="#FFD748" />
          </IconButton>
          {challenge.name}
          <Stack width={40}></Stack>
        </Stack>
      </PhoneAppBarDetails>
      <PhoneContent>
        <Stack
          display="flex"
          flexDirection="row"
          padding="0 16px 16px 16px"
          alignItems="flex-end"
          marginTop={'15px'}
        >
          <FormControl fullWidth>
            <TextField
              id="input-with-icon-textfield"
              onChange={handleOnSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  height: '48px',
                },
              }}
              variant="outlined"
              style={{
                background: '#fff',
                height: '48px',
              }}
              placeholder={t(
                'pages.game.phone.screens.challengeStoresScreen.search',
              )}
            />
          </FormControl>
        </Stack>

        {storesList && (
          <>
            {storesList.map((p, i) => {
              return renderStore(i, p)
            })}
          </>
        )}
      </PhoneContent>
    </>
  )
}

export default ChallengeStoresScreen
