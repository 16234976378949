import { AlertColor } from '@mui/material'
import { TFunction } from 'react-i18next'
import { downloadXLSX, thousandsSeparator } from '../../../../../helpers/utils'
import { StoresDiscountsResponse } from '../../../../../store/Import/types'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'

export const getStoresDiscountsResponse = (
  importResponse: StoresDiscountsResponse,
  messageType: 'prepare' | 'accept',
  alertType: AlertColor,
  t: TFunction<'translation'>,
): {
  message: {
    type: AlertColor
    message: string
    hasSecondStep: boolean
  }
  additionalInfo?: React.ReactNode
} | null => {
  if (importResponse.data.skippedStores) {
    const skippedStores = importResponse.data.skippedStores
    const skippedGroups = importResponse.data.skippedGroups
    const skippedManufacturers = importResponse.data.skippedManufacturers
    const skippedProducts = importResponse.data.skippedProducts
    const addedResultsSummary = importResponse.data.addedResultsSummary
    const updatedResultsSummary = importResponse.data.updatedResultsSummary
    const skippedResultsSummary = importResponse.data.skippedResultsSummary

    const renderAdditionalInfo = () => {
      return (
        <div>
          {skippedStores.length > 0 && (
            <SecondaryButton
              onClick={() =>
                downloadXLSX(
                  skippedStores.map((result) => ({
                    storeCentralId: result,
                  })),
                  t('import.messages.storesDiscounts.skippedResultsFilename'),
                )
              }
              size="small"
              style={{ marginBottom: '10px', marginRight: '5px' }}
            >
              {t('import.messages.storesDiscounts.downloadSkippedResults')}
            </SecondaryButton>
          )}
          {skippedGroups && skippedGroups.length > 0 && (
            <SecondaryButton
              onClick={() =>
                downloadXLSX(
                  skippedGroups.map((result) => ({
                    groups: result,
                  })),
                  t('import.messages.storesDiscounts.skippedGroupsFilename'),
                )
              }
              size="small"
              style={{ marginBottom: '10px' }}
            >
              {t('import.messages.storesDiscounts.downloadSkippedGroups')}
            </SecondaryButton>
          )}
          {skippedManufacturers && skippedManufacturers.length > 0 && (
            <SecondaryButton
              onClick={() =>
                downloadXLSX(
                  skippedManufacturers.map((result) => ({
                    manufacturers: result,
                  })),
                  t(
                    'import.messages.storesDiscounts.skippedManufacturersFilename',
                  ),
                )
              }
              size="small"
              style={{ marginBottom: '10px' }}
            >
              {t(
                'import.messages.storesDiscounts.downloadSkippedManufacturers',
              )}
            </SecondaryButton>
          )}
          {skippedProducts && skippedProducts.length > 0 && (
            <SecondaryButton
              onClick={() =>
                downloadXLSX(
                  skippedProducts.map((result) => ({
                    products: result,
                  })),
                  t('import.messages.storesDiscounts.skippedProductsFilename'),
                )
              }
              size="small"
              style={{ marginBottom: '10px' }}
            >
              {t('import.messages.storesDiscounts.downloadSkippedProducts')}
            </SecondaryButton>
          )}
        </div>
      )
    }

    return {
      message: {
        type: alertType,
        message: t(`import.messages.storesDiscounts.${messageType}Message`, {
          addedResultsSummary: thousandsSeparator(addedResultsSummary || 0),
          updatedResultsSummary: thousandsSeparator(updatedResultsSummary || 0),
          skippedResultsSummary: thousandsSeparator(skippedResultsSummary || 0),
        }),
        hasSecondStep: false,
      },
      additionalInfo: renderAdditionalInfo(),
    }
  }
  return null
}
