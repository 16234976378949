import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import StoreDiscountsService from '../../../../services/storeDiscounts.service'
import { toast } from 'react-toastify'
import { StoreDiscount } from '../../../../store/StoreDiscount/types'

type StoresDiscountsDeactivationDialogProps = {
  storesDiscounts: Pick<StoreDiscount, 'id'>[]
  open: boolean
  handleClose: (refresh: boolean) => void
}

const StoresDiscountsDeactivationDialog: React.FunctionComponent<
  StoresDiscountsDeactivationDialogProps
> = ({ storesDiscounts, open, handleClose }) => {
  const { t } = useTranslation()
  const [saving, setSaving] = useState<boolean>(false)

  const onConfirm = async () => {
    try {
      setSaving(true)
      const storesDiscountsDeactivateResponse =
        await StoreDiscountsService.storesDiscountsDeactivate(
          storesDiscounts.map((discount) => ({ id: discount.id })),
        )

      if (storesDiscountsDeactivateResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  const renderDiscountsIds = (storesDiscounts: Pick<StoreDiscount, 'id'>[]) =>
    storesDiscounts.map((discount) => discount.id).join(', ')

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.storesDiscounts.storesDiscountsDeactivationDialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            {t(
              'pages.storesDiscounts.storesDiscountsDeactivationDialog.forDiscounts',
            )}
            : {renderDiscountsIds(storesDiscounts)}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              handleClose(false)
            }}
            disabled={saving}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onConfirm} disabled={saving}>
            {t(
              'pages.storesDiscounts.storesDiscountsDeactivationDialog.submit',
            )}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default StoresDiscountsDeactivationDialog
