import React from 'react'
import {
  Column,
  ColumnInstance,
  Row,
  useBlockLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'

import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useSticky } from 'react-table-sticky'
import { useTranslation } from 'react-i18next'

import './Table.scss'
import {
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
} from '../../helpers/utils'

interface TableControlledProps {
  name?: string
  columns: Array<Column<object>>
  data: Array<object>
  height: string
  fetchData: ({ pageSize, pageIndex, sortBy }: any) => Promise<void>
  loading: boolean
  pageIndex: number
  pageCount: number
  totalCount: number
  skipPageReset: boolean
  columnsVisibility: string[]
  toggleVisibility: (allColumns: ColumnInstance<object>[]) => void
  handleSelectedRows?: (selectedFlatRows: Row<object>[]) => void
  saveTableState?: boolean
}

const TableControlled: React.FC<TableControlledProps> = ({
  name = '',
  columns,
  data,
  height,
  fetchData,
  loading,
  pageIndex: controlledPageIndex,
  pageCount: controlledPageCount,
  totalCount,
  skipPageReset,
  columnsVisibility,
  toggleVisibility,
  handleSelectedRows,
  saveTableState = true,
}) => {
  const { t } = useTranslation()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    setPageSize,
    allColumns,
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiSort: true,
      initialState: {
        pageIndex: saveTableState
          ? parseInt(
              getTableState(name, 'page-index') ||
                controlledPageIndex.toString(),
            )
          : controlledPageIndex,
        pageSize: saveTableState
          ? parseInt(getTableState(name, 'page-size') || '100')
          : 100,
        hiddenColumns: getHiddenColumns(name, columnsVisibility),
        sortBy: [
          {
            id: saveTableState ? getTableState(name, 'sort-by') || '' : '',
            desc: saveTableState
              ? getTableState(name, 'sort-order') === 'DESC'
                ? true
                : false
              : false,
          },
        ],
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: !skipPageReset,
      autoResetSortBy: false,
      autoResetHiddenColumns: false,
      manualSortBy: true,
    },
    useSortBy,
    useBlockLayout,
    useSticky,
    usePagination,
    useRowSelect,
  )

  const getCellStyles = (cell: any) => {
    const colorId = cell.column.id.split('--')[1]
    if (colorId) {
      const color = cell.row.original.planResults[colorId].colorHexValue
      if (color) {
        return {
          backgroundColor: `${color}15`,
          textAlign: typeof cell.value === 'number' ? 'right' : 'left',
        }
      }
    }
    return { textAlign: typeof cell.value === 'number' ? 'right' : 'left' }
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    gotoPage(value - 1)
  }

  React.useEffect(() => {
    setTableState(name, 'page-index', pageIndex.toString())
    setTableState(name, 'page-size', pageSize.toString())
    if (sortBy.length > 0) {
      setTableState(name, 'sort-by', sortBy[0].id)
      setTableState(name, 'sort-order', sortBy[0].desc ? 'DESC' : 'ASC')
    }
    if (!skipPageReset) {
      gotoPage(0)
    }
    if (pageIndex > 0 && !skipPageReset) {
      return
    }
    fetchData({ pageIndex, pageSize, sortBy })
  }, [fetchData, pageIndex, pageSize, sortBy, gotoPage, name]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (handleSelectedRows) {
      handleSelectedRows(selectedFlatRows)
    }
  }, [selectedFlatRows, handleSelectedRows])

  // Render the UI for your table
  return (
    <>
      <Stack flexDirection={'row'}>
        {allColumns.map(
          (column) =>
            columnsVisibility.includes(column.id) && (
              <Stack
                key={column.id}
                flexDirection="row"
                alignItems="center"
                mr={2}
                style={{ marginBottom: '3px' }}
              >
                <IconButton
                  onClick={() => {
                    column.toggleHidden()
                    localStorage.setItem(
                      `${name}-${column.id}`,
                      JSON.stringify(!column.isVisible),
                    )
                    toggleVisibility(allColumns)
                  }}
                  style={{
                    width: 14,
                    height: 14,
                    marginRight: 7,
                    marginLeft: 5,
                  }}
                >
                  {column.isVisible ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
                <Typography variant="body2">
                  {t(`pages.${name}.table.${column.id}`)}
                </Typography>
              </Stack>
            ),
        )}
      </Stack>
      <div
        style={{
          width: '100%',
          display: 'inline-block',
          maxWidth: '100%',
          overflow: 'scroll',
          height,
          border: '1px solid #EAEAEA',
        }}
      >
        <MuiTable {...getTableProps()} className={`table sticky ${name}`}>
          <TableHead className="header">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardOutlinedIcon
                            sx={{ fontSize: 15, marginLeft: '5px' }}
                            color="disabled"
                          />
                        ) : (
                          <ArrowUpwardOutlinedIcon
                            sx={{ fontSize: 15, marginLeft: '5px' }}
                            color="disabled"
                          />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps}>
            {loading && (
              <TableRow>
                <Stack display="flex" justifyContent="center">
                  <CircularProgress sx={{ m: 1 }} />
                  {t('table.loading')}
                </Stack>
              </TableRow>
            )}
            {!loading &&
              page.map((row, i) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <TableCell
                          {...cell.getCellProps({
                            style: getCellStyles(cell),
                          })}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            {!loading && page.length === 0 && (
              <TableRow>
                <TableCell>{t('common.noData')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </div>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        style={{
          backgroundColor: '#DCDCDC',
          marginTop: '-6px',
          padding: '3px 10px',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl size="small">
            <Select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[20, 50, 100, 200, 500].map((pageSize) => (
                <MenuItem key={pageSize} value={pageSize}>
                  {t('table.show')} {pageSize}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!loading && (
            <Typography variant="body2" display="block">
              {t('table.found')}: {totalCount} {t('table.results')}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              page={pageIndex + 1}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default TableControlled
