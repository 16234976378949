import React from 'react'
import { PlotData } from '../../../store/Game/types'
import Map0Img from '../../../assets/images/game/map/0.jpg'
import Map1Img from '../../../assets/images/game/map/1.jpg'
import Map2Img from '../../../assets/images/game/map/2.jpg'
import Map3Img from '../../../assets/images/game/map/3.jpg'
import Map4Img from '../../../assets/images/game/map/4.jpg'
import Map5Img from '../../../assets/images/game/map/5.jpg'
import Map6Img from '../../../assets/images/game/map/6.jpg'
import Map7Img from '../../../assets/images/game/map/7.jpg'
import Map8Img from '../../../assets/images/game/map/8.jpg'
import Map9Img from '../../../assets/images/game/map/9.jpg'
import Map10Img from '../../../assets/images/game/map/10.jpg'
import Map11Img from '../../../assets/images/game/map/11.jpg'
import Map12Img from '../../../assets/images/game/map/12.jpg'

import Plot0Img from '../../../assets/images/game/plot/0.png'
import Plot1Img from '../../../assets/images/game/plot/1.png'
import Plot2Img from '../../../assets/images/game/plot/2.png'
import Plot3Img from '../../../assets/images/game/plot/3.png'
import Plot4Img from '../../../assets/images/game/plot/4.png'
import Plot5Img from '../../../assets/images/game/plot/5.png'
import Plot6Img from '../../../assets/images/game/plot/6.png'
import Plot7Img from '../../../assets/images/game/plot/7.png'
import Plot8Img from '../../../assets/images/game/plot/8.png'
import Plot9Img from '../../../assets/images/game/plot/9.png'
import Plot10Img from '../../../assets/images/game/plot/10.png'
import Plot11Img from '../../../assets/images/game/plot/11.png'
import Plot12Img from '../../../assets/images/game/plot/12.png'

export const NUM_OF_LEVELS_PER_MAP = 1

export const MAP_STYLE = {
  position: 'relative',
  margin: '5px',
} as React.CSSProperties

export const MAP_IMG_STYLE = {
  height: '400px',
} as React.CSSProperties

export const MAPS_DATA = [
  {
    mapSrc: Map0Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map1Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map2Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map3Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map4Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map5Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map6Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map7Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map8Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map9Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map10Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map11Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
  {
    mapSrc: Map12Img,
    levelPositions: [{ top: 250, left: 175 }],
    levels: [],
  },
]

export const PLOT_DATA: PlotData[] = [
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot0Img,
    title: 'Port w Barcelonie',
    content:
      'Łódź dobija do portu w Barcelonie. Po wielu dniach podróży pod wodą dobrze w końcu odetchnąć czystym powietrzem. <strong>Wychodzicie na ląd, by rozprostować nogi i zasięgnąć kąpieli w hiszpańskim słońcu.</strong> Kierujecie się w stronę Barcelonety, najsłynniejszej plaży w mieście. Początkowo było to miejsce zamieszkane przez rodziny rybaków, które pokoleniami zajmowały się połowem i sprzedażą ryb oraz owoców morza. Dziś długa na 1100 metrów plaża Barceloneta przyciąga turystów głodnych leniwego odpoczynku i kąpieli w Morzu Śródziemnym.<br><br>Z drinkiem w dłoni rozsiadacie się na piasku, podziwiając łagodne ruchy fal. Tak długo znajdowaliście się pod wodą, że oglądanie morza z lądu jest szczególnie przyjemne. Po krótkim odpoczynku pora rozpocząć zwiedzanie miasta.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot1Img,
    title: 'Mirador de Colom',
    content:
      'Idąc wzdłuż nabrzeża trafiacie do miejsca, gdzie Ronda Litoral przecina ruchliwą La Rambla. <strong>Nad ulicami góruje tu Mirador de Colom, czyli pomnik Krzysztofa Kolumba.</strong> Choć słynny podróżnik urodził się jako Włoch, to właśnie dwór hiszpański finansował jego wyprawy. Barcelońska podobizna odkrywcy powstała w 1888 roku z okazji Wystawy Światowej. Pomnik przedstawia Kolumba stojącego na 40-metrowej kolumnie, prawą ręką wskazując w stronę Nowego Świata. Na cokole znajdują się jeszcze podobizny kilkunastu osób związanych z Kolumbem, w tym królowej Izabeli Kastylijskiej, rodziny podróżnika czy rdzennych Amerykanów.<br><br>Wokół kolumny szukacie czerwonego znaku, który ma wskazywać wejście na punkt widokowy. Po chwili zauważacie schody do podziemi, gdzie znajduje się kasa biletowa, a za nią malutka winda. Z góry rozpościera się widok na całą Barcelonę. Posiłkując się mapą, znajdujesz wzrokiem ulicę La Rambla, gotycką Katedrę oraz Wzgórze Montjuic.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot2Img,
    title: 'La Rambla',
    content:
      'Opuszczacie punkt widokowy i udajecie się na północ, w kierunku La Rambla. <strong>To najważniejsza turystyczna ulica w Barcelonie.</strong> Dawniej stanowiła ona drogę handlową łączącą port z centrum. Od czasów renesansu jest przede wszystkim miejscem, gdzie można się dobrze zabawić, zjeść oraz zaopatrzyć w pamiątki. Jeśli istnieje ulica, która kumuluje w sobie całą atmosferę hiszpańskiego miasta, jest nią właśnie La Rambla.<br><br>Spacerujecie przez tętniący życiem deptak otoczony rzędami zielonych drzew. Ponad straganami widać historyczne fronty zadbanych kamienic z XIX wieku, które aż proszą się o sfotografowanie.<br><br>Wstępujecie do jednego z wielu tapas barów, gdzie możecie skosztować charakterystycznych dla tego rejonu przekąsek: smażonych kalmarów, croquetas i patatas bravas, czyli barcelońską odpowiedź na klasyczne frytki.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot3Img,
    title: 'Barrio Gótico',
    content:
      'Barcelona urzeka unikalną architekturą od pierwszego spojrzenia, ale przed Wami jest jeszcze wiele do odkrycia. <strong>Dzielnica Gotycka to wizualna podróż w przeszłość – wąskie uliczki, średniowieczna zabudowa i setki sklepików stwarzają atmosferę nie do podrobienia.</strong><br><br>Rozpoczynacie zwiedzanie od Placa del Pi, na którym to mieści się kościół Santa Maria del Pi z charakterystyczną gotycką rozetą. Wkraczając na uliczkę Carrer de Petritxol obserwujecie, jak współczesna sztuka w postaci street-artu łączy się z zabytkowymi kapliczkami i malowanymi kafelkami.<br><br> Nieopodal, w ulubionej kawiarni Salvadora Dalí, Dulcinea, kupujecie churros i gorącą czekoladę. Jak za dawnych czasów, także dzisiaj ludzie spotykają się na schodach kościoła oraz placach, kontynuując katalońskie tradycje, np. castells czyli wznoszenie wież z ludzi.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot4Img,
    title: 'Katedra',
    content:
      'Zwieńczeniem spaceru po Dzielnicy Gotyckiej jest dotarcie do Katedry św. Eulalii, zwanej przez miejscowych po prostu… Katedrą. <strong>Budowla znajduje się w samym sercu Barrio Gótico i według znawców jest najwspanialszym przykładem architektury gotyku w całej Hiszpanii.</strong> Jej budowa trwała od XIII do XV wieku, a część fasady dodano jeszcze w XIX wieku.<br><br>Nie tylko sam wygląd Katedry z imponującymi sklepieniami i rzeźbami robi wrażenie. W środku znajdują się takie skarby, jak chrzcielnica z czasów wypraw Kolumba czy krzyż pamiętający bitwę pod Lepanto.<br><br>Z pięknego, ale dusznego kościoła wychodzicie do otaczającego go ogrodu z tropikalnymi drzewami oraz sadzawką, w której na pamiątkę św. Eulalii zawsze pływa… 13 gęsi.<br><br>Wędrówka ulicami Barcelony była owocna. Na dziś wystarczy jednak zwiedzania – udajecie się na odpoczynek do hotelu.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot5Img,
    title: 'Plac Kataloński',
    content:
      'Drugiego dnia z nowymi siłami kontynuujecie spacer po Barcelonie. <strong>Udajecie się na Plac Kataloński, będący swoistym centrum miasta</strong>, chociaż nie przypomina on znajomych polskich starówek. Jest to bardziej węzeł przesiadkowy, bo właśnie stąd odjeżdża metro, autobusy czy autokary turystyczne. Z rana na pustej przestrzeni roi się od gołębi, które odlatują, gdy zbliża się Wasza grupa. Robicie zdjęcia fontanny oraz rzeźb otaczających Plac, datowanych na początek XX wieku.<br>Nie przyszliście tu jednak wyłącznie dla architektury i sztuki. W poszukiwaniu miejsca, gdzie można zjeść śniadanie, natrafiacie na knajpkę, gdzie podają pa amb tomàquet. To klasyczna śniadaniowa potrawa katalońska, która składa się z tostowanego białego chleba posmarowanego startymi pomidorami i skropionego oliwą.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot6Img,
    title: 'Casa Milà i Casa Batlló',
    content:
      '<strong>Kontynuując podróż po architektonicznych perełkach Barcelony, docieracie do słynnej kamienicy Casa Milà, która jest nazywana również La Pedrera (hiszp. „kamieniołom”).</strong> Budynek powstał z inicjatywy Pere Milà, lokalnego przedsiębiorcy, który na twórcę swojego pomysłu wybrał samego Antoniego Gaudiego. Artysta miał wówczas ręce pełne pracy przy swoich licznych projektach, w tym niedokończonej Sagradzie Familii.<br><br>La Pedrera robi na Was niesamowite wrażenie. <strong>Bryła budynku, który miał nawiązywać do morskich skał, fal i wodorostów wydaje się wręcz falować.</strong> Jeśli wierzyć znawcom architektury Gaudiego, w kamienicy nie ma żadnego kąta ostrego.<br><br>Antonio Gaudi rozpoczął prace nad Casa Milà na krótko po ukończeniu swojego innego projektu. To właśnie on jest Waszym kolejnym przystankiem. <strong>Casa Batlló nazywany jest Domem Smoka lub Domem Kości. Motywy umieszczone na fasadzie inspirowane były Legendą o św. Jerzym walczącym ze smokiem.</strong> Nawet dachówka przypomina tu smoczą łuskę!',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot7Img,
    title: 'Muzeum Picassa',
    content:
      'Hiszpania jest ojczyzną wielu artystów o światowej sławie. <strong>Bez wątpienia najsłynniejszym z nich jest Pablo Picasso. Udajecie się do Muzeum poświęconego jego twórczości</strong>, które mieści się przy Carrer Montcada 15-23.<br><br>Picasso żył w latach 1881-1973 i zasłynął szerzej jako twórca kubizmu. <strong>Chociaż urodził się w Maladze, właśnie w Barcelonie przez wiele lat mieszkał i malował.</strong> To podczas pobytu w tym mieście rozwinął się jeden z najowocniejszych okresów twórczości artysty, zwany „okresem niebieskim”.<br><br>W Muzeum, które powstało z inicjatywy przyjaciela Picassa, Jaume Sabartésa, znajduje się aż 4251 dzieł malarskich, szkiców, oraz rzeźb, w tym takie tytuły, jak „Pierwsza Komunia” czy „Panny Dworskie” inspirowane dziełem Velazqueza.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot8Img,
    title: 'Sagrada Familia',
    content:
      'Ponownie powracacie do Gaudiego. W rzeczywistości spacerując uliczkami Barcelony, nie można zapomnieć o twórczości tego artysty. <strong>Jego najsłynniejsze dzieło, Sagrada Familia, jest Waszym kolejnym celem. Kościół oficjalnie nazywa się Temple Expiatori de la Sagrada Familia i jest prawdziwym ewenementem na światową skalę.</strong> Jego budowa rozpoczęła się w 1882 roku i… trwa do dziś. Po śmierci Gaudiego w wypadku tramwajowym budowniczy kontynuują prace zgodnie z planami artysty.<br><br>Cały kościół ma powierzchnię 4,5 tys. m2 i może pomieścić do 12 tys. wiernych. Finalnie budowla powinna mieć wymiary 60x95 metrów z górującymi nad nią 18 wieżami. Od 2005 roku Sagrada Familia jest wpisana na listę UNESCO.<br><br>Zarówno fasada, jak i wnętrze urzekają swoją konstrukcją i ornamentyką. Niemal każdy szczegół ma symboliczne znaczenie – zgodnie ze swoją ideą Gaudi zawarł tu odniesienia do królestwa zwierząt, roślin, minerałów i ludzi.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot9Img,
    title: 'Park Güell',
    content:
      'Historia Parku Güell rozpoczyna się podobnie, jak wiele projektów Gaudiego. Bogaty przedsiębiorca-wizjoner chce wyróżnić fragment Barcelony oryginalną zabudową. W tym przypadku inicjatorem był zamożny Eusebi Güell, którego zafascynowały angielskie miasta-ogrody. Projekt zrealizował jego przyjaciel, Antonio Gaudi. Niestety osiedle nie spotkało się z zainteresowaniem inwestorów. Dlaczego? Być może chodziło o zawiłe formy dzierżawy, a może lokalizację. Mimo to Park Güell zyskał wartość turystyczną.<br><br><strong>Budowle przypominające domki z piernika, inspirowana grecką sztuką Sala Kolumnowa, czy słynna salamandra z tłuczonej ceramiki to zaledwie część tego oryginalnego projektu.</strong> Odpoczywacie, siadając na najdłuższej ławce na świecie, która wije się jak wąż przez całe 110 metrów, okalając cały plac.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot10Img,
    title: 'Camp Nou',
    content:
      'Nie samą kulturą żyje człowiek. Po wielogodzinnym zwiedzaniu przyda się Wam odrobina sportowej rozrywki. <strong>Barcelona słynie nie tylko z atrakcyjnej architektury, ale i jednej z najlepszych drużyn piłkarskich. Jeśli mowa o FC Barcelona, nie można zapomnieć o Camp Nou.</strong><br><br>Dołączacie do wycieczki na największy stadion na świecie, który może pomieścić aż 98 772 osób. Przewodnik raczy Was ciekawostkami o budowie tego obiektu. Został on ukończony w 1957 roku, a pierwszym meczem rozegranym na nowym stadionie było spotkanie FC Barcelona vs. Legia Warszawa.<br><br>Zwiedzanie stadionu rozpoczynacie od muzeum, w którym głównymi eksponatami są liczne puchary zdobyte przez Blaugranę, buty, koszulki i zdjęcia najważniejszych momentów w historii. Następnie przechodzicie przez salę konferencyjną i korytarz prowadzący do wyjścia na murawę. A wszystko to przy akompaniamencie śpiewów i okrzyków kibiców wydobywających się z głośników. Czy to z trybun, boiska czy gniazda reporterskiego – Camp Nou imponuje swoją wielkością. Podczas wieczornego meczu możecie poczuć wyjątkową atmosferę stadionu i na żywo zobaczyć światowej klasy zawodników...',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot11Img,
    title: 'Wzgórze Montjuïc',
    content:
      'Ostatniego dnia w Barcelonie decydujecie się rzucić okiem na miasto z innej perspektywy. <strong>Najlepszym punktem widokowym jest wzgórze Montjuïc o wysokości 173 m n.p.m., do którego prowadzi kolejka liniowa.</strong> Na szczycie znajduje się zamek z XVII wieku, Castell de Montjuïc, który przez lata funkcjonował jako fortyfikacja, więzienie, a następnie baza wojskowa. Dziś mieści się w nim muzeum. Wy kierujecie się jednak do Wioski Hiszpańskiej, która jest formą skansenu z charakterystycznymi dla Hiszpanii budynkami.<br><br>Spacerując po wzgórzu, wstępujecie do ogrodu botanicznego, a następnie zatrzymujecie się w kawiarni na Plaça de l’Armada. Tu z tarasu knajpki roztacza się widok na całą Barcelonę. Widzicie port, od którego rozpoczęła się Wasza podróż, gotycką Katedrę oraz wieże Sagrady Familii.<br><br><strong>Na tym kończy się Wasz spacer po Barcelonie.</strong>',
  },
  {
    style: {
      position: 'absolute',
      bottom: '50px',
      left: 'calc(50% - 80px)',
    },
    image: Plot12Img,
    title: 'Misja finałowa',
    content:
      'To była wyjątkowa podróż. Udało Ci się odwiedzić najważniejsze atrakcje Barcelony.<br><br>Gratulacje - posiadasz wystarczającą ilość punktów aby przystąpić do misji finałowej i zdobyć nagrodę główną.<br><br>Kliknij czerwony przycisk i rozwiąż zagadkę.',
  },
]
