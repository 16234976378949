import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  Grid,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { errorHandler } from '../../../../../helpers/errorHandler'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../../store/types'
import { RegionAddErrors } from '../../../../../store/Region/types'
import RegionService from '../../../../../services/region.service'
import { toast } from 'react-toastify'

type RegionAddDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
}

const RegionAddDialog: React.FunctionComponent<RegionAddDialogProps> = ({
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const [saving, setSaving] = useState<boolean>(false)

  //new
  const [name, setName] = useState<string>('')
  const [centralId, setCentralId] = useState<string | null>('')

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: RegionAddErrors = {
    name: defaultError,
    centralId: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<RegionAddErrors>(defaultFormErrors)

  const handleCentralIdChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCentralId(event.target.value)
  }
  const handleNameChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setName(event.target.value)
  }

  const resetForm = () => {
    setFormErrors(defaultFormErrors)
    setName('')
    setCentralId('')
  }

  const onSave = async () => {
    setFormErrors(defaultFormErrors)
    const formErrors = defaultFormErrors

    if (name.trim() === '') {
      setFormErrors({
        ...formErrors,
        name: {
          error: true,
          message: t('pages.regions.regionAddDialog.errors.required'),
        },
      })
      return
    }

    try {
      setSaving(true)
      const addRegionResponse = await RegionService.addRegion(
        name,
        centralId ? centralId : null,
      )

      if (addRegionResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        resetForm()
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.regions.regionAddDialog.title')}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                <label>{t('pages.regions.regionAddDialog.name')} *</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                  error={formErrors.name.error}
                  helperText={formErrors.name.error && formErrors.name.message}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth margin="dense">
                <label>{t('pages.regions.regionAddDialog.centralId')}</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={centralId}
                  onChange={handleCentralIdChange}
                  error={formErrors.centralId.error}
                  helperText={
                    formErrors.centralId.error && formErrors.centralId.message
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              resetForm()
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSave} disabled={saving}>
            {t('common.save')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default RegionAddDialog
