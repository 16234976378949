import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  TextField,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../store/types'
import StoreDiscountsService from '../../../../services/storeDiscounts.service'
import { toast } from 'react-toastify'
import {
  StoresDiscountsEditErrors,
  StoresDiscountsEditParams,
} from '../../../../store/StoreDiscount/types'
import moment from 'moment'
import DateTimePicker from '../../../shared/DateTimePicker'

type StoresDiscountsEditDialogProps = {
  discountId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const StoresDiscountsEditDialog: React.FunctionComponent<
  StoresDiscountsEditDialogProps
> = ({ discountId, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)

  const [storeCentralId, setStoreCentralId] = useState<string>('')
  const [storeName, setStoreName] = useState<string>('')

  const [discountCentralId, setDiscountCentralId] = useState<string>('')
  const [discountName, setDiscountName] = useState<string>('')

  const [discountValue, setDiscountValue] = useState<string>()

  const [validToDate, setValidToDate] = useState<Date | null>(null)
  const [validToTime, setValidToTime] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [unlimitedDiscountTime, setUnlimitedDiscountTime] =
    useState<boolean>(false)

  const [isActive, setIsActive] = useState<any>(true)

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: StoresDiscountsEditErrors = {
    discountValue: defaultError,
    validToDate: defaultError,
    validToTime: defaultError,
    isActive: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<StoresDiscountsEditErrors>(defaultFormErrors)

  const handleIsActiveChange = (event: SelectChangeEvent) => {
    setIsActive(event.target.value as string)
  }

  const handleDiscountValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDiscountValue(event.target.value.replace(',', '.'))
  }

  const handleUnlimitedDiscountTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUnlimitedDiscountTime(event.target.checked)
    if (event.target.checked) {
      setFormErrors({
        ...formErrors,
        validToDate: {
          error: false,
          message: '',
        },
      })
      setValidToDate(null)
      setValidToTime(null)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const storesDiscountsDetailsResponse =
          await StoreDiscountsService.getStoresDiscountDetails(discountId)

        if (storesDiscountsDetailsResponse.data) {
          setStoreCentralId(storesDiscountsDetailsResponse.data.storeCentralId)
          setStoreName(storesDiscountsDetailsResponse.data.storeName)
          setDiscountCentralId(
            storesDiscountsDetailsResponse.data.discountCentralId,
          )
          setDiscountName(storesDiscountsDetailsResponse.data.discountName)
          setDiscountValue(
            storesDiscountsDetailsResponse.data.discountValue.toString(),
          )
          setValidToDate(
            storesDiscountsDetailsResponse.data.validTo
              ? new Date(
                  storesDiscountsDetailsResponse.data.validTo.slice(0, 10),
                )
              : null,
          )
          setValidToTime(
            storesDiscountsDetailsResponse.data.validTo
              ? new Date(
                  new Date().setHours(
                    parseInt(
                      storesDiscountsDetailsResponse.data.validTo.slice(11, 13),
                    ),
                    parseInt(
                      storesDiscountsDetailsResponse.data.validTo.slice(14, 16),
                    ),
                  ),
                )
              : null,
          )
          setUnlimitedDiscountTime(
            storesDiscountsDetailsResponse.data.validTo ? false : true,
          )
          setIsActive(storesDiscountsDetailsResponse.data.isActive)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, discountId])

  const onSave = async () => {
    setFormErrors(defaultFormErrors)

    if (!discountValue) {
      setFormErrors({
        ...formErrors,
        discountValue: {
          error: true,
          message: t(
            'pages.storesDiscounts.storesDiscountsEditDialog.errors.discountValueRequired',
          ),
        },
      })
      return
    }
    if (parseFloat(discountValue) <= 0) {
      setFormErrors({
        ...formErrors,
        discountValue: {
          error: true,
          message: t(
            'pages.storesDiscounts.storesDiscountsEditDialog.errors.invalidDiscountValueMin',
          ),
        },
      })
      return
    } else if (parseFloat(discountValue) > 100) {
      setFormErrors({
        ...formErrors,
        discountValue: {
          error: true,
          message: t(
            'pages.storesDiscounts.storesDiscountsEditDialog.errors.invalidDiscountValueMax',
          ),
        },
      })
      return
    } else if (
      !unlimitedDiscountTime &&
      validToDate &&
      validToTime &&
      (validToDate.toString() === 'Invalid Date' ||
        validToTime.toString() === 'Invalid Date')
    ) {
      setFormErrors({
        ...formErrors,
        validToDate: {
          error: true,
          message: t(
            'pages.storesDiscounts.storesDiscountsEditDialog.errors.invalidDate',
          ),
        },
      })
      return
    } else if (!unlimitedDiscountTime && validToDate && !validToTime) {
      setFormErrors({
        ...formErrors,
        validToDate: {
          error: true,
          message: t(
            'pages.storesDiscounts.storesDiscountsEditDialog.errors.invalidDate',
          ),
        },
      })
      return
    } else if (!unlimitedDiscountTime && !validToDate && !validToTime) {
      setFormErrors({
        ...formErrors,
        validToDate: {
          error: true,
          message: t(
            'pages.storesDiscounts.storesDiscountsEditDialog.errors.invalidDate',
          ),
        },
      })
      return
    } else if (
      !unlimitedDiscountTime &&
      moment(validToDate).isBefore(new Date(), 'year')
    ) {
      setFormErrors({
        ...formErrors,
        validToDate: {
          error: true,
          message: t(
            'pages.storesDiscounts.storesDiscountsEditDialog.errors.invalidDate',
          ),
        },
      })
      return
    }
    const validTo = validToDate
      ? moment(validToDate).format('YYYY-MM-DD') +
        ' ' +
        moment(validToTime).format('HH:mm:ss')
      : null

    const formParams: StoresDiscountsEditParams = {
      discountId,
      discountValue: parseFloat(discountValue),
      validTo: unlimitedDiscountTime ? null : validTo,
      isActive: isActive === true || isActive === 't' ? true : false,
    }

    try {
      setSaving(true)
      const editStoresDiscountsResponse =
        await StoreDiscountsService.storesDiscountsEdit(formParams)

      if (editStoresDiscountsResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.storesDiscounts.storesDiscountsEditDialog.title', {
            userTypeName: t(`userTypeCodes`),
          })}
        </Typography>
      </DialogTitle>
      {loading && <LoadingSpinner />}
      {!loading && (
        <DialogContent>
          <DialogContentText>
            <Grid container columnSpacing={2}>
              <Grid item md={12}>
                <span style={{ fontWeight: 'bold' }}>
                  {t(
                    'pages.storesDiscounts.storesDiscountsEditDialog.storeCentralId',
                  )}
                  :{' '}
                </span>
                <span>{storeCentralId}</span>
                <span style={{ fontWeight: 'bold', marginLeft: 30 }}>
                  {t(
                    'pages.storesDiscounts.storesDiscountsEditDialog.storeName',
                  )}
                  :{' '}
                </span>
                <span>{storeName}</span>
              </Grid>
              <Grid item md={12} mt={1}>
                <span style={{ fontWeight: 'bold' }}>
                  {t(
                    'pages.storesDiscounts.storesDiscountsEditDialog.discountCentralId',
                  )}
                  :{' '}
                </span>
                <span>{discountCentralId}</span>
                <span style={{ fontWeight: 'bold', marginLeft: 30 }}>
                  {t(
                    'pages.storesDiscounts.storesDiscountsEditDialog.discountName',
                  )}
                  :{' '}
                </span>
                <span>{discountName}</span>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t(
                      'pages.storesDiscounts.storesDiscountsEditDialog.discountValue',
                    )}{' '}
                    *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={discountValue}
                    onChange={handleDiscountValueChange}
                    error={formErrors.discountValue.error}
                    helperText={
                      formErrors.discountValue.error &&
                      formErrors.discountValue.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={3}>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={formErrors.isActive.error}
                >
                  <label>
                    {t(
                      'pages.storesDiscounts.storesDiscountsEditDialog.isActive',
                    )}
                  </label>
                  <Select
                    value={isActive ? 't' : 'f'}
                    onChange={handleIsActiveChange}
                    size="small"
                  >
                    <MenuItem value="t">{t('common.yes')}</MenuItem>
                    <MenuItem value="f">{t('common.no')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={7}>
                <DateTimePicker
                  label={t(
                    'pages.storesDiscounts.storesDiscountsEditDialog.validTo',
                  )}
                  date={validToDate}
                  time={validToTime}
                  onDateChange={setValidToDate}
                  onTimeChange={setValidToTime}
                  minDate={new Date()}
                  error={formErrors.validToDate}
                  disabled={unlimitedDiscountTime}
                  style={{ opacity: unlimitedDiscountTime ? '0.5' : '1' }}
                />
              </Grid>
              <Grid item xs={5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={unlimitedDiscountTime}
                      onChange={handleUnlimitedDiscountTimeChange}
                      size="small"
                      sx={{ py: 0 }}
                    />
                  }
                  style={{ marginTop: '30px' }}
                  label={t(
                    'pages.storesDiscounts.storesDiscountsEditDialog.unlimitedDiscount',
                  )}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSave} disabled={saving || loading}>
            {t('common.save')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default StoresDiscountsEditDialog
