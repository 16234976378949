import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import CompanyService from '../../../../services/company.service'
import UserService from '../../../../services/user.service'
import LoyaltyUserService from '../../../../services/loyaltyUser.service'
import { useTranslation } from 'react-i18next'
import LoyaltyUsersToolbar from '../partials/LoyaltyUsersToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { User } from '../../../../store/Auth/types'
import {
  LoyaltyUser,
  LoyaltyUserTypeCode,
} from '../../../../store/LoyaltyUser/types'
import {
  CellProps,
  Column,
  ColumnInstance,
  HeaderProps,
  Row,
} from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import { pick } from 'lodash'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
} from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import LoyaltyUserAddDialog from '../partials/LoyaltyUserAddDialog'
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import LoyaltyUserEditDialog from '../partials/LoyaltyUserEditDialog'
import LoyaltyUserDetailsDialog from '../partials/LoyaltyUserDetailsDialog'
import IndeterminateCheckbox from '../../../Table/IndeterminateCheckbox'
// import LockIcon from '@mui/icons-material/Lock'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  isCentralAdmin,
  isCompanyAdmin,
  isSuperAdmin,
} from '../../../../helpers/checkRole'
import LoyaltyUserDeactivationDialog from '../partials/LoyaltyUserDeactivationDialog'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { UserFilter } from '../../../../store/User/types'
import LoyaltyUserVisibilityDialog from '../partials/LoyaltyUserVisibilityDialog'
import LoyaltyUserChangePointsDialog from '../partials/LoyaltyUserChangePointsDialog'
import {
  isLpLoyaltyPointsVisible,
  isVpLoyaltyPointsVisible,
  isXpLoyaltyPointsVisible,
} from '../../../../helpers/environment'

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import ImportDialog from '../../../shared/ImportDialog'
import { LoyaltyPeriod } from '../../../../store/LoyaltyPeriod/types'
import { AccountOperation } from '../../../../store/AccountOperation/types'
import AccountOperationService from '../../../../services/accountOperation.service'
import LoyaltyPeriodService from '../../../../services/loyaltyPeriod.service'

type LoyaltyUsersListProps = {
  user: User
  type: LoyaltyUserTypeCode
}

const LoyaltyUsersList: FunctionComponent<LoyaltyUsersListProps> = ({
  user,
  type,
}) => {
  const tableName = `loyaltyUsers${type.toLowerCase()}`

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredUsersList, setFilteredUsersList] = useState<LoyaltyUser[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')
  const [statusValue, setStatusValue] = useState<string>('all')

  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])

  const [users, setUsers] = useState<UserFilter[]>([])
  const [filteredUsers, setFilteredUsers] = useState<Option[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([])

  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])

  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const fetchIdRef = useRef(0)

  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [selectedRows, setSelectedRows] = useState<Row<object>[]>([])
  const [selectedUserIds, setSelectedUserIds] = useState<
    Pick<LoyaltyUser, 'participantId'>[]
  >([])

  const [openImportDialog, setImportDialogOpen] = useState(false)
  const [periodValue, setPeriodValue] = useState<string>('')
  const [periods, setPeriods] = useState<LoyaltyPeriod[]>([])

  const [operationTypes, setOperationTypes] = useState<AccountOperation[]>([])
  const [operationTypesValue, setOperationTypesValue] = useState<string>('')

  const [openUserAddDialog, setUserAddDialogOpen] = useState(false)
  const [openUserEditDialog, setUserEditDialogOpen] = useState(false)
  const [openUserDetailsDialog, setUserDetailsDialogOpen] = useState(false)
  const [openUserChangePointsDialog, setUserChangePointsDialogOpen] =
    useState(false)
  const [openUserDeactivationDialog, setUserDeactivationDialogOpen] =
    useState(false)
  const [openUserHideDialog, setUserHideDialogOpen] = useState(false)
  const [userId, setUserId] = useState<number | null>(null)
  const [refresh, setRefresh] = useState(false)

  const [importName, setImportName] = useState<string | null>(null)
  const [importTitle, setImportTitle] = useState<string>()
  const [importParamPeriod, setImportParamPeriod] = useState<number>()
  const [importParamOperationType, setImportParamOperationType] =
    useState<number>()

  const generateTableColumns = useCallback(
    (users: LoyaltyUser[]) => {
      const columns = []
      columns.push(
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          width: 40,
          Cell: ({ row }: CellProps<any>) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          sticky: 'left',
        },
        {
          Header: t('pages.loyaltyUsersprt.table.participantId').toString(),
          accessor: 'participantId',
          width: 100,
          Cell: (params: any) => params.value,
        },
        {
          Header: t(
            'pages.loyaltyUsersprt.table.participantCentralId',
          ).toString(),
          accessor: 'participantCentralId',
          width: 160,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'firstname',
          Header: t('pages.loyaltyUsersprt.table.firstname').toString(),
          width: 130,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'lastname',
          Header: t('pages.loyaltyUsersprt.table.lastname').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'email',
          Header: t('pages.loyaltyUsersprt.table.email').toString(),
          width: 240,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'mobile',
          Header: t('pages.loyaltyUsersprt.table.mobile').toString(),
          width: 180,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeCentralId',
          Header: t('pages.loyaltyUsersprt.table.storeCentralId').toString(),
          width: 125,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeName',
          Header: t('pages.loyaltyUsersprt.table.storeName').toString(),
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'companyName',
          Header: t('pages.loyaltyUsersprt.table.companyName').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userUsername',
          Header: t('pages.loyaltyUsersprt.table.userUsername').toString(),
          width: 200,
          Cell: (params: any) => params.value,
        },

        {
          accessor: 'lastLoginDate',
          Header: t('pages.loyaltyUsersprt.table.lastLoginDate').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'isVisible',
          Header: t('pages.loyaltyUsersprt.table.isVisible').toString(),
          width: 110,
          Cell: (params: any) =>
            params.value ? <>{t('common.yes')}</> : <>{t('common.no')}</>,
        },
        {
          accessor: 'isActive',
          Header: t('pages.loyaltyUsersprt.table.isActive').toString(),
          width: 110,
          Cell: (params: any) =>
            params.value ? <>{t('common.yes')}</> : <>{t('common.no')}</>,
        },
        {
          accessor: 'edit',
          Header: t('pages.loyaltyUsersprt.table.actions').toString(),
          width: 100,
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <>
              {type === LoyaltyUserTypeCode.PRT && (
                <Grid container>
                  <Grid
                    item
                    sx={{
                      width: '35px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip title={`${t('pages.loyaltyUsersprt.table.edit')}`}>
                      <IconButton
                        onClick={() =>
                          handleUserEditDialogClickOpen(
                            params.row.original.participantId,
                          )
                        }
                        size="small"
                        style={{ padding: 0 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: '35px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip
                      title={`${t('pages.loyaltyUsersprt.table.preview')}`}
                    >
                      <IconButton
                        onClick={() =>
                          handleUserDetailsDialogClickOpen(
                            params.row.original.participantId,
                          )
                        }
                        size="small"
                        style={{ padding: 0, marginRight: '5px' }}
                      >
                        <PreviewIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </>
          ),
        },
      )
      return columns
    },
    [t, type],
  )

  const handleUserAddDialogClickOpen = () => {
    setUserAddDialogOpen(true)
  }

  const handleUserAddDialogClose = (refreshTable: boolean = false) => {
    setUserAddDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserEditDialogClickOpen = (userId: number) => {
    setUserId(userId)
    setUserEditDialogOpen(true)
  }

  const handleUserEditDialogClose = (refreshTable: boolean = false) => {
    setUserEditDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserDetailsDialogClickOpen = (participantId: number) => {
    setUserId(participantId)
    setUserDetailsDialogOpen(true)
  }

  const handleUserDetailsDialogClose = (refreshTable: boolean = false) => {
    setUserDetailsDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserChangePointsDialogClickOpen = (selectedRows: Row<any>[]) => {
    setSelectedUserIds(
      selectedRows.map((row) => ({
        participantId: row.original.participantId,
      })),
    )
    setUserChangePointsDialogOpen(true)
  }

  const handleUserChangePointsDialogClose = (refreshTable: boolean = false) => {
    setUserChangePointsDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserDeactivationDialogClickOpen = (selectedRows: Row<any>[]) => {
    setSelectedUserIds(
      selectedRows.map((row) => ({
        participantId: row.original.participantId,
      })),
    )
    setUserDeactivationDialogOpen(true)
  }

  const handleUserDeactivationDialogClose = (refreshTable: boolean = false) => {
    setUserDeactivationDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserHideDialogClickOpen = (selectedRows: Row<any>[]) => {
    setSelectedUserIds(
      selectedRows.map((row) => ({
        participantId: row.original.participantId,
      })),
    )
    setUserHideDialogOpen(true)
  }

  const handleUserHideDialogClose = (refreshTable: boolean = false) => {
    setUserHideDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleImportDialogClose = () => {
    setImportDialogOpen(false)
  }

  const handleImportDialogClickOpen = (
    userType: LoyaltyUserTypeCode,
    periodId?: string,
    operationType?: string,
  ) => {
    setImportTitle(
      t('pages.loyaltyUsers.import.pointsImportTitle', {
        periodName: periods.find((p) => p.id === parseInt(periodId!))?.name,
      }),
    )
    setImportParamPeriod(parseInt(periodId!))
    setImportParamOperationType(parseInt(operationType!))
    if (userType === LoyaltyUserTypeCode.PRT) {
      setImportName('participant_points_operations')
    }
    setImportDialogOpen(true)
  }

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          const selectedCompaniesState = getTableState(
            tableName,
            'selectedCompaniesState',
          )
          if (selectedCompaniesState) {
            setSelectedCompanies(JSON.parse(selectedCompaniesState))
          } else {
            setSelectedCompanies(multiSelectOptions)
          }
        }

        const userListResponse = await UserService.getUserListFilter()

        if (userListResponse.data.users) {
          const multiSelectOptions: Option[] = []
          userListResponse.data.users.forEach((user) =>
            multiSelectOptions.push({
              value: user.id,
              label: `${user.lastname} ${user.firstname}`,
            }),
          )
          setUsers(userListResponse.data.users)
          const selectedUsersState = getTableState(
            tableName,
            'selectedUsersState',
          )
          if (selectedUsersState) {
            setSelectedUsers(JSON.parse(selectedUsersState))
          } else {
            setSelectedUsers(multiSelectOptions)
          }

          const filteredUsersState = getTableState(
            tableName,
            'filteredUsersState',
          )
          if (filteredUsersState) {
            setFilteredUsers(JSON.parse(filteredUsersState))
          } else {
            setFilteredUsers(multiSelectOptions)
          }
        }
        const searchState = getTableState(tableName, 'search')
        const statusState = getTableState(tableName, 'status')

        if (searchState) {
          setSearchText(searchState)
          setSearchValue(searchState)
        } else {
          setSearchText('')
          setSearchValue('')
        }

        if (statusState) {
          setStatusValue(statusState)
        } else {
          setStatusValue('all')
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
    const fetchPeriodsData = async () => {
      try {
        const periodListResponse = await LoyaltyPeriodService.getPeriodList()

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods.reverse())
          setPeriodValue(
            periodListResponse.data.periods
              .find((period) => period.isActivated)
              ?.id.toString() || '',
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchPeriodsData()

    const fetchOperationTypesData = async () => {
      try {
        const operationTypesListResponse =
          await AccountOperationService.getLoyaltyAccountOperationList()

        if (operationTypesListResponse.data.operationTypes) {
          setOperationTypes(operationTypesListResponse.data.operationTypes)

          setOperationTypesValue(
            operationTypesListResponse.data.operationTypes
              .find((operation) => operation.id === 1)
              ?.id.toString() || '',
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (!isCompanyAdmin(user)) {
      fetchOperationTypesData()
    }
  }, [t, tableName, type, user])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      const checkSortColumn = (sortColumn: string) => {
        if (
          ['totalXpValue', 'currentXpValue', 'subtractedXpValue'].includes(
            sortColumn,
          ) &&
          ['usersse', 'usersadm'].includes(tableName)
        ) {
          return ''
        }

        return sortColumn
      }

      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        if (selectedCompanies.length) {
          setTableLoading(true)
          try {
            let sortColumn = ''
            let sortDirection = ''
            if (sortBy.length) {
              sortColumn = sortBy[0].id
              sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
            }

            setDownloadSortBy(sortColumn)
            setDownloadSortOrder(sortDirection)

            const page = ++pageIndex
            const usersResponse = await LoyaltyUserService.getLoyaltyUsers(
              selectedCompanies.map((company) => {
                return {
                  id: company.value,
                }
              }),
              selectedUsers.map((user) => ({ id: user.value })),
              type,
              searchValue,
              checkSortColumn(sortColumn),
              sortDirection,
              pageSize,
              page,
              null,
              null,
              statusValue === 'all' ? null : statusValue === '0' ? false : true,
              null,
            )

            if (usersResponse.data.participants) {
              setTableColumns(
                generateTableColumns(usersResponse.data.participants),
              )

              setFilteredUsersList(usersResponse.data.participants)

              setTotalCount(usersResponse.data.totalCount)
              setPageCount(Math.ceil(usersResponse.data.totalCount / pageSize))
            }
          } catch (error) {
            errorHandler(error, t)
          } finally {
            setSkipPageReset(true)
            setTableLoading(false)
          }
        } else {
          setTableColumns(generateTableColumns([]))

          setFilteredUsersList([])

          setTotalCount(0)
          setPageCount(0)
        }
      }
    },
    [
      t,
      selectedCompanies,
      searchValue,
      generateTableColumns,
      type,
      refresh,
      tableName,
      statusValue,
      selectedUsers,
    ],
  )

  const handleSelectedCompaniesChange = (companies: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setSelectedCompanies(companies)
    setTableState(
      tableName,
      'selectedCompaniesState',
      JSON.stringify(companies),
    )

    const filteredUsersForMultiselect = users
      .filter((user) =>
        companies.map((company) => company.value).includes(user.companyId),
      )
      .map((u) => ({
        value: u.id,
        label: `${u.lastname} ${u.firstname}`,
      }))
    setFilteredUsers(filteredUsersForMultiselect)
    setTableState(
      tableName,
      'filteredUsersState',
      JSON.stringify(filteredUsersForMultiselect),
    )
    setSelectedUsers(filteredUsersForMultiselect)
    setTableState(
      tableName,
      'selectedUsersState',
      JSON.stringify(filteredUsersForMultiselect),
    )
  }
  const handleSelectedUsersChange = (users: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setSelectedUsers(users)
    setTableState(tableName, 'selectedUsersState', JSON.stringify(users))
  }

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const usersResponse = await LoyaltyUserService.getLoyaltyUsers(
        selectedCompanies.map((company) => {
          return {
            id: company.value,
          }
        }),
        selectedUsers.map((user) => ({ id: user.value })),
        type,
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
        null,
        null,
        statusValue === 'all' ? null : statusValue === '0' ? false : true,
        null,
      )

      const dataUsers = usersResponse.data.participants
      if (dataUsers) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'participantId',
            'participantCentralId',
            'firstname',
            'lastname',
            'email',
            'mobile',
            'storeCentralId',
            'storeName',
            'companyName',
            'userUsername',
            'lastLoginDate',
            'isVisible',
            'isActive',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredUsersData = dataUsers.map((user) => {
          user.isVisible = user.isVisible
            ? t('common.yes').toString()
            : t('common.no').toString()

          user.isActive = user.isActive
            ? t('common.yes').toString()
            : t('common.no').toString()
          return pick(user, visibleColumns)
        })

        const translatedHeaders = {
          participantId: t('excel.loyaltyUsers.participantId'),
          participantCentralId: t('excel.loyaltyUsers.participantCentralId'),
          firstname: t('excel.loyaltyUsers.firstname'),
          lastname: t('excel.loyaltyUsers.lastname'),
          email: t('excel.loyaltyUsers.email'),
          mobile: t('excel.loyaltyUsers.mobile'),
          storeCentralId: t('excel.loyaltyUsers.storeCentralId'),
          storeName: t('excel.loyaltyUsers.storeName'),
          companyName: t('excel.loyaltyUsers.companyName'),
          userUsername: t('excel.loyaltyUsers.userUsername'),
          lastLoginDate: t('excel.loyaltyUsers.lastLoginDate'),
          isVisible: t('excel.loyaltyUsers.isVisible'),
          isActive: t('excel.loyaltyUsers.isActive'),
        }

        const headers = [
          Object.keys(filteredUsersData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredUsersData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredUsersData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  const shouldRenderChangePointsButton = (): boolean => {
    if (
      type === LoyaltyUserTypeCode.PRT &&
      (isXpLoyaltyPointsVisible() ||
        isVpLoyaltyPointsVisible() ||
        isLpLoyaltyPointsVisible())
    ) {
      return true
    } else if (type === LoyaltyUserTypeCode.PRT && isXpLoyaltyPointsVisible()) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            alignItems="center"
            justifyContent={
              isCompanyAdmin(user) && type === LoyaltyUserTypeCode.PRT
                ? 'flex-end'
                : ''
            }
            marginBottom={1}
          >
            {type === LoyaltyUserTypeCode.PRT &&
              (isCentralAdmin(user) || isSuperAdmin(user)) && (
                <PrimaryButton
                  variant="contained"
                  onClick={handleUserAddDialogClickOpen}
                >
                  {t('common.create')}
                </PrimaryButton>
              )}
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              width={type === LoyaltyUserTypeCode.PRT ? '100%' : ''}
            >
              {type === LoyaltyUserTypeCode.PRT &&
                (isCentralAdmin(user) || isSuperAdmin(user)) && (
                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    padding={'5px'}
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: 5,
                      marginLeft: 'auto',
                      marginRight: '10px',
                    }}
                  >
                    <FormControl size="small">
                      <Select
                        id="period-select"
                        onChange={(event: SelectChangeEvent) => {
                          setOperationTypesValue(event.target.value)
                        }}
                        value={operationTypesValue}
                        defaultValue={operationTypesValue}
                        style={{ marginRight: 5 }}
                      >
                        {operationTypes.map((operation) => (
                          <MenuItem value={operation.id} key={operation.id}>
                            {operation.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl size="small">
                      <Select
                        id="period-select"
                        onChange={(event: SelectChangeEvent) => {
                          setPeriodValue(event.target.value)
                        }}
                        value={periodValue}
                        defaultValue={periodValue}
                        style={{ marginRight: 5 }}
                      >
                        {periods.map((period) => (
                          <MenuItem value={period.id} key={period.id}>
                            {period.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <PrimaryButton
                      variant="contained"
                      onClick={() =>
                        handleImportDialogClickOpen(
                          type,
                          periodValue,
                          operationTypesValue,
                        )
                      }
                      style={{ marginRight: 5 }}
                    >
                      {t('pages.loyaltyUsers.pointsImport')}
                    </PrimaryButton>
                  </Stack>
                )}
            </Stack>
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('participants')}
              sx={{ marginLeft: 'auto', minWidth: '203px' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <LoyaltyUsersToolbar
            user={user}
            companies={companies}
            selectedCompanies={selectedCompanies}
            users={filteredUsers}
            selectedUsers={selectedUsers}
            value={searchText}
            statusValue={statusValue}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
              setSearchText(searchValue)
              setTableState(tableName, 'search', searchValue)
            }}
            setSelectedCompanies={(companies: Option[]) => {
              handleSelectedCompaniesChange(companies)
            }}
            setSelectedUsers={(users: Option[]) => {
              handleSelectedUsersChange(users)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
            filterStatus={(event: SelectChangeEvent) => {
              setStatusValue(event.target.value)
              setTableState(tableName, 'status', event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            type={type}
          />
          {(isCentralAdmin(user) || isSuperAdmin(user)) && (
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
              style={{ marginBottom: '-22px' }}
            >
              <>
                {shouldRenderChangePointsButton() && (
                  <Button
                    size="small"
                    endIcon={<ChangeCircleIcon />}
                    style={{ textTransform: 'none', marginRight: 5 }}
                    disabled={selectedRows.length === 0}
                    onClick={() =>
                      handleUserChangePointsDialogClickOpen(selectedRows)
                    }
                  >
                    {t('pages.users.changePoints')}
                  </Button>
                )}
                <Button
                  size="small"
                  endIcon={<HighlightOffIcon />}
                  style={{ textTransform: 'none', marginRight: 5 }}
                  disabled={selectedRows.length === 0}
                  onClick={() =>
                    handleUserDeactivationDialogClickOpen(selectedRows)
                  }
                >
                  {t('pages.loyaltyUsers.deactivation')}
                </Button>
                <Button
                  size="small"
                  endIcon={<VisibilityIcon />}
                  style={{ textTransform: 'none' }}
                  disabled={selectedRows.length === 0}
                  onClick={() => handleUserHideDialogClickOpen(selectedRows)}
                >
                  {t('pages.loyaltyUsers.hide')}
                </Button>
              </>

              {/* <Button
              size="small"
              endIcon={<LockIcon />}
              style={{ textTransform: 'none', marginRight: '15px' }}
              disabled={selectedRows.length === 0}
            >
              {t('pages.users.changePassword')}
            </Button> */}
            </Stack>
          )}
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredUsersList}
            height="calc(100vh - 320px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'participantId',
              'participantCentralId',
              'email',
              'companyName',
              'userUsername',
              'lastLoginDate',
            ]}
            toggleVisibility={setColumnsVisibility}
            handleSelectedRows={setSelectedRows}
          />
          <LoyaltyUserAddDialog
            open={openUserAddDialog}
            handleClose={handleUserAddDialogClose}
            type={type}
          />
          {userId && (
            <LoyaltyUserEditDialog
              open={openUserEditDialog}
              handleClose={handleUserEditDialogClose}
              participantId={userId}
            />
          )}
          {userId && (
            <LoyaltyUserDetailsDialog
              open={openUserDetailsDialog}
              handleClose={handleUserDetailsDialogClose}
              participantId={userId}
            />
          )}
          <LoyaltyUserChangePointsDialog
            users={selectedUserIds}
            open={openUserChangePointsDialog}
            handleClose={handleUserChangePointsDialogClose}
          />
          <LoyaltyUserDeactivationDialog
            participants={selectedUserIds}
            open={openUserDeactivationDialog}
            handleClose={handleUserDeactivationDialogClose}
          />
          <LoyaltyUserVisibilityDialog
            participants={selectedUserIds}
            open={openUserHideDialog}
            handleClose={handleUserHideDialogClose}
          />
          {importName && (
            <ImportDialog
              open={openImportDialog}
              handleClose={handleImportDialogClose}
              name={importName}
              param1={importParamOperationType}
              param2={importParamPeriod}
              title={importTitle}
            />
          )}
        </>
      )}
    </>
  )
}

export default LoyaltyUsersList
