import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import AwardsService from '../../../../services/award.service'
import { toast } from 'react-toastify'
import { Award, GroupAwardActionsTypes } from '../../../../store/Award/types'

type AwardsActionsDialogProps = {
  awards: Pick<Award, 'id'>[]
  actionType: GroupAwardActionsTypes
  title: string
  open: boolean
  handleClose: (refresh: boolean) => void
}

const AwardsActionsDialog: React.FunctionComponent<
  AwardsActionsDialogProps
> = ({ awards, actionType, title, open, handleClose }) => {
  const { t } = useTranslation()
  const [saving, setSaving] = useState<boolean>(false)
  const [comment, setComment] = useState<string | null>(null)
  const [commentLength, setCommentLength] = useState<number>(0)

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 255) {
      setComment(event.target.value)
      setCommentLength(event.target.value.length)
    }
  }

  const onConfirm = async () => {
    try {
      setSaving(true)

      let awardActionResponse

      if (actionType === 'ACCEPT') {
        awardActionResponse = await AwardsService.acceptAwards(
          awards.map((award) => ({
            id: award.id,
          })),
          comment,
        )
      }
      if (actionType === 'DECLINE') {
        awardActionResponse = await AwardsService.declineAwards(
          awards.map((award) => ({
            id: award.id,
          })),
          comment,
        )
      }
      if (actionType === 'CLOSE') {
        awardActionResponse = await AwardsService.closeAwards(
          awards.map((award) => ({
            id: award.id,
          })),
          comment,
        )
      }

      if (awardActionResponse && awardActionResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        handleClose(true)
        setComment(null)
        setCommentLength(0)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  const renderAwardIds = (awards: Pick<Award, 'id'>[]) =>
    awards.map((award) => award.id).join(', ')

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            {t('pages.awards.awardsActionsDialog.forAwards')}:{' '}
            {renderAwardIds(awards)}
          </Typography>
        </DialogContentText>
        <FormControl fullWidth margin="dense">
          <label>{t('pages.awards.awardsActionsDialog.comment')}</label>
          <TextField
            multiline
            rows={4}
            value={comment}
            onChange={handleCommentChange}
            inputProps={{
              autoComplete: 'off',
            }}
          />
        </FormControl>
        <div className="character-amount">{commentLength}/255</div>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              handleClose(false)
              setComment(null)
              setCommentLength(0)
            }}
            disabled={saving}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onConfirm} disabled={saving}>
            {t('common.submit')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default AwardsActionsDialog
