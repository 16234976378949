import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  FormHelperText,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../../helpers/errorHandler'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../../store/types'
import {
  CompanyAddUpdateParams,
  CompanyAddEditErrors,
} from '../../../../../store/Company/types'
import CompanyService from '../../../../../services/company.service'
import RegionService from '../../../../../services/region.service'
import UserService from '../../../../../services/user.service'
import { toast } from 'react-toastify'
import { Region } from '../../../../../store/Region/types'

type CompanyAddDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
}

const CompanyAddDialog: React.FunctionComponent<CompanyAddDialogProps> = ({
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)

  const [userLoading, setUserLoading] = useState<boolean>(false)

  const [userExists, setUserExists] = useState<boolean>(false)
  const [userExistsMessage, setUserExistsMessage] = useState<boolean>(false)

  //new
  const [companyName, setCompanyName] = useState<string>('')
  const [companyCentralId, setCompanyCentralId] = useState<string | null>('')
  const [regionId, setRegionId] = useState<string>('')
  const [regions, setRegions] = useState<Region[]>([])
  const [defaultUserCentralId, setDefaultUserCentralId] = useState<string>('')
  const [firstname, setFirstname] = useState<string | null>('')
  const [lastname, setLastname] = useState<string | null>('')
  const [email, setEmail] = useState<string | null>('')

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: CompanyAddEditErrors = {
    companyId: defaultError,
    companyName: defaultError,
    companyCentralId: defaultError,
    regionId: defaultError,
    defaultUserCentralId: defaultError,
    companyImageId: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<CompanyAddEditErrors>(defaultFormErrors)

  const handleRegionChange = (event: SelectChangeEvent) => {
    setRegionId(event.target.value as string)
  }

  const handleCompanyCentralIdChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCompanyCentralId(event.target.value)
  }
  const handleCompanyNameChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCompanyName(event.target.value)
  }

  //check if user exists in Database
  const handleDefaultUserCentralIdChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDefaultUserCentralId(event.target.value)
    if (event.target.value.length > 0) {
      setUserLoading(true)
      try {
        const userNameResponse = await UserService.getUserData(
          event.target.value,
        )

        if (userNameResponse.data.firstname) {
          setFirstname(userNameResponse.data.firstname)
          setLastname(userNameResponse.data.lastname)
          setEmail(userNameResponse.data.email)
          setUserExists(true)
        }
        if (!userNameResponse.data.firstname) {
          setFirstname('')
          setLastname('')
          setEmail('')
          setUserExists(false)
          setUserExistsMessage(true)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setUserLoading(false)
      }
    }
    if (event.target.value.length === 0) {
      setFirstname('')
      setLastname('')
      setEmail('')
      setUserExists(false)
      setUserExistsMessage(false)
    }
  }

  const resetForm = () => {
    setFormErrors(defaultFormErrors)
    setRegionId('')
    setRegions([])
    setCompanyName('')
    setCompanyCentralId('')
    setDefaultUserCentralId('')
    setFirstname('')
    setLastname('')
    setEmail('')
    setUserExists(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // get groups
        const groupsResponse = await RegionService.getRegionList()
        if (groupsResponse.data.regions) {
          setRegions(groupsResponse.data.regions)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [open, t])

  const onSave = async () => {
    setFormErrors(defaultFormErrors)
    const formErrors = defaultFormErrors

    if (companyName.trim() === '') {
      setFormErrors({
        ...formErrors,
        companyName: {
          error: true,
          message: t('pages.company.companyAddEditDialog.errors.required'),
        },
      })
      return
    } else if (defaultUserCentralId.trim() === '') {
      setFormErrors({
        ...formErrors,
        defaultUserCentralId: {
          error: true,
          message: t('pages.company.companyAddEditDialog.errors.required'),
        },
      })
      return
    } else if (!userExists) {
      return
    }

    const formParams: CompanyAddUpdateParams = {
      companyId: null,
      companyName,
      companyCentralId: companyCentralId ? companyCentralId : null,
      regionId: regionId ? parseInt(regionId) : null,
      defaultUserCentralId,
      companyImageId: null,
    }

    try {
      setSaving(true)
      const addUserResponse = await CompanyService.addAndEditCompany(formParams)

      if (addUserResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        resetForm()
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.company.companyAddEditDialog.title')}
        </Typography>
      </DialogTitle>
      {loading && <LoadingSpinner />}
      {!loading && (
        <DialogContent>
          <DialogContentText>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.company.companyAddEditDialog.companyName')} *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={companyName}
                    onChange={handleCompanyNameChange}
                    error={formErrors.companyName.error}
                    helperText={
                      formErrors.companyName.error &&
                      formErrors.companyName.message
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.company.companyAddEditDialog.companyCentralId')}
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={companyCentralId}
                    onChange={handleCompanyCentralIdChange}
                    error={formErrors.companyCentralId.error}
                    helperText={
                      formErrors.companyCentralId.error &&
                      formErrors.companyCentralId.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={formErrors.regionId.error}
                >
                  <label>
                    {t('pages.company.companyAddEditDialog.region')}
                  </label>
                  <Select
                    id="company-select"
                    value={regionId}
                    onChange={handleRegionChange}
                    size="small"
                  >
                    {regions.map((item: any) => (
                      <MenuItem value={item.regionId} key={item.regionId}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.regionId.error && (
                    <FormHelperText>
                      {formErrors.regionId.error && formErrors.regionId.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t(
                      'pages.company.companyAddEditDialog.defaultUserCentralId',
                    )}{' '}
                    *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={defaultUserCentralId}
                    onChange={handleDefaultUserCentralIdChange}
                    error={formErrors.defaultUserCentralId.error}
                    helperText={
                      formErrors.defaultUserCentralId.error &&
                      formErrors.defaultUserCentralId.message
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.company.companyAddEditDialog.firstname')}
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={firstname}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.company.companyAddEditDialog.lastname')}
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={lastname}
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.company.companyAddEditDialog.email')}</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={email}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '4px',
                  height: 10,
                }}
              >
                {userLoading && userExistsMessage && (
                  <>{t('pages.company.companyAddEditDialog.searching')}</>
                )}
                {!userLoading && userExists && userExistsMessage && (
                  <div style={{ color: 'green' }}>
                    {t('pages.company.companyAddEditDialog.found')}
                  </div>
                )}
                {!userLoading && !userExists && userExistsMessage && (
                  <div style={{ color: '#d32f2f' }}>
                    {t('pages.company.companyAddEditDialog.userDoNotExist')}
                  </div>
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              resetForm()
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSave} disabled={saving || loading}>
            {t('common.save')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default CompanyAddDialog
