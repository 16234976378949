export function isXpPointsVisible() {
  return window.env.XP_POINTS
}

export function isVpPointsVisible() {
  return window.env.VP_POINTS
}

export function isEpPointsVisible() {
  return window.env.EP_POINTS
}

export function isXpLoyaltyPointsVisible() {
  return window.env.XP_LOYALTY_POINTS
}

export function isVpLoyaltyPointsVisible() {
  return window.env.VP_LOYALTY_POINTS
}

export function isLpLoyaltyPointsVisible() {
  return window.env.LP_LOYALTY_POINTS
}
