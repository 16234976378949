import axios from 'axios'
import { AccountOperationListResponse } from '../store/AccountOperation/types'
import {
  UserChangePointsParams,
  UserChangePointsResponse,
} from '../store/User/types'
import authHeader from './authHeader'
import {
  LoyaltyUserChangePointsParams,
  LoyaltyUserChangePointsResponse,
} from '../store/LoyaltyUser/types'

const API_URL = process.env.REACT_APP_API_URL

const getAccountOperationList = (): Promise<AccountOperationListResponse> => {
  return axios.get(API_URL + '/api/admin/game/operation-type', {
    headers: authHeader(),
  })
}

const userChangePoints = async (
  params: UserChangePointsParams,
): Promise<UserChangePointsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/game/operation',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const getGroupAccountOperationList =
  (): Promise<AccountOperationListResponse> => {
    return axios.get(API_URL + '/api/admin/game/group-operation-type', {
      headers: authHeader(),
    })
  }

const userChangeGroupPoints = async (
  params: UserChangePointsParams,
): Promise<UserChangePointsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/game/group-operation',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const getLoyaltyAccountOperationList =
  (): Promise<AccountOperationListResponse> => {
    return axios.get(
      API_URL + '/api/admin/loyalty/participant/operation-type',
      {
        headers: authHeader(),
      },
    )
  }

const loyaltyUserChangePoints = async (
  params: LoyaltyUserChangePointsParams,
): Promise<LoyaltyUserChangePointsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/loyalty/participant/operation',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getAccountOperationList,
  userChangePoints,
  getGroupAccountOperationList,
  userChangeGroupPoints,
  getLoyaltyAccountOperationList,
  loyaltyUserChangePoints,
}

export default exportedObject
