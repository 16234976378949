import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { Option } from '../../../../store/types'
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import { MultiSelect } from 'react-multi-select-component'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { Period } from '../../../../store/Period/types'
import DateTimePicker from '../../../shared/DateTimePicker'
import moment from 'moment'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'
import { thousandsSeparator } from '../../../../helpers/utils'

interface OperationsToolbarProps {
  clearSearch: () => void
  submitSearch: (value: string) => void
  onChange: (event: { target: { value: string } }) => void
  setSelectedCompanies: (companies: Option[]) => void
  setSelectedFilterOperationTypes: (operationTypes: Option[]) => void
  setSelectedUsers: (users: Option[]) => void
  filterPeriod: (event: SelectChangeEvent) => void
  selectedCompanies: Option[]
  selectedUsers: Option[]
  companies: Option[]
  users: Option[]
  filterOperationTypes: Option[]
  selectedFilterOperationTypes: Option[]
  periods: Period[]
  periodValue: string
  value: string
  dateFrom: Date | null
  timeFrom: Date | null
  dateTo: Date | null
  timeTo: Date | null
  totalXp: number
  totalVp: number
  totalLp: number
  setDateFrom: React.Dispatch<React.SetStateAction<Date | null>>
  setTimeFrom: React.Dispatch<React.SetStateAction<Date | null>>
  setDateTo: React.Dispatch<React.SetStateAction<Date | null>>
  setTimeTo: React.Dispatch<React.SetStateAction<Date | null>>
}

export default function OperationsToolbar(props: OperationsToolbarProps) {
  const { t } = useTranslation()

  const customValueRenderer = (selected: { label: any }[], _options: any) => {
    return selected.length
      ? t('multiSelect.selectedItems', { length: selected.length })
      : t('multiSelect.select')
  }
  const onSearchKeyDown = (e: { keyCode: number }) => {
    // on enter press
    if (e.keyCode === 13) {
      props.submitSearch(props.value)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 10,
        alignItems: 'flex-end',
        flexDirection: 'row',
      }}
    >
      <div style={{ display: 'flex' }}>
        <FormControl
          size="small"
          sx={{
            minWidth: 180,
            zIndex: 6,
          }}
        >
          <label>
            {t('pages.loyaltyOperations.toolbar.filters.operationTypes')}
          </label>
          <MultiSelect
            options={props.filterOperationTypes}
            value={props.selectedFilterOperationTypes}
            onChange={props.setSelectedFilterOperationTypes}
            labelledBy={t('multiSelect.labelledBy')}
            overrideStrings={{
              allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
              clearSearch: t('multiSelect.clearSearch'),
              noOptions: t('multiSelect.noOptions'),
              search: t('multiSelect.search'),
              selectAll: t('multiSelect.selectAll'),
              selectAllFiltered: t('multiSelect.selectAllFiltered'),
              selectSomeItems: t('multiSelect.selectSomeItems'),
            }}
            valueRenderer={customValueRenderer}
          />
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 180, zIndex: 6 }}>
          <label>
            {t('pages.loyaltyOperations.toolbar.filters.companies')}
          </label>
          <MultiSelect
            options={props.companies}
            value={props.selectedCompanies}
            onChange={props.setSelectedCompanies}
            labelledBy={t('multiSelect.labelledBy')}
            overrideStrings={{
              allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
              clearSearch: t('multiSelect.clearSearch'),
              noOptions: t('multiSelect.noOptions'),
              search: t('multiSelect.search'),
              selectAll: t('multiSelect.selectAll'),
              selectAllFiltered: t('multiSelect.selectAllFiltered'),
              selectSomeItems: t('multiSelect.selectSomeItems'),
            }}
            valueRenderer={customValueRenderer}
          />
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 180, zIndex: 6 }}>
          <label>{t('pages.loyaltyOperations.toolbar.filters.users')}</label>
          <MultiSelect
            options={props.users}
            value={props.selectedUsers}
            onChange={props.setSelectedUsers}
            labelledBy={t('multiSelect.labelledBy')}
            overrideStrings={{
              allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
              clearSearch: t('multiSelect.clearSearch'),
              noOptions: t('multiSelect.noOptions'),
              search: t('multiSelect.search'),
              selectAll: t('multiSelect.selectAll'),
              selectAllFiltered: t('multiSelect.selectAllFiltered'),
              selectSomeItems: t('multiSelect.selectSomeItems'),
            }}
            valueRenderer={customValueRenderer}
          />
        </FormControl>
      </div>
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        padding={'5px'}
        style={{
          border: '1px solid #ccc',
          borderRadius: 5,
          marginLeft: '10px',
          marginRight: 'auto',
        }}
      >
        <FormControl size="small">
          <label>{t('pages.loyaltyOperations.toolbar.filters.period')}</label>
          <Select
            id="period-select"
            onChange={props.filterPeriod}
            value={props.periodValue}
            defaultValue={props.periodValue}
          >
            <MenuItem value="all">{t('common.all')}</MenuItem>
            {props.periods.map((period) => (
              <MenuItem value={period.id} key={period.id}>
                {period.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small">
          <DateTimePicker
            label={t('pages.loyaltyOperations.toolbar.filters.dateFrom')}
            date={props.dateFrom}
            time={props.timeFrom}
            onDateChange={props.setDateFrom}
            onTimeChange={props.setTimeFrom}
            maxDate={props.dateTo || undefined}
            style={{
              width: 260,
              marginRight: 8,
              marginLeft: 8,
            }}
          />
        </FormControl>
        <FormControl size="small">
          <DateTimePicker
            label={t('pages.loyaltyOperations.toolbar.filters.dateTo')}
            date={props.dateTo}
            time={props.timeTo}
            onDateChange={props.setDateTo}
            onTimeChange={props.setTimeTo}
            minDate={props.dateFrom || undefined}
            maxDate={moment(new Date()).add(1, 'day').toDate()}
            style={{
              width: 260,
              marginRight: 8,
            }}
          />
        </FormControl>
      </Stack>
      <Stack display={'flex'} justifyContent={'space-around'}>
        <div
          style={{
            marginBottom: 5,
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          {isXpPointsVisible() && (
            <div>
              <span style={{ fontWeight: 'bold' }}>
                {t('pages.loyaltyOperations.toolbar.xp')}:
              </span>{' '}
              {thousandsSeparator(props.totalXp)}
            </div>
          )}
          {isVpPointsVisible() && (
            <div>
              <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                {t('pages.loyaltyOperations.toolbar.vp')}:
              </span>{' '}
              {thousandsSeparator(props.totalVp)}
            </div>
          )}
          {isEpPointsVisible() && (
            <div>
              <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                {t('pages.loyaltyOperations.toolbar.lp')}:
              </span>{' '}
              {thousandsSeparator(props.totalLp)}
            </div>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <TextField
            variant="outlined"
            size="small"
            value={props.value}
            onChange={props.onChange}
            placeholder={t('pages.loyaltyOperations.toolbar.search')}
            onKeyDown={onSearchKeyDown}
            InputProps={{
              style: {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              } as React.CSSProperties,
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? 'visible' : 'hidden' }}
                  onClick={props.clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
            inputProps={{ maxLength: 100 }}
          />
          <PrimaryButton
            onClick={() => props.submitSearch(props.value)}
            style={{
              padding: '7px 5px',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              height: '40px',
            }}
          >
            {t('common.search')}
          </PrimaryButton>
        </div>
      </Stack>
    </div>
  )
}
