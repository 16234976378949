import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import HomeService from '../../../../services/home.service'
import CompanyService from '../../../../services/company.service'
import { UserRanking } from '../../../../store/Home/types'
import {
  Card,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Carousel from 'react-elastic-carousel'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import UserProfileImage from '../../../shared/UserProfileImage'
import {
  escapeRegExp,
  renderGroupIcon,
  thousandsSeparator,
} from '../../../../helpers/utils'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import PhoneDialog from '../../../PhonePreview/PhoneDialog'
import './UsersRanking.css'
import { Company } from '../../../../store/Company/types'
import { ReactComponent as XpIcon } from '../../../../assets/images/icons/xp_icon.svg'
import { ReactComponent as VpIcon } from '../../../../assets/images/icons/vp_icon.svg'
import { ReactComponent as PreviewPhoneIcon } from '../../../../assets/images/icons/preview_phone.svg'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import { pick } from 'lodash'
import {
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'

type UsersRankingProps = {}

const UsersRanking: React.FunctionComponent<UsersRankingProps> = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [users, setUsers] = useState<UserRanking[]>([])
  const [filteredUsers, setFilteredUsers] = useState<UserRanking[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [companies, setCompanies] = useState<Company[]>([])
  const [companyValue, setCompanyValue] = useState<string>('all')
  const [openPhoneDialog, setPhoneDialogOpen] = useState(false)
  const [userId, setUserId] = useState<number | null>(null)

  const breakPoints: {
    width: number
    itemsToShow: number
    itemsToScroll: number
  }[] = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 6, itemsToScroll: 6 },
  ]

  const requestSearch = (
    searchValue: string,
    filters: { column: string; value: string }[],
  ) => {
    let filteredRows: UserRanking[] = users

    if (filters.length > 0) {
      filters.forEach((filter) => {
        filteredRows = filteredRows.filter((user) => {
          if (filter.value === 'all') {
            return true
          }
          if (
            (user as any)[filter.column].toString() === filter.value.toString()
          ) {
            return true
          }
          return false
        })
      })
    }

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    filteredRows = filteredRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && searchRegex.test(row[field].toString())
      })
    })
    setFilteredUsers(filteredRows)
  }

  const handleSearch = (event: { target: { value: string } }) => {
    setSearchValue(event.target.value)
    requestSearch(event.target.value, [
      {
        column: 'companyId',
        value: companyValue,
      },
    ])
  }

  const handlePhoneDialogClickOpen = (userId: number) => {
    setUserId(userId)
    setPhoneDialogOpen(true)
  }

  const handlePhoneDialogClose = () => {
    setPhoneDialogOpen(false)
  }

  const clearSearch = () => {
    setSearchValue('')
    requestSearch('', [
      {
        column: 'companyId',
        value: companyValue,
      },
    ])
  }

  const filterCompany = (event: SelectChangeEvent) => {
    setCompanyValue(event.target.value)
    requestSearch(searchValue, [
      {
        column: 'companyId',
        value: event.target.value,
      },
    ])
  }

  const downloadXLSX = (data: any, name: string) => {
    const fileName = `${name}.xlsx`

    const visibleColumns = [
      'userCentralId',
      'firstname',
      'lastname',
      'regionName',
      'companyName',
      'xpAmount',
      'vpAmount',
      'epAmount',
      'rankPlace',
      'currentLevel',
      'hasWonPrize',
      'awardGroup',
      'vpAwardAmount',
    ]

    const filteredData = data.map((d: any) => {
      d.hasWonPrize = d.hasWonPrize
        ? t('common.yes').toString()
        : t('common.no').toString()
      return pick(d, visibleColumns)
    })

    const translatedHeaders = {
      userCentralId: t('excel.usersRanking.userCentralId'),
      firstname: t('excel.usersRanking.firstname'),
      lastname: t('excel.usersRanking.lastname'),
      regionName: t('excel.usersRanking.regionName'),
      companyName: t('excel.usersRanking.companyName'),
      vpAmount: t('excel.usersRanking.vpAmount'),
      xpAmount: t('excel.usersRanking.xpAmount'),
      epAmount: t('excel.usersRanking.epAmount'),
      rankPlace: t('excel.usersRanking.rankPlace'),
      awardGroup: t('excel.usersRanking.awardGroup'),
      awardOrder: t('excel.usersRanking.awardOrder'),
      awardShare: t('excel.usersRanking.awardShare'),
      hasWonPrize: t('excel.usersRanking.hasWonPrize'),
      currentLevel: t('excel.usersRanking.currentLevel'),
      vpAwardAmount: t('excel.usersRanking.vpAwardAmount'),
    }

    const headers = [
      Object.keys(filteredData[0]).map(
        (key) => (translatedHeaders as any)[key],
      ),
    ]

    //Had to create a new workbook and then add the header
    const ws: XLSX.WorkSheet = XLSX.utils.book_new()
    XLSX.utils.sheet_add_aoa(ws, headers)

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, filteredData, {
      origin: 'A2',
      skipHeader: true,
    })

    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, name)

    XLSX.writeFile(wb, fileName)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersRankingResponse = await HomeService.getUsersRanking()

        if (usersRankingResponse.data.users) {
          setUsers(usersRankingResponse.data.users)
          setFilteredUsers(usersRankingResponse.data.users)
        }

        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          setCompanies(companyListResponse.data.companies)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div
          style={{
            background: '#f1f1f1',
            borderRadius: '3px',
            margin: '0 20px',
            padding: '15px 20px',
            height: '100%',
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-between"
            mb={1}
          >
            <Stack flexDirection="row" alignItems="flex-end">
              <FormControl size="small" style={{ marginRight: '10px' }}>
                <label style={{ fontSize: '12px', fontWeight: 'bold' }}>
                  {t('pages.home.usersRanking.company')}
                </label>
                <Select
                  id="company-select"
                  onChange={filterCompany}
                  value={companyValue}
                  defaultValue={companyValue}
                  size="small"
                  style={{ backgroundColor: '#fff' }}
                >
                  <MenuItem value="all">{t('common.all')}</MenuItem>
                  {companies.map((company) => (
                    <MenuItem value={company.companyId} key={company.companyId}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <SecondaryButton
                onClick={() => downloadXLSX(users, 'ranking')}
                style={{ padding: '7px 20px' }}
              >
                {t('pages.home.usersRanking.xlsx')}
              </SecondaryButton>
            </Stack>
            <FormControl size="small">
              <label style={{ fontSize: '12px', fontWeight: 'bold' }}>
                {t('pages.home.usersRanking.searchBar')}
              </label>
              <TextField
                variant="outlined"
                size="small"
                value={searchValue}
                onChange={handleSearch}
                placeholder={t('pages.challenge.table.search')}
                style={{ backgroundColor: '#fff' }}
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" />,
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: searchValue ? 'visible' : 'hidden' }}
                      onClick={clearSearch}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
            </FormControl>
          </Stack>
          <Carousel
            isRTL={false}
            enableAutoPlay={false}
            enableMouseSwipe={false}
            enableSwipe={false}
            transitionMs={700}
            breakPoints={breakPoints}
            pagination={false}
          >
            {filteredUsers.length === 0 && (
              <div
                style={{
                  height: '210px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('common.noData')}
              </div>
            )}
            {filteredUsers.map((user) => (
              <Card
                key={user.id}
                elevation={0}
                style={{
                  maxWidth: '300px',
                  width: '100%',
                  height: '100%',
                  padding: '15px',
                  textAlign: 'center',
                  marginTop: '30px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  overflow: 'visible',
                  position: 'relative',
                }}
              >
                <div
                  style={{ position: 'absolute', top: '-10px', left: '-5px' }}
                >
                  <EmojiEventsIcon color="primary" fontSize="large" />
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {user.rankPlace}
                  </span>
                </div>
                {!user.awardGroup && (
                  <div
                    style={{ position: 'absolute', top: '0px', right: '10px' }}
                  >
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                      {t('pages.home.usersRanking.currentLevel')}
                      {user.currentLevel}
                    </span>
                  </div>
                )}
                {user.awardGroup && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '10px',
                    }}
                  >
                    {renderGroupIcon(user.awardGroup)}
                  </div>
                )}
                <UserProfileImage
                  userId={user.id}
                  firstname={user.firstname}
                  lastname={user.lastname}
                  style={{
                    width: 70,
                    height: 70,
                    border: '7px solid #fff',
                    marginTop: '-40px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <Typography
                  variant="body2"
                  display="block"
                  gutterBottom
                  fontWeight="bold"
                  minHeight={40}
                >
                  {user.firstname} {user.lastname}
                </Typography>
                <Typography
                  variant="caption"
                  gutterBottom
                  textAlign="center"
                  noWrap
                  letterSpacing="0"
                  style={{
                    backgroundColor: '#f1f1f1',
                    padding: '2px 7px',
                    fontSize: '14px',
                  }}
                >
                  {user.companyName}
                </Typography>
                <Grid container spacing={1} mt={1} height={40}>
                  <Grid
                    item
                    xs={5}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {isXpPointsVisible() && (
                      <Typography
                        variant="caption"
                        display="flex"
                        fontWeight="bold"
                        textAlign="left"
                        alignItems="center"
                      >
                        <XpIcon
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '5px',
                          }}
                        />
                        {thousandsSeparator(user.xpAmount)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {user.previewEnabled && (
                      <Typography
                        variant="caption"
                        display="flex"
                        fontWeight="bold"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <IconButton
                          size="small"
                          onClick={() => handlePhoneDialogClickOpen(user.id)}
                        >
                          <PreviewPhoneIcon />
                        </IconButton>
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {isVpPointsVisible() && (
                      <Typography
                        variant="caption"
                        display="flex"
                        fontWeight="bold"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <VpIcon
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '5px',
                          }}
                        />
                        {thousandsSeparator(user.vpAmount)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Carousel>
          {userId && (
            <PhoneDialog
              open={openPhoneDialog}
              handleClose={handlePhoneDialogClose}
              userId={userId}
            />
          )}
        </div>
      )}
    </>
  )
}

export default UsersRanking
