import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import LoyaltyOperationService from '../../../../services/loyaltyOperation.service'
import {
  isLpLoyaltyPointsVisible,
  isVpLoyaltyPointsVisible,
  isXpLoyaltyPointsVisible,
} from '../../../../helpers/environment'
import { DetailsData } from '../../../../store/Details/types'
import DetailsDialog from '../../../shared/DetailsDialog'

type OperationDetailsDialogProps = {
  operationId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const OperationDetailsDialog: React.FunctionComponent<
  OperationDetailsDialogProps
> = ({ operationId, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [detailsParamsForDialog, setDetailsParamsForDialog] =
    useState<DetailsData>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const operationDetailsResponse =
          await LoyaltyOperationService.getLoyaltyOperationDetails(operationId)
        if (operationDetailsResponse.data) {
          const operationsDetailsParams: DetailsData = {
            title: {
              label: `${t(
                'pages.loyaltyOperations.loyaltyOperationDetailsDialog.id',
              )}: ${operationId.toString()}`,
              value: operationDetailsResponse.data.typeName,
            },
            data: [
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.operationTypeId',
                ),
                value: operationDetailsResponse.data.typeId,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.operationTypeName',
                ),
                value: operationDetailsResponse.data.typeName,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.operationName',
                ),
                value: operationDetailsResponse.data.operationName,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.operationDate',
                ),
                value: operationDetailsResponse.data.operationDate,
                bottomSeparator: true,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.participantCentralId',
                ),
                value: operationDetailsResponse.data.participantCentralId,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.participantFirstname',
                ),
                value: operationDetailsResponse.data.participantFirstname,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.participantLastname',
                ),
                value: operationDetailsResponse.data.participantLastname,
                bottomSeparator: true,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.storeCentralId',
                ),
                value: operationDetailsResponse.data.storeCentralId,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.storeName',
                ),
                value: operationDetailsResponse.data.storeName,
                bottomSeparator: true,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.authorCentralId',
                ),
                value: operationDetailsResponse.data.authorCentralId,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.authorFirstname',
                ),
                value: operationDetailsResponse.data.authorFirstname,
              },
              {
                label: t(
                  'pages.loyaltyOperations.loyaltyOperationDetailsDialog.authorLastname',
                ),
                value: operationDetailsResponse.data.authorLastname,
                bottomSeparator:
                  operationDetailsResponse.data.xpValue ||
                  operationDetailsResponse.data.vpValue ||
                  operationDetailsResponse.data.lpValue
                    ? true
                    : false,
              },
            ],
          }
          if (isXpLoyaltyPointsVisible()) {
            operationsDetailsParams.data.push({
              label: t(
                'pages.loyaltyOperations.loyaltyOperationDetailsDialog.xpValue',
              ),
              value: operationDetailsResponse.data.xpValue,
            })
          }
          if (isVpLoyaltyPointsVisible()) {
            operationsDetailsParams.data.push({
              label: t(
                'pages.loyaltyOperations.loyaltyOperationDetailsDialog.vpValue',
              ),
              value: operationDetailsResponse.data.vpValue,
            })
          }
          if (isLpLoyaltyPointsVisible()) {
            operationsDetailsParams.data.push({
              label: t(
                'pages.loyaltyOperations.loyaltyOperationDetailsDialog.lpValue',
              ),
              value: operationDetailsResponse.data.lpValue,
            })
          }
          setDetailsParamsForDialog(operationsDetailsParams)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, operationId])

  return (
    <>
      {detailsParamsForDialog && (
        <DetailsDialog
          detailsData={detailsParamsForDialog}
          open={open}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default OperationDetailsDialog
