import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import './Donuts.css'
import { Stack, Grid, Typography, styled } from '@mui/material'

import AmateurImg from '../../../../../assets/images/phone/amateur.png'
import ExpertImg from '../../../../../assets/images/phone/expert.png'
import MasterImg from '../../../../../assets/images/phone/master.png'

export const LevelBadge = styled('div')({
  backgroundColor: '#f1f1f1',
  border: '1px solid #dcdcdc',
  borderRadius: '5px',
  fontSize: '75%',
  padding: '1px 8px',
  display: 'inline-block',
})

type TakenChallengesStackedBarProps = {
  amateurAcceptances: number
  amateurCompleted: number
  expertAcceptances: number
  expertCompleted: number
  masterAcceptances: number
  masterCompleted: number
}

const TakenChallengesStackedBar: React.FunctionComponent<
  TakenChallengesStackedBarProps
> = ({
  amateurAcceptances,
  amateurCompleted,
  expertAcceptances,
  expertCompleted,
  masterAcceptances,
  masterCompleted,
}) => {
  const { t } = useTranslation()
  const chartEl = useRef<SVGSVGElement>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTimeout(() => {
          const stackHeight = Math.max(
            amateurAcceptances,
            expertAcceptances,
            masterAcceptances,
          )
          const margin = { top: 0, right: 0, bottom: 0, left: 0 },
            width = 220 - margin.left - margin.right,
            height = 90 - margin.top - margin.bottom

          // append the svg object to the body of the page
          const svg = d3.select(chartEl.current)

          svg
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .attr(
              'viewBox',
              `0 0 ${width + margin.left + margin.right} ${
                height + margin.top + margin.bottom
              }`,
            )
            .attr('style', 'height: auto; height: intrinsic;')

          svg.selectAll('*').remove()

          const data = [
            {
              group: 'amateur',
              taken: amateurCompleted,
              overall: amateurAcceptances - amateurCompleted,
            },
            {
              group: 'expert',
              taken: expertCompleted,
              overall: expertAcceptances - expertCompleted,
            },
            {
              group: 'master',
              taken: masterCompleted,
              overall: masterAcceptances - masterCompleted,
            },
          ]

          // Parse the Data
          // d3.csv(
          //   'https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/data_stacked.csv',
          // ).then(function (data) {
          // List of subgroups = header of the csv files = soil condition here
          // const subgroups = data.columns.slice(1)
          const subgroups = ['taken', 'overall']

          // List of groups = species here = value of the first column called group -> I show them on the X axis
          const groups = data.map((d) => d.group)

          // Add X axis
          const x = d3
            .scaleBand()
            // @ts-ignore
            .domain(groups)
            .range([0, width])
            .padding(0.6)
            .paddingOuter(0.3)
          // svg
          //   .append('g')
          //   .attr('transform', `translate(0, ${height})`)
          //   .call(d3.axisBottom(x).tickSizeOuter(0))

          // Add Y axis
          const y = d3.scaleLinear().domain([0, stackHeight]).range([height, 0])
          // svg.append('g').call(d3.axisLeft(y)).attr('transform', `translate(30, 0)`)

          // color palette = one color per subgroup
          const color = d3
            .scaleOrdinal()
            .domain(subgroups)
            .range(['#61aa0d', '#dcdcdc'])

          //stack the data? --> stack per subgroup
          // @ts-ignore
          const stackedData = d3.stack().keys(subgroups)(data)

          // Show the bars
          svg
            .append('g')
            .selectAll('g')
            // Enter in the stack data = loop key per key = group per group
            .data(stackedData)
            .join('g')
            // @ts-ignore
            .attr('fill', (d) => color(d.key))
            .selectAll('rect')
            // enter a second time = loop subgroup per subgroup to add all rectangles
            .data((d) => d)
            .join('rect')
            // @ts-ignore
            .attr('x', (d) => x(d.data.group))
            .attr('y', (d) => y(d[1]))
            .attr('height', (d) => y(d[0]) - y(d[1]))
            .attr('width', x.bandwidth())
            .attr('rx', 2)
            .attr('ry', 2)
          // })
        }, 0)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [
    t,
    amateurAcceptances,
    amateurCompleted,
    expertAcceptances,
    expertCompleted,
    masterAcceptances,
    masterCompleted,
  ])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div style={{ width: '220px' }}>
          <svg ref={chartEl} />
          <Stack flexDirection="row" width={220} alignItems="center">
            <Grid xs={4} textAlign="center">
              <Typography
                variant="caption"
                display="block"
                fontWeight="bold"
                letterSpacing={0}
              >
                <img style={{ width: 30 }} src={AmateurImg} alt="" />
              </Typography>
              <LevelBadge>
                {amateurCompleted}/{amateurAcceptances}
              </LevelBadge>
            </Grid>
            <Grid xs={4} textAlign="center">
              <Typography
                variant="caption"
                display="block"
                fontWeight="bold"
                letterSpacing={0}
              >
                <img style={{ width: 30 }} src={ExpertImg} alt="" />
              </Typography>
              <LevelBadge>
                {expertCompleted}/{expertAcceptances}
              </LevelBadge>
            </Grid>
            <Grid xs={4} textAlign="center">
              <Typography
                variant="caption"
                display="block"
                fontWeight="bold"
                letterSpacing={0}
              >
                <img style={{ width: 30 }} src={MasterImg} alt="" />
              </Typography>
              <LevelBadge>
                {masterCompleted}/{masterAcceptances}
              </LevelBadge>
            </Grid>
          </Stack>
        </div>
      )}
    </>
  )
}

export default TakenChallengesStackedBar
