import { UserRole } from '../Auth/types'

export const enum ConfigurationParameterName {
  ML = 'ADMIN_APPLICATION_ML_IMAGE_PARAMETERS',
  CD = 'ADMIN_APPLICATION_CD_IMAGE_PARAMETERS',
  TMAL = 'TRADE_ACTION_TMAL_IMAGE_PARAMETERS',
  TMARI = 'TRADE_ACTION_TMARI_IMAGE_PARAMETERS',
  ATI = 'ADMIN_APPLICATION_DEFAULT_IMAGE_PARAMETERS',
  AQQI = 'ADMIN_APPLICATION_DEFAULT_IMAGE_PARAMETERS',
}

export type ApplicationModule = {
  id: number
  name: string
  code: string
  description: string | null
  isAvailable: boolean
  roles: UserRole[]
}

export type AppliactionModulesResponse = {
  data: {
    modules: ApplicationModule[]
  }
}

export type UpdateDateResponse = {
  data: {
    lastUpdateDate: string
  }
}

export type StoresFtpImportParameterResponse = {
  data: {
    ftpStoreImportType: number
  }
}

export type FtpStoreImportResponse = {
  data: {
    success: boolean
  }
}

export type SetUpdateDateResponse = {
  data: {
    success: boolean
  }
}

export type ConfigurationParameter = {
  maxSize: number
  maxNameLength: number
  maxWidth: number
  maxHeight: number
  mimeTypes: string[]
}

export type ConfigurationParameterResponse = {
  data: ConfigurationParameter
}

export type ApplicationVersion = {
  applicationVersionId: number
  applicationVersion: string
}

export type AppliactionReleasesResponse = {
  data: {
    applicationVersions: ApplicationVersion[]
  }
}

export type ApiVersionResponse = {
  data: {
    apiVersion: string
  }
}
