import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import RegionAddDialog from '../partials/RegionAddDialog'

type RegionListProps = {
  path: string
}

const RegionsList: FunctionComponent<RegionListProps> = ({ path }) => {
  // const tableName = 'region'

  const { t } = useTranslation()

  const [addRegionDialogOpen, setAddRegionDialogOpen] = useState(false)

  const handleRegionAddDialogClickOpen = () => {
    setAddRegionDialogOpen(true)
  }

  const handleRegionAddDialogClose = () => {
    setAddRegionDialogOpen(false)
  }

  return (
    <>
      <Stack
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        width="100%"
        marginBottom={1}
      >
        <Stack
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <PrimaryButton
            sx={{ marginTop: '8px', marginBottom: '7px' }}
            variant="contained"
            onClick={handleRegionAddDialogClickOpen}
          >
            {t('pages.regions.addRegion')}
          </PrimaryButton>
        </Stack>
      </Stack>
      <RegionAddDialog
        open={addRegionDialogOpen}
        handleClose={handleRegionAddDialogClose}
      />
    </>
  )
}

export default RegionsList
