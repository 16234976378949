import axios from 'axios'
import { RegionAddResponse, RegionListReponse } from '../store/Region/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getRegionList = (): Promise<RegionListReponse> => {
  return axios.get(API_URL + '/api/admin/region', {
    headers: authHeader(),
  })
}
const addRegion = (
  name: string,
  centralId: string | null,
): Promise<RegionAddResponse> => {
  return axios.post(
    API_URL + '/api/admin/region/create',
    { name, centralId },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getRegionList,
  addRegion,
}

export default exportedObject
