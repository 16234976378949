import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  Grid,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../store/types'
import ManufacturerService from '../../../../services/manufacturer.service'
import { toast } from 'react-toastify'
import {
  ManufacturerDetails,
  ManufacturerEditErrors,
  ManufacturerEditParams,
} from '../../../../store/Manufacturer/types'
import { ImageTypeCode } from '../../../../store/Image/types'
import ImageLibrary from '../../../ImageLibrary/ImageLibrary'

type ManufacturerEditDialogProps = {
  manufacturerId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const ManufacturerEditDialog: React.FunctionComponent<
  ManufacturerEditDialogProps
> = ({ manufacturerId, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)

  //new
  const [name, setName] = useState<string>('')
  const [shortName, setShortName] = useState<string | null>(null)
  const [centralId, setCentralId] = useState<string | null>(null)
  const [imageId, setImageId] = useState<number | null>(null)
  const [manufacturerDetails, setManufacturerDetails] =
    useState<ManufacturerDetails>()

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: ManufacturerEditErrors = {
    imageId: defaultError,
    name: defaultError,
    shortName: defaultError,
    centralId: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<ManufacturerEditErrors>(defaultFormErrors)

  const handleNameChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setName(event.target.value)
  }
  const handleShortNameChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShortName(event.target.value)
  }
  const handleCentralIdChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCentralId(event.target.value)
  }

  const resetForm = () => {
    setFormErrors(defaultFormErrors)
    setName('')
    setShortName('')
    setCentralId('')
    setImageId(null)
  }

  useEffect(() => {
    setLoading(true)

    const fetchCompanyDetails = async () => {
      try {
        const manufacturerDetailsResponse =
          await ManufacturerService.getManufacturerDetails(manufacturerId)
        if (manufacturerDetailsResponse.data) {
          setManufacturerDetails(manufacturerDetailsResponse.data)
          setName(manufacturerDetailsResponse.data.name)
          setShortName(manufacturerDetailsResponse.data.shortName)
          setCentralId(manufacturerDetailsResponse.data.centralId)
          setImageId(manufacturerDetailsResponse.data.imageId)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchCompanyDetails()
  }, [open, t, manufacturerId])

  const onSave = async () => {
    setFormErrors(defaultFormErrors)
    const formErrors = defaultFormErrors

    if (name.trim() === '') {
      setFormErrors({
        ...formErrors,
        name: {
          error: true,
          message: t(
            'pages.manufacturers.manufacturerEditDialog.errors.required',
          ),
        },
      })
      return
    }

    const formParams: ManufacturerEditParams = {
      manufacturerId: manufacturerId,
      imageId,
      name: name,
      shortName: shortName ? shortName : null,
      centralId: centralId ? centralId : null,
    }

    try {
      setSaving(true)
      const addUserResponse = await ManufacturerService.editManufacturer(
        formParams,
      )

      if (addUserResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        resetForm()
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.manufacturers.manufacturerEditDialog.title')}
        </Typography>
      </DialogTitle>
      {loading && <LoadingSpinner />}
      {!loading && (
        <DialogContent>
          <DialogContentText>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.manufacturers.manufacturerEditDialog.name')} *
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={name}
                    onChange={handleNameChange}
                    error={formErrors.name.error}
                    helperText={
                      formErrors.name.error && formErrors.name.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.manufacturers.manufacturerEditDialog.shortName')}
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={shortName}
                    onChange={handleShortNameChange}
                    error={formErrors.shortName.error}
                    helperText={
                      formErrors.shortName.error && formErrors.shortName.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.manufacturers.manufacturerEditDialog.centralId')}
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={centralId}
                    onChange={handleCentralIdChange}
                    error={formErrors.centralId.error}
                    helperText={
                      formErrors.centralId.error && formErrors.centralId.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ImageLibrary
                  imageType={ImageTypeCode.ML}
                  selectedImageId={manufacturerDetails?.imageId || null}
                  setSelectedImageId={(id) => setImageId(id)}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              resetForm()
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSave} disabled={saving || loading}>
            {t('common.save')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default ManufacturerEditDialog
