import axios from 'axios'
import authHeader from './authHeader'
import {
  StoreDiscountTypesResponse,
  StoreDiscountsDetailsResponse,
  StoresDiscountsDeactivateResponse,
  StoresDiscountsEditParams,
  StoresDiscountsEditResponse,
  StoresDiscountsListResponse,
} from '../store/StoreDiscount/types'

const API_URL = process.env.REACT_APP_API_URL

const getStoresDiscountsList = async (
  companies: { id: number }[],
  users: { id: number }[] | null,
  discountTypes: { id: number }[] | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
  isActive: boolean | null,
): Promise<StoresDiscountsListResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store/discount/list',
    {
      companies,
      users,
      discountTypes,
      search,
      sortBy,
      sortOrder,
      per,
      page,
      isActive,
    },
    {
      headers: authHeader(),
    },
  )
}

const getDiscountTypes = (): Promise<StoreDiscountTypesResponse> => {
  return axios.get(API_URL + `/api/admin/store/discount/type`, {
    headers: authHeader(),
  })
}

const storesDiscountsDeactivate = async (
  discounts: { id: number }[],
): Promise<StoresDiscountsDeactivateResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store/discount/deactivate',
    {
      discounts,
    },
    {
      headers: authHeader(),
    },
  )
}

const getStoresDiscountDetails = (
  discountId: number,
): Promise<StoreDiscountsDetailsResponse> => {
  return axios.get(
    API_URL + `/api/admin/store/discount/preview/${discountId}`,
    {
      headers: authHeader(),
    },
  )
}

const storesDiscountsEdit = async (
  params: StoresDiscountsEditParams,
): Promise<StoresDiscountsEditResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store/discount',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getStoresDiscountsList,
  getDiscountTypes,
  storesDiscountsDeactivate,
  getStoresDiscountDetails,
  storesDiscountsEdit,
}

export default exportedObject
