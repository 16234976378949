import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import CompanyService from '../../../../../services/store.service'
import { ImageDetails } from '../../../../../store/Image/types'
import { CircularProgress } from '@mui/material'
import { useInView } from 'react-intersection-observer'

type ImageProps = {
  imageId: number | null
  style?: React.CSSProperties
}
const Image: React.FunctionComponent<ImageProps> = ({ imageId, style }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [image, setImage] = useState<ImageDetails | null>(null)
  const { ref, inView } = useInView({ triggerOnce: true })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const imageResponse = await CompanyService.getImageContent(imageId!)
        if (imageResponse.data.imageContent) {
          setImage(imageResponse.data)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (inView && imageId) {
      fetchData()
    }
  }, [t, imageId, inView])

  if (!imageId) {
    return <></>
  }

  return (
    <div ref={ref}>
      {loading && (
        <>
          <CircularProgress />
        </>
      )}
      {!loading && image && (
        <img
          style={style}
          src={`data:${image.imageMimeType};base64,${image.imageContent}`}
          alt="logo"
        />
      )}
      {!loading && !image && (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {t('pages.company.companyImageDialog.noImage')}
        </div>
      )}
    </div>
  )
}

export default Image
