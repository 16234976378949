import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { Card, Grid, Typography } from '@mui/material'
import DateTimePicker from '../../../shared/DateTimePicker'
import { FormError } from '../../../../store/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import moment from 'moment'
import ClearChatBoxDialog from './ClearChatBoxDialog'

const ClearChatBox: React.FC = () => {
  const { t } = useTranslation()
  const [updateDateTime, setUpdateDateTime] = useState<string>('')
  const [updateDate, setUpdateDate] = useState<Date | null>(null)
  const [updateTime, setUpdateTime] = useState<Date | null>(null)
  const [clearChatDialogOpen, setClearChatDialogOpen] = useState(false)

  const defaultError: FormError = {
    error: false,
    message: '',
  }

  const [updateDateError, setUpdateDateError] =
    useState<FormError>(defaultError)

  const handleCloseConfirmClearChatBoxDialog = () => {
    setClearChatDialogOpen(false)
  }

  const handleOpenConfirmClearChatBoxDialog = async () => {
    setUpdateDateError(defaultError)

    if (
      !updateDate ||
      !updateTime ||
      updateDate.toString() === 'Invalid Date' ||
      updateTime.toString() === 'Invalid Date'
    ) {
      setUpdateDateError({
        error: true,
        message: t('pages.configuration.clearChatBox.dateRequired'),
      })
      return
    }

    const lastUpdateDate =
      moment(updateDate).format('YYYY-MM-DD') +
      ' ' +
      moment(updateTime).format('HH:mm') +
      ':00'
    setUpdateDateTime(lastUpdateDate)
    setClearChatDialogOpen(true)
  }

  return (
    <Stack p={3}>
      <Card style={{ padding: '20px' }}>
        <Grid container>
          <Grid
            item
            md={12}
            sx={{
              fontSize: '20px',
              fontWeight: 'bolder',
              marginBottom: '20px',
            }}
          >
            {t('pages.configuration.clearChatBox.title')}
          </Grid>
        </Grid>
        <Typography variant="subtitle2" gutterBottom>
          {t('pages.configuration.clearChatBox.description')}:
        </Typography>
        <Stack display="flex" flexDirection="row" width={'400px'}>
          <DateTimePicker
            label={''}
            date={updateDate}
            time={updateTime}
            onDateChange={setUpdateDate}
            onTimeChange={setUpdateTime}
            maxDate={moment(new Date()).toDate()}
            error={updateDateError}
            onError={() => {
              setUpdateDateError({
                error: true,
                message: t('pages.configuration.clearChatBox.dateRequired'),
              })
            }}
          />
          <PrimaryButton
            style={{ marginLeft: '10px', height: '40px' }}
            onClick={handleOpenConfirmClearChatBoxDialog}
          >
            {t('common.clear')}
          </PrimaryButton>
        </Stack>
        <ClearChatBoxDialog
          open={clearChatDialogOpen}
          handleClose={handleCloseConfirmClearChatBoxDialog}
          date={updateDateTime}
        />
      </Card>
    </Stack>
  )
}

export default ClearChatBox
