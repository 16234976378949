import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import Image from '../../../shared/Image'

type ManufacturerImageDialogProps = {
  imageId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const ManufacturerImageDialog: React.FunctionComponent<
  ManufacturerImageDialogProps
> = ({ imageId, open, handleClose }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.manufacturers.manufacturerImageDialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image imageId={imageId} style={{ width: '100%', maxWidth: '390px' }} />
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="center" width="100%">
          <SecondaryButton
            onClick={() => {
              handleClose(false)
            }}
          >
            {t('common.close')}
          </SecondaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default ManufacturerImageDialog
