import { AlertColor } from '@mui/material'
import { TFunction } from 'react-i18next'
import { downloadXLSX, thousandsSeparator } from '../../../../../helpers/utils'
import { ImportUsersGroupPointsOperationsResponse } from '../../../../../store/Import/types'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'

export const getUsersGroupPointsOperationsResponse = (
  importResponse: ImportUsersGroupPointsOperationsResponse,
  messageType: 'prepare' | 'accept',
  alertType: AlertColor,
  t: TFunction<'translation'>,
): {
  message: {
    type: AlertColor
    message: string
    hasSecondStep: boolean
  }
  additionalInfo?: React.ReactNode
} | null => {
  if (importResponse.data.skippedUsers) {
    const skippedUsers = importResponse.data.skippedUsers
    const addedResultsSummary = importResponse.data.addedResultsSummary
    const skippedResultsSummary = importResponse.data.skippedResultsSummary
    const addedResultsValue = importResponse.data.addedResultsValue

    const renderAdditionalInfo = () => {
      return (
        <>
          {skippedUsers.length > 0 && (
            <div>
              <SecondaryButton
                onClick={() =>
                  downloadXLSX(
                    skippedUsers,
                    t('import.messages.users.skippedResultsFilename'),
                  )
                }
                size="small"
                style={{ marginBottom: '10px' }}
              >
                {t('import.messages.users.downloadSkippedResults')}
              </SecondaryButton>
            </div>
          )}
        </>
      )
    }

    return {
      message: {
        type: alertType,
        message: t(`import.messages.users.${messageType}Message`, {
          addedResultsSummary: thousandsSeparator(addedResultsSummary || 0),
          skippedResultsSummary: thousandsSeparator(skippedResultsSummary || 0),
          addedResultsValue: thousandsSeparator(addedResultsValue || 0),
        }),
        hasSecondStep: false,
      },
      additionalInfo: renderAdditionalInfo(),
    }
  }
  return null
}
