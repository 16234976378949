import {
  Alert,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DateTimePicker from '../../../../shared/DateTimePicker'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../../store/types'
import {
  LoyaltyCreateOrUpdatePlanDetailsParams,
  LoyaltyPlan,
  LoyaltyPlanDetailsErrors,
  LoyaltyPlanType,
} from '../../../../../store/LoyaltyPlan/types'
import { PlanContainer } from './styles'
import ImageLibrary from '../../../../ImageLibrary/ImageLibrary'
import { ImageTypeCode } from '../../../../../store/Image/types'
import { errorHandler } from '../../../../../helpers/errorHandler'
import moment from 'moment'
import LoyaltyPlanService from '../../../../../services/loyaltyPlan.service'
import LoyaltyPeriodService from '../../../../../services/loyaltyPeriod.service'
import { toast } from 'react-toastify'
import { LoyaltyPeriod } from '../../../../../store/LoyaltyPeriod/types'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import { Color, ColorResult, SketchPicker } from 'react-color'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { grey } from '@mui/material/colors'
import { GreenSwitch } from '../../../../shared/GreenSwitch'

const EDITOR_MAX_LENGTH = 1000

type LoyaltyPlanDetailsProps = {
  planId: number | null
  setPlanId: React.Dispatch<React.SetStateAction<number | null>>
  planDetails?: LoyaltyPlan
}

const PlanDetails: FunctionComponent<LoyaltyPlanDetailsProps> = ({
  planId,
  setPlanId,
  planDetails,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [name, setName] = useState(planDetails?.name || '')
  const [shortName, setShortName] = useState(planDetails?.shortName || '')
  const [sequence, setSequence] = useState(planDetails?.sequence || '1')
  const [periodList, setPeriodList] = useState<LoyaltyPeriod[]>([])
  const [planTypes, setPlanTypes] = useState<LoyaltyPlanType[]>([])
  const [savingStep, setSavingStep] = useState(false)
  const [imageId, setImageId] = useState<number | null>(null)
  const [nameLength, setNameLength] = useState(planDetails?.name.length || 0)
  const [shortNameLength, setShortNameLength] = useState(
    planDetails?.shortName ? planDetails.shortName.length : 0,
  )
  const [validFromDate, setValidFromDate] = useState<Date | null>(null)
  const [validFromTime, setValidFromTime] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [validToDate, setValidToDate] = useState<Date | null>(null)
  const [validToTime, setValidToTime] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )
  const [period, setPeriod] = useState<string>(
    planDetails?.loyaltyPeriodId.toString() || '',
  )
  const [planType, setPlanType] = useState<string>(planDetails?.typeCode || '')
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false)
  const [colorValue, setColorValue] = useState<Color>(
    planDetails?.colorValue || '#000000',
  )
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  )
  const [description, setDescription] = useState<string | null>(null)
  const [descriptionLength, setDescriptionLength] = useState<number>(0)

  const [editableSwitch, setEditableSwitch] = useState(
    planDetails ? (planDetails.isEditable ? true : false) : true,
  )
  const [importKey, setImportKey] = useState<string | null>(null)
  const [importKeyLength, setImportKeyLength] = useState<number>(0)

  const MAX_NAME_LENGTH = 50
  const MAX_SHORT_NAME_LENGTH = 10

  const MAX_IMPORT_KEY_LENGTH = 7
  const MIN_IMPORT_KEY_LENGTH = 7

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultPlanDetailsFormErrors: LoyaltyPlanDetailsErrors = {
    loyaltyPeriodId: defaultError,
    typeCode: defaultError,
    sequence: defaultError,
    colorValue: defaultError,
    name: defaultError,
    shortName: defaultError,
    validFromDate: defaultError,
    validToDate: defaultError,
    description: defaultError,
    importKey: defaultError,
  }
  const [planDetailsFormErrors, setPlanDetailsFormErrors] =
    useState<LoyaltyPlanDetailsErrors>(defaultPlanDetailsFormErrors)

  const handlePeriodChange = async (event: SelectChangeEvent) => {
    setPeriod(event.target.value as string)
  }

  const handlePlanTypeChange = async (event: SelectChangeEvent) => {
    setPlanType(event.target.value as string)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    setNameLength(event.target.value.length)
  }

  const handleImportKeyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length <= MAX_IMPORT_KEY_LENGTH) {
      setImportKeyLength(event.target.value.trim().length)
      setImportKey(event.target.value.trim())
    }
  }

  const handleShortNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShortName(event.target.value)
    setShortNameLength(event.target.value.length)
  }

  const handleSequenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSequence(event.target.value)
  }

  const handleColorClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleColorClose = () => {
    setDisplayColorPicker(false)
  }

  const handleColorChange = (color: ColorResult) => {
    setColorValue(color.hex)
  }

  const handleEditableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableSwitch(event.target.checked)
  }

  useEffect(() => {
    setDescriptionLength(
      editorState.getCurrentContent().getPlainText('').length,
    )
    setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState])

  const onEditorStateChange = (currentEditorState: EditorState) => {
    const contentState = currentEditorState.getCurrentContent()
    const oldContent = editorState.getCurrentContent()
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= EDITOR_MAX_LENGTH
    ) {
      setEditorState(currentEditorState)
    } else {
      const newEditorState = EditorState.moveFocusToEnd(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character',
        ),
      )
      setEditorState(newEditorState)
    }
  }

  useEffect(() => {
    if (planDetails) {
      setValidFromDate(new Date(planDetails.validFrom.slice(0, 10)))
      setValidFromTime(
        new Date(
          new Date().setHours(
            parseInt(planDetails.validFrom.slice(11, 13)),
            parseInt(planDetails.validFrom.slice(14, 16)),
          ),
        ),
      )
      setValidToDate(new Date(planDetails.validTo.slice(0, 10)))
      setValidToTime(
        new Date(
          new Date().setHours(
            parseInt(planDetails.validTo.slice(11, 13)),
            parseInt(planDetails.validTo.slice(14, 16)),
          ),
        ),
      )
      if (planDetails.description) {
        const blocksFromHTML = htmlToDraft(planDetails.description)
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        )
        setEditorState(EditorState.createWithContent(content))
        setDescription(planDetails.description)
        setImportKey(planDetails.importKey)
        setImportKeyLength(
          planDetails.importKey ? planDetails.importKey.length : 0,
        )
      }
    }
  }, [planDetails])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const periodListResponse = await LoyaltyPeriodService.getPeriodList()

        if (periodListResponse.data.periods) {
          setPeriodList(periodListResponse.data.periods)
        }

        const planTypeListResponse = await LoyaltyPlanService.getPlanTypeList()

        if (planTypeListResponse.data.planTypes) {
          setPlanTypes(planTypeListResponse.data.planTypes)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t])

  const saveForm = async () => {
    setPlanDetailsFormErrors(defaultPlanDetailsFormErrors)
    const planDetailsFormErrors = defaultPlanDetailsFormErrors

    if (period === '') {
      setPlanDetailsFormErrors({
        ...planDetailsFormErrors,
        loyaltyPeriodId: {
          error: true,
          message: t('pages.loyaltyPlans.planDetails.form.periodRequired'),
        },
      })
      return
    } else if (planType === '') {
      setPlanDetailsFormErrors({
        ...planDetailsFormErrors,
        typeCode: {
          error: true,
          message: t('pages.loyaltyPlans.planDetails.form.typeCodeRequired'),
        },
      })
      return
    } else if (sequence === '') {
      setPlanDetailsFormErrors({
        ...planDetailsFormErrors,
        sequence: {
          error: true,
          message: t('pages.loyaltyPlans.planDetails.form.sequenceRequired'),
        },
      })
      return
    } else if (parseInt(sequence.toString()) <= 0) {
      setPlanDetailsFormErrors({
        ...planDetailsFormErrors,
        sequence: {
          error: true,
          message: t('pages.loyaltyPlans.planDetails.form.wrongSequence'),
        },
      })
      return
    } else if (name.trim() === '') {
      setPlanDetailsFormErrors({
        ...planDetailsFormErrors,
        name: {
          error: true,
          message: t('pages.loyaltyPlans.planDetails.form.nameRequired'),
        },
      })
      return
    } else if (
      !validFromDate ||
      !validFromTime ||
      validFromDate.toString() === 'Invalid Date' ||
      validFromTime.toString() === 'Invalid Date'
    ) {
      setPlanDetailsFormErrors({
        ...planDetailsFormErrors,
        validFromDate: {
          error: true,
          message: t('pages.loyaltyPlans.planDetails.form.dateRequired'),
        },
      })
      return
    } else if (
      !validToDate ||
      !validToTime ||
      validToDate.toString() === 'Invalid Date' ||
      validToTime.toString() === 'Invalid Date'
    ) {
      setPlanDetailsFormErrors({
        ...planDetailsFormErrors,
        validToDate: {
          error: true,
          message: t('pages.loyaltyPlans.planDetails.form.dateRequired'),
        },
      })
      return
    } else if (
      importKey &&
      (importKey.length < MIN_IMPORT_KEY_LENGTH ||
        importKey.length > MAX_IMPORT_KEY_LENGTH)
    ) {
      if (MIN_IMPORT_KEY_LENGTH === MAX_IMPORT_KEY_LENGTH) {
        setPlanDetailsFormErrors({
          ...planDetailsFormErrors,
          importKey: {
            error: true,
            message: t(
              'pages.loyaltyPlans.planDetails.form.importKeyLengthSingle',
              {
                importKeyLength: MIN_IMPORT_KEY_LENGTH,
              },
            ),
          },
        })
      } else {
        setPlanDetailsFormErrors({
          ...planDetailsFormErrors,
          importKey: {
            error: true,
            message: t(
              'pages.loyaltyPlans.planDetails.form.importKeyLengthRange',
              {
                importKeyMinLength: MIN_IMPORT_KEY_LENGTH,
                importKeyMaxLength: MAX_IMPORT_KEY_LENGTH,
              },
            ),
          },
        })
      }

      return
    } else if (descriptionLength === 0 || !description) {
      setPlanDetailsFormErrors({
        ...planDetailsFormErrors,
        description: {
          error: true,
          message: t('pages.loyaltyPlans.planDetails.form.descriptionRequired'),
        },
      })
      return
    }

    const validFrom =
      moment(validFromDate).format('YYYY-MM-DD') +
      ' ' +
      moment(validFromTime).format('HH:mm:ss')

    const validTo =
      moment(validToDate).format('YYYY-MM-DD') +
      ' ' +
      moment(validToTime).format('HH:mm:ss')

    const formParams: LoyaltyCreateOrUpdatePlanDetailsParams = {
      planId,
      loyaltyPeriodId: parseInt(period),
      typeCode: planType,
      sequence: parseInt(`${sequence}`),
      colorValue: colorValue.toString(),
      name,
      shortName: shortName.trim() === '' ? null : shortName,
      validFrom,
      validTo,
      description,
      imageId,
      isEditable: editableSwitch,
      importKey: importKey ? importKey : null,
    }

    try {
      setSavingStep(true)
      const createPlanDetailsResponse =
        await LoyaltyPlanService.createOrUpdatePlanDetails(formParams)

      if (createPlanDetailsResponse.data.success) {
        setPlanId(createPlanDetailsResponse.data.planId)
        toast.success(t('messages.success.savedSuccessfully'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSavingStep(false)
    }
  }

  const clearForm = async () => {
    setPlanDetailsFormErrors(defaultPlanDetailsFormErrors)
    setPeriod('')
    setPlanType('')
    setSequence('1')
    setColorValue('#000000')
    setName('')
    setShortName('')
    setImageId(null)
    setValidFromDate(null)
    setValidFromTime(new Date(new Date().setHours(0, 0, 0, 0)))
    setValidToDate(null)
    setValidToTime(new Date(new Date().setHours(23, 59, 59, 0)))
    setEditorState(EditorState.createEmpty())
    setDescription(null)
    setImportKey(null)
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <PlanContainer>
          <Stack direction={'row'}>
            <div className="step-line first">
              <div className="step-circle">1</div>
              <div className="step-arrow first"></div>
            </div>
            <Box className="form-container">
              <Grid container sx={{ marginBottom: 4 }}>
                <Grid item xs={6}>
                  <div className="step-title">
                    {t('pages.loyaltyPlans.planDetails.title')}
                  </div>
                  <div className="step-subtitle">
                    {t('pages.loyaltyPlans.planDetails.subtitle')}
                  </div>
                </Grid>
                <Grid item xs={6} className="switch-button">
                  <FormControlLabel
                    value="start"
                    control={
                      <GreenSwitch
                        value={editableSwitch}
                        onChange={handleEditableChange}
                        checked={editableSwitch}
                      />
                    }
                    label={
                      <div className="visibility-label-container">
                        <div className="visibility">
                          {t('pages.loyaltyPlans.planDetails.editable')}
                        </div>
                        <div className="visibility-label">
                          {t('pages.loyaltyPlans.planDetails.switchLabel')}
                        </div>
                      </div>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {planType === 'TP' && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      <Typography
                        variant="body2"
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: t('pages.loyaltyPlans.planDetails.info'),
                        }}
                      ></Typography>
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={4} mb={1}>
                  <FormControl
                    size="small"
                    fullWidth
                    error={planDetailsFormErrors.loyaltyPeriodId.error}
                  >
                    <label>
                      {t('pages.loyaltyPlans.planDetails.form.selectPeriod')}
                    </label>
                    <Select
                      id="period-select"
                      value={period}
                      onChange={handlePeriodChange}
                      style={{ background: '#fff' }}
                    >
                      {periodList.map((period) => (
                        <MenuItem value={period.id} key={period.id}>
                          {period.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {planDetailsFormErrors.loyaltyPeriodId.error && (
                      <FormHelperText>
                        {planDetailsFormErrors.loyaltyPeriodId.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4} mb={1}>
                  <FormControl
                    size="small"
                    fullWidth
                    error={planDetailsFormErrors.typeCode.error}
                  >
                    <label>
                      {t('pages.loyaltyPlans.planDetails.form.selectPlanType')}
                    </label>
                    <Select
                      id="plan-type-select"
                      value={planType}
                      onChange={handlePlanTypeChange}
                      style={{ background: '#fff' }}
                    >
                      {planTypes.map((planType) => (
                        <MenuItem value={planType.code} key={planType.id}>
                          {planType.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {planDetailsFormErrors.typeCode.error && (
                      <FormHelperText>
                        {planDetailsFormErrors.typeCode.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={2} mb={1}>
                  <FormControl fullWidth>
                    <label>
                      {t('pages.loyaltyPlans.planDetails.form.sequence')}
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={sequence}
                      onChange={handleSequenceChange}
                      error={planDetailsFormErrors.sequence.error}
                      helperText={
                        planDetailsFormErrors.sequence.error &&
                        planDetailsFormErrors.sequence.message
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} mb={1}>
                  <label>
                    {t('pages.loyaltyPlans.planDetails.form.colorValue')}
                  </label>
                  <br />
                  <FormControl fullWidth>
                    <div
                      style={{
                        padding: '5px',
                        background: '#fff',
                        borderRadius: '3px',
                        border: '1px solid #cbcbcb',
                        display: 'block',
                        cursor: 'pointer',
                      }}
                      onClick={handleColorClick}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '28px',
                          borderRadius: '2px',
                          background: `${colorValue}`,
                        }}
                      />
                    </div>
                    {displayColorPicker ? (
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                        }}
                      >
                        <div
                          style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }}
                          onClick={handleColorClose}
                        />
                        <SketchPicker
                          color={colorValue}
                          onChange={handleColorChange}
                          disableAlpha
                        />
                      </div>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <label>
                      {t('pages.loyaltyPlans.planDetails.form.name')}
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={name}
                      onChange={handleNameChange}
                      inputProps={{ maxLength: MAX_NAME_LENGTH }}
                      error={planDetailsFormErrors.name.error}
                      helperText={
                        planDetailsFormErrors.name.error &&
                        planDetailsFormErrors.name.message
                      }
                    />
                    <div className="character-amount">
                      {nameLength}/{MAX_NAME_LENGTH}
                    </div>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <label>
                      {t('pages.loyaltyPlans.planDetails.form.shortName')}
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={shortName}
                      onChange={handleShortNameChange}
                      inputProps={{ maxLength: MAX_SHORT_NAME_LENGTH }}
                    />
                    <div className="character-amount">
                      {shortNameLength}/{MAX_SHORT_NAME_LENGTH}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: 2 }} spacing={2}>
                <Grid item style={{ width: '100%', maxWidth: '265px' }}>
                  <DateTimePicker
                    label={t('pages.loyaltyPlans.planDetails.form.validFrom')}
                    date={validFromDate}
                    time={validFromTime}
                    onDateChange={setValidFromDate}
                    onTimeChange={setValidFromTime}
                    maxDate={validToDate || undefined}
                    error={planDetailsFormErrors.validFromDate}
                    style={{
                      width: 260,
                      marginRight: 8,
                    }}
                  />
                </Grid>
                <Grid item style={{ width: '100%', maxWidth: '265px' }}>
                  <DateTimePicker
                    label={t('pages.loyaltyPlans.planDetails.form.validTo')}
                    date={validToDate}
                    time={validToTime}
                    onDateChange={setValidToDate}
                    onTimeChange={setValidToTime}
                    minDate={validFromDate || undefined}
                    error={planDetailsFormErrors.validToDate}
                    style={{
                      width: 260,
                      marginRight: 8,
                    }}
                  />
                </Grid>
                <Grid item style={{ width: '100%', maxWidth: '217px' }}>
                  <FormControl fullWidth>
                    <label>{t('pages.plans.planDetails.form.importKey')}</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={importKey}
                      onChange={handleImportKeyChange}
                      inputProps={{ maxLength: MAX_IMPORT_KEY_LENGTH }}
                      error={planDetailsFormErrors.importKey.error}
                      helperText={
                        planDetailsFormErrors.importKey.error &&
                        planDetailsFormErrors.importKey.message
                      }
                    />
                    <div className="character-amount">
                      {importKeyLength}/{MAX_IMPORT_KEY_LENGTH}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: 2 }}>
                <Grid item xs={12}>
                  <label>
                    {t('pages.tradeActions.rules.form.description')}
                  </label>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                      options: ['inline', 'list', 'colorPicker', 'emoji'],
                      inline: {
                        options: [
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                        ],
                      },
                    }}
                  />
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <small style={{ color: grey[500] }}>
                      {descriptionLength} / {EDITOR_MAX_LENGTH}
                    </small>
                  </Stack>
                  {planDetailsFormErrors.description.error &&
                    descriptionLength === 0 && (
                      <FormHelperText error>
                        {planDetailsFormErrors.description.message}
                      </FormHelperText>
                    )}
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: 4 }}>
                <Grid item xs={12}>
                  <label>{t('pages.loyaltyPlans.planDetails.form.logo')}</label>
                  <ImageLibrary
                    imageType={ImageTypeCode.ML}
                    selectedImageId={planDetails?.imageId || null}
                    setSelectedImageId={setImageId}
                    showTitle={false}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginBottom: 1 }} />
              <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                width="100%"
                className="buttons-container"
              >
                <Stack spacing={2} direction="row">
                  <SecondaryButton disabled={savingStep} onClick={clearForm}>
                    {t('common.clear')}
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => {
                      saveForm()
                    }}
                    disabled={savingStep}
                  >
                    {t('common.save')}
                  </PrimaryButton>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </PlanContainer>
      )}
    </>
  )
}

export default PlanDetails
