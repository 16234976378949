import {
  DialogContent,
  DialogContentText,
  Typography,
  Grid,
  Dialog,
  Stack,
  DialogTitle,
  DialogActions,
  IconButton,
} from '@mui/material'
import { DetailsData } from '../../store/Details/types'
import LoadingSpinner from './LoadingSpinner'
import SecondaryButton from '../../styles/Buttons/SecondaryButton'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import UserProfileImage from './UserProfileImage'
type DetailsDialogProps = {
  userAvatarId?: number
  userFirstname?: string
  userLastname?: string
  detailsData: DetailsData
  open: boolean
  loading: boolean
  handleClose: (refresh: boolean) => void
}

const DetailsDialog: React.FunctionComponent<DetailsDialogProps> = ({
  userAvatarId,
  userFirstname,
  userLastname,
  detailsData,
  open,
  loading,
  handleClose,
}) => {
  const { t } = useTranslation()
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleClose(false)}
    >
      {loading && <LoadingSpinner />}
      {!loading && detailsData && (
        <>
          <DialogTitle style={{ minHeight: '90px' }}>
            <Stack flexDirection="row">
              {userAvatarId && userFirstname && userLastname && (
                <UserProfileImage
                  userId={userAvatarId}
                  firstname={userFirstname}
                  lastname={userLastname}
                  style={{
                    width: 50,
                    height: 50,
                  }}
                />
              )}
              <Stack justifyContent="center" style={{ marginLeft: '15px' }}>
                <Typography variant="caption">
                  {detailsData.title.label}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  component="div"
                >
                  {detailsData.title.value}
                </Typography>
              </Stack>
              <IconButton
                style={{ marginLeft: 'auto', height: '50px', width: '50px' }}
                onClick={() => handleClose(false)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {detailsData.data.map((item, index) => (
                <Grid
                  container
                  columnSpacing={2}
                  marginBottom={item.bottomSeparator ? 3 : 0}
                  key={index}
                >
                  <Grid item xs={4} textAlign="right">
                    <Typography
                      variant="body2"
                      gutterBottom
                      fontWeight="bold"
                      style={{ wordBreak: 'break-all' }}
                    >
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" gutterBottom>
                      {item.value ? item.value : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack flexDirection="row" justifyContent="flex-end" width="100%">
              <SecondaryButton onClick={() => handleClose(false)}>
                {t('common.close')}
              </SecondaryButton>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default DetailsDialog
