import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import StoreDiscountsService from '../../../../services/storeDiscounts.service'
import { DetailsData } from '../../../../store/Details/types'
import DetailsDialog from '../../../shared/DetailsDialog'

type StoresDiscountsDetailsDialogProps = {
  discountId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const StoresDiscountsDetailsDialog: React.FunctionComponent<
  StoresDiscountsDetailsDialogProps
> = ({ discountId, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [detailsParamsForDialog, setDetailsParamsForDialog] =
    useState<DetailsData>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const storesDiscountsDetailsResponse =
          await StoreDiscountsService.getStoresDiscountDetails(discountId)
        if (storesDiscountsDetailsResponse.data) {
          setDetailsParamsForDialog({
            title: {
              label: `${t(
                'pages.storesDiscounts.storesDiscountsDetailsDialog.id',
              )}: ${discountId.toString()}`,
              value: storesDiscountsDetailsResponse.data.discountName,
            },
            data: [
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.storeInternalId',
                ),
                value: storesDiscountsDetailsResponse.data.storeInternalId,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.storeCentralId',
                ),
                value: storesDiscountsDetailsResponse.data.storeCentralId,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.storeName',
                ),
                value: storesDiscountsDetailsResponse.data.storeName,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.city',
                ),
                value: storesDiscountsDetailsResponse.data.city,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.companyName',
                ),
                value: storesDiscountsDetailsResponse.data.companyName,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.countryName',
                ),
                value: storesDiscountsDetailsResponse.data.countryName,
                bottomSeparator: true,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.userFirstname',
                ),
                value: storesDiscountsDetailsResponse.data.userFirstname,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.userLastname',
                ),
                value: storesDiscountsDetailsResponse.data.userLastname,
                bottomSeparator: true,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.typeName',
                ),
                value: storesDiscountsDetailsResponse.data.typeName,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.discountValue',
                ),
                value: storesDiscountsDetailsResponse.data.discountValue,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.validTo',
                ),
                value: storesDiscountsDetailsResponse.data.validTo,
              },
              {
                label: t(
                  'pages.storesDiscounts.storesDiscountsDetailsDialog.isActive',
                ),
                value: storesDiscountsDetailsResponse.data.isActive
                  ? t('common.yes')
                  : t('common.no'),
              },
            ],
          })
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, discountId])

  return (
    <>
      {detailsParamsForDialog && (
        <DetailsDialog
          detailsData={detailsParamsForDialog}
          open={open}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default StoresDiscountsDetailsDialog
