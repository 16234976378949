import React, { useEffect, useState } from 'react'
import {
  Stack,
  Typography,
  FormControl,
  Grid,
  Alert,
  List,
  ListItem,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  styled,
} from '@mui/material'
import { MultiSelect } from 'react-multi-select-component'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import HomeService from '../../../../services/home.service'
import CompanyService from '../../../../services/company.service'
import { PeriodResult } from '../../../../store/Home/types'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Option } from '../../../../store/types'
import { thousandsSeparator } from '../../../../helpers/utils'

const PeriodPlanResultContainer = styled('div')({
  '.plan-item': {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    paddingLeft: 5,
  },
})

type BorderLinearProgressProps = LinearProgressProps & { barcolor?: string }

const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(
  ({ barcolor }) => ({
    marginTop: 3,
    height: 14,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: 'transparent',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: barcolor,
    },
  }),
)

const PercentageBadge = styled('div')({
  backgroundColor: '#f1f1f1',
  border: '1px solid #dcdcdc',
  borderRadius: '5px',
  fontSize: '75%',
  padding: '0px 4px',
  display: 'inline-block',
})

type PeriodPlanResultProps = {}

const PeriodPlanResult: React.FunctionComponent<PeriodPlanResultProps> = () => {
  const { t } = useTranslation()
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [periodResults, setPeriodResults] = useState<PeriodResult[]>([])

  const customValueRenderer = (selected: { label: any }[], _options: any) => {
    return selected.length
      ? t('multiSelect.selectedItems', { length: selected.length })
      : t('multiSelect.select')
  }

  const filterOptions = (options: any[], filter: string | RegExp) => {
    if (!filter) {
      return options
    }
    const re = new RegExp(filter, 'i')
    return options.filter(({ label }) => label && label.match(re))
  }

  const handleRegionsChange = async (companies: Option[]) => {
    setSelectedCompanies(companies)

    if (companies.length > 0) {
      const homePlanResultListResponse = await HomeService.getHomePlanResult(
        companies.map((company) => ({
          id: company.value,
        })),
      )
      if (homePlanResultListResponse.data.planResults) {
        setPeriodResults(homePlanResultListResponse.data.planResults)
      }
    } else {
      setPeriodResults([])
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          setSelectedCompanies(multiSelectOptions)
        }

        if (companyListResponse.data.companies.length > 0) {
          const homePlanResultListResponse =
            await HomeService.getHomePlanResult(
              companyListResponse.data.companies.map((company) => ({
                id: company.companyId,
              })),
            )
          if (homePlanResultListResponse.data.planResults) {
            setPeriodResults(homePlanResultListResponse.data.planResults)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <PeriodPlanResultContainer
          style={{
            background: '#f1f1f1',
            borderRadius: '3px',
            padding: '15px 20px',
            height: '100%',
            margin: '0 20px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div"
              fontWeight="bold"
            >
              {t('pages.home.periodPlanResult.title')}
            </Typography>
            <FormControl size="small" style={{ width: 300 }}>
              <label>{t('pages.home.periodPlanResult.selectCompanies')}</label>
              <MultiSelect
                options={companies}
                value={selectedCompanies}
                onChange={handleRegionsChange}
                labelledBy={t('multiSelect.labelledBy')}
                ClearSelectedIcon={<></>}
                filterOptions={filterOptions}
                overrideStrings={{
                  allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
                  clearSearch: t('multiSelect.clearSearch'),
                  noOptions: t('multiSelect.noOptions'),
                  search: t('multiSelect.search'),
                  selectAll: t('multiSelect.selectAll'),
                  selectAllFiltered: t('multiSelect.selectAllFiltered'),
                  selectSomeItems: t('multiSelect.selectSomeItems'),
                }}
                valueRenderer={customValueRenderer}
              />
            </FormControl>
          </Stack>
          <Grid container>
            {periodResults.filter((plan) => plan.isVisible).length === 0 && (
              <Grid item xs={12} mt={1}>
                <Alert severity="info" variant="filled">
                  {t('pages.home.periodPlanResult.noDataToDisplay')}
                </Alert>
              </Grid>
            )}
            {periodResults
              .filter((plan) => plan.isVisible)
              .map((plan) => (
                <Grid item xs={4} key={plan.periodId}>
                  <div style={{ background: '#f1f1f1', padding: '10px' }}>
                    {plan.periodName}
                    <List
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        padding: 0,
                        marginTop: '8px',
                      }}
                    >
                      {plan.results.map((p, i) => (
                        <ListItem
                          key={p.planId}
                          alignItems="center"
                          style={{
                            padding: '2px 6px',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                            minHeight: 50,
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{ marginTop: 'unset' }}
                          >
                            <Grid
                              item
                              xs={4}
                              sx={{ display: 'flex', alignItems: 'center' }}
                              className="plan-item"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="caption"
                                  component="div"
                                  letterSpacing={0}
                                >
                                  {p.planName}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sx={{ display: 'flex', alignItems: 'center' }}
                              className="plan-item"
                            >
                              <Grid container>
                                <Grid item xs={12}>
                                  <BorderLinearProgress
                                    variant="determinate"
                                    value={p.value >= 100 ? 100 : p.value}
                                    barcolor={p.colorValue}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    fontSize: 12,
                                    marginTop: '3px',
                                  }}
                                >
                                  <div>
                                    <strong>
                                      {t(
                                        'pages.home.periodPlanResult.resultValue',
                                      )}
                                    </strong>{' '}
                                    {thousandsSeparator(p.resultValue)}
                                  </div>
                                  <div>
                                    <strong>
                                      {t(
                                        'pages.home.periodPlanResult.planValue',
                                      )}
                                    </strong>{' '}
                                    {thousandsSeparator(p.planValue)}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              textAlign="right"
                              className="plan-item"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <PercentageBadge>
                                {p.value.toFixed(2)}%
                              </PercentageBadge>
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Grid>
              ))}
          </Grid>
        </PeriodPlanResultContainer>
      )}
    </>
  )
}

export default PeriodPlanResult
