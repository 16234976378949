import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, SelectChangeEvent, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ChallengesUsersToolbar from '../partials/ChallengesUsersToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { useParams } from 'react-router-dom'
import { Column, ColumnInstance } from 'react-table'
import {
  ChallengeFilter,
  ChallengeResult,
  ChallengeState,
  ChallengeTypes,
} from '../../../../store/Challenge/types'
import TableControlled from '../../../Table/TableControlled'
import PeriodService from '../../../../services/period.service'
import ChallengeService from '../../../../services/challenge.service'
import CompanyService from '../../../../services/company.service'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
  thousandsSeparator,
} from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import { User } from '../../../../store/Auth/types'
import { pick } from 'lodash'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'

type ChallengesUsersListProps = {
  path: string
  user: User
}

interface ParamTypes {
  challengeId?: string
}

const ChallengesUsersList: React.FC<ChallengesUsersListProps> = ({
  path,
  user,
}) => {
  let { challengeId } = useParams<ParamTypes>()
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'challengesUsers'

  const searchState = getTableState(tableName, 'search')
  const periodState = getTableState(tableName, 'period')
  const challengeTypeState = getTableState(tableName, 'challengeType')

  const [loading, setLoading] = useState<boolean>(true)
  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [periodValue, setPeriodValue] = useState<string>(
    periodState ? periodState : 'all',
  )
  const [statusValue, setStatusValue] = useState<string>('all')

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)

  const [filteredChallengeResultList, setFilteredChallengeResultList] =
    useState<ChallengeResult[]>([])
  const [periods, setPeriods] = useState<Period[]>([])
  const [statuses, setStatuses] = useState<ChallengeState[]>([])
  const [searchValue, setSearchValue] = useState<string>(
    searchState ? searchState : '',
  )
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [challengesFilter, setChallengesFilter] = useState<Option[]>([])
  const [challenges, setChallenges] = useState<ChallengeFilter[]>([])
  const [selectedChallengesFilter, setSelectedChallengesFilter] = useState<
    Option[]
  >([])

  const [challengeTypes, setChallengeTypes] = useState<ChallengeTypes[]>([])
  const [challengeTypeValue, setChallengeTypeValue] = useState<string>(
    challengeTypeState ? challengeTypeState : 'all',
  )

  const [difficultyLevelsFilter, setDifficultyLevelsFilter] = useState<
    Option[]
  >([])
  const [selectedDifficultyLevelsFilter, setSelectedDifficultyLevelsFilter] =
    useState<Option[]>([])

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])

  const generateTableColumns = useCallback(
    (challengesUsers: ChallengeResult[]) => {
      const columns = []
      columns.push(
        {
          Header: t('pages.challengesUsers.table.challengeId').toString(),
          accessor: 'challengeId',
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.challengesUsers.table.challengeName').toString(),
          accessor: 'challengeName',
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          Header: t(
            'pages.challengesUsers.table.challengeStateCode',
          ).toString(),
          accessor: 'challengeStateCode',
          width: 110,
          Cell: (params: any) =>
            t(`pages.challengesUsers.challengeStateCodes.${params.value}`),
        },
        {
          Header: t('pages.challengesUsers.table.periodName').toString(),
          accessor: 'periodName',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userCentralId',
          Header: t('pages.challengesUsers.table.userId').toString(),
          width: 90,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'firstname',
          Header: t('pages.challengesUsers.table.firstname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'lastname',
          Header: t('pages.challengesUsers.table.lastname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'email',
          Header: t('pages.challengesUsers.table.email').toString(),
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'companyName',
          Header: t('pages.challengesUsers.table.companyName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'regionName',
          Header: t('pages.challengesUsers.table.regionName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'challengeDifficultyLevelCode',
          Header: t(
            'pages.challengesUsers.table.challengeDifficultyLevel',
          ).toString(),
          width: 100,
          Cell: (params: any) =>
            t(
              `pages.challengesUsers.challengeDifficultyLevelCodes.${params.value}`,
            ),
        },
        {
          accessor: 'plan',
          Header: t('pages.challengesUsers.table.plan').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.challengesUsers.table.none')}
            </Stack>
          ),
        },
        {
          accessor: 'realization',
          Header: t('pages.challengesUsers.table.realization').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.challengesUsers.table.none')}
            </Stack>
          ),
        },
        {
          accessor: 'percentRealization',
          Header: t(
            'pages.challengesUsers.table.percentRealization',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value) + '%'
                : t('pages.challengesUsers.table.null')}
            </Stack>
          ),
        },
      )
      isXpPointsVisible() &&
        columns.push({
          accessor: 'xpAmount',
          Header: t('pages.challengesUsers.table.xpAmount').toString(),
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.challengesUsers.table.null')}
            </Stack>
          ),
        })
      isVpPointsVisible() &&
        columns.push({
          accessor: 'vpAmount',
          Header: t('pages.challengesUsers.table.vpAmount').toString(),
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.challengesUsers.table.null')}
            </Stack>
          ),
        })
      isEpPointsVisible() &&
        columns.push({
          accessor: 'epAmount',
          Header: t('pages.challengesUsers.table.epAmount').toString(),
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.challengesUsers.table.null')}
            </Stack>
          ),
        })

      return columns
    },
    [t],
  )

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)
          const page = ++pageIndex
          const response = await ChallengeService.getChallengeResultList(
            selectedCompanies.map((company) => ({ id: company.value })),
            periodValue === 'all'
              ? periods.map((period) => ({ id: period.id }))
              : [{ id: parseInt(periodValue) }],
            selectedChallengesFilter.map((challenge) => ({
              id: challenge.value,
            })),
            statusValue === 'all' ? null : statusValue,
            selectedDifficultyLevelsFilter.map((difficultyLevel) => ({
              id: difficultyLevel.value,
            })),
            challengeTypeValue === 'all' ? null : parseInt(challengeTypeValue),
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )

          if (response.data.challenges) {
            setTableColumns(generateTableColumns(response.data.challenges))

            setFilteredChallengeResultList(response.data.challenges)

            setTotalCount(response.data.totalCount)
            setPageCount(Math.ceil(response.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      statusValue,
      searchValue,
      generateTableColumns,
      selectedChallengesFilter,
      selectedCompanies,
      periods,
      selectedDifficultyLevelsFilter,
      challengeTypeValue,
    ],
  )

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)
        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          setSelectedCompanies(multiSelectOptions)
        }

        const challengesFilterResponse =
          await ChallengeService.getChallengeFilter()

        if (challengesFilterResponse.data.challenges) {
          setChallenges(challengesFilterResponse.data.challenges)
          const challengeStateListResponse =
            await ChallengeService.getChallengeStateList()
          if (challengeStateListResponse.data.challengeStates) {
            setStatuses(challengeStateListResponse.data.challengeStates)
            const periodListResponse = await PeriodService.getPeriodList()
            if (periodListResponse.data.periods) {
              setPeriods(periodListResponse.data.periods)
            }
          }
        }

        const challengeDifficultyLevelsResponse =
          await ChallengeService.getChallengeDifficultyLevel()
        if (challengeDifficultyLevelsResponse.data.difficultyLevels) {
          const multiSelectOptions: Option[] = []
          multiSelectOptions.push({
            value: null,
            label: t(
              'pages.challengesUsers.challengeDifficultyLevelCodes.null',
            ),
          })
          challengeDifficultyLevelsResponse.data.difficultyLevels.forEach(
            (difficultyLevel) =>
              multiSelectOptions.push({
                value: difficultyLevel.id,
                label: difficultyLevel.name,
              }),
          )
          setDifficultyLevelsFilter(multiSelectOptions)
          const difficultyLevelsState = getTableState(
            tableName,
            'difficultyLevels',
          )
          if (difficultyLevelsState) {
            setSelectedDifficultyLevelsFilter(JSON.parse(difficultyLevelsState))
          } else {
            setSelectedDifficultyLevelsFilter(multiSelectOptions)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
  }, [t, challengeId])

  useEffect(() => {
    const filterChallenges = async () => {
      const multiSelectOptions: Option[] = []
      if (periodValue === 'all') {
        challenges.forEach((challenge) =>
          multiSelectOptions.push({
            value: challenge.id,
            label: challenge.name,
          }),
        )
      } else {
        challenges
          .filter((challenge) => challenge.periodId === parseInt(periodValue))
          .forEach((challenge) =>
            multiSelectOptions.push({
              value: challenge.id,
              label: challenge.name,
            }),
          )
      }
      if (challengeId === 'all' || challengeId === undefined) {
        setSelectedChallengesFilter(multiSelectOptions)
      } else {
        setSelectedChallengesFilter(
          multiSelectOptions.filter(
            (option) => option.value === parseInt(challengeId!),
          ),
        )
      }
      setChallengesFilter(multiSelectOptions)
    }
    if (periodValue !== '' && challenges.length > 0) {
      filterChallenges()
    }
  }, [periodValue, challenges, challengeId])

  useEffect(() => {
    const filterData = async () => {
      try {
        const challengesTypesResponse =
          await ChallengeService.getChallengeTypes()

        if (challengesTypesResponse.data) {
          setChallengeTypes(challengesTypesResponse.data.challengeTypes)
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }

    filterData()
  }, [t])

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const response = await ChallengeService.getChallengeResultList(
        selectedCompanies.map((company) => ({ id: company.value })),
        periodValue === 'all'
          ? periods.map((period) => ({ id: period.id }))
          : [{ id: parseInt(periodValue) }],
        selectedChallengesFilter.map((challenge) => ({
          id: challenge.value,
        })),
        statusValue === 'all' ? null : statusValue,
        selectedDifficultyLevelsFilter.map((difficultyLevel) => ({
          id: difficultyLevel.value,
        })),
        challengeTypeValue === 'all' ? null : parseInt(challengeTypeValue),
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
      )

      const dataChallenges = response.data.challenges
      if (dataChallenges) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'challengeId',
            'periodName',
            'challengeName',
            'userCentralId',
            'email',
            'firstname',
            'lastname',
            'companyName',
            'regionName',
            'challengeDifficultyLevelCode',
            'plan',
            'realization',
            'percentRealization',
            ...(isXpPointsVisible() ? ['xpAmount'] : []),
            ...(isVpPointsVisible() ? ['vpAmount'] : []),
            ...(isEpPointsVisible() ? ['epAmount'] : []),
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredChallengeResultList = dataChallenges.map((challenge) => {
          challenge.challengeStateCode = t(
            `excel.challengesUsers.challengeStateCodes.${challenge.challengeStateCode}`,
          )
          challenge.challengeDifficultyLevelCode = t(
            `excel.challengesUsers.challengeDifficultyLevelCodes.${challenge.challengeDifficultyLevelCode}`,
          )
          challenge.plan =
            challenge.plan !== null
              ? challenge.plan
              : t('excel.challengesUsers.none').toString()

          challenge.realization =
            challenge.realization !== null
              ? challenge.realization
              : t('excel.challengesUsers.none').toString()

          challenge.percentRealization =
            challenge.percentRealization !== null
              ? challenge.percentRealization + '%'
              : t('excel.challengesUsers.null').toString()

          challenge.xpAmount =
            challenge.xpAmount !== null
              ? challenge.xpAmount
              : t('excel.challengesUsers.null').toString()

          challenge.vpAmount =
            challenge.vpAmount !== null
              ? challenge.vpAmount
              : t('excel.challengesUsers.null').toString()
          challenge.epAmount =
            challenge.epAmount !== null
              ? challenge.epAmount
              : t('excel.challengesUsers.null').toString()

          return pick(challenge, visibleColumns)
        })

        const translatedHeaders = {
          challengeId: t('excel.challengesUsers.challengeId'),
          challengeStateCode: t('excel.challengesUsers.challengeStateCode'),
          periodName: t('excel.challengesUsers.periodName'),
          challengeName: t('excel.challengesUsers.challengeName'),
          userCentralId: t('excel.challengesUsers.userId'),
          email: t('excel.challengesUsers.email'),
          firstname: t('excel.challengesUsers.firstname'),
          lastname: t('excel.challengesUsers.lastname'),
          companyName: t('excel.challengesUsers.companyName'),
          regionName: t('excel.challengesUsers.regionName'),
          challengeDifficultyLevelCode: t(
            'excel.challengesUsers.challengeDifficultyLevelCode',
          ),
          plan: t('excel.challengesUsers.plan'),
          realization: t('excel.challengesUsers.realization'),
          percentRealization: t('excel.challengesUsers.percentRealization'),
          xpAmount: t('excel.challengesUsers.xpAmount'),
          vpAmount: t('excel.challengesUsers.vpAmount'),
          epAmount: t('excel.challengesUsers.epAmount'),
        }

        const headers = [
          Object.keys(filteredChallengeResultList[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredChallengeResultList, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('challengesUsers')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>

          <ChallengesUsersToolbar
            periods={periods}
            statuses={statuses}
            periodValue={periodValue}
            challengeTypes={challengeTypes}
            challengeTypeValue={challengeTypeValue}
            value={searchText}
            companies={companies}
            selectedCompanies={selectedCompanies}
            challengesFilter={challengesFilter}
            selectedChallengesFilter={selectedChallengesFilter}
            difficultyLevelsFilter={difficultyLevelsFilter}
            selectedDifficultyLevelsFilter={selectedDifficultyLevelsFilter}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              setTableState(tableName, 'search', event.target.value)
              setControlledPageIndex(0)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            statusValue={statusValue}
            filterStatus={(event: SelectChangeEvent) => {
              setStatusValue(event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
              setTableState(tableName, 'period', event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            setSelectedCompanies={(companies: Option[]) => {
              setSelectedCompanies(companies)
              setSkipPageReset(false)
              setControlledPageIndex(0)
            }}
            setSelectedChallengesFilter={(challenges: Option[]) => {
              setSelectedChallengesFilter(challenges)
              setSkipPageReset(false)
              setControlledPageIndex(0)
            }}
            setSelectedDifficultyLevelsFilter={(difficultyLevels: Option[]) => {
              setSkipPageReset(false)
              setControlledPageIndex(0)
              setTableState(
                tableName,
                'difficultyLevels',
                JSON.stringify(difficultyLevels),
              )
              setSelectedDifficultyLevelsFilter(difficultyLevels)
            }}
            filterChallengeTypes={(event: SelectChangeEvent) => {
              setChallengeTypeValue(event.target.value)
              setTableState(tableName, 'challengeType', event.target.value)
              setControlledPageIndex(0)
              setSkipPageReset(false)
            }}
            user={user}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredChallengeResultList}
            height="calc(100vh - 330px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'challengeStateCode',
              'periodName',
              'email',
              'companyName',
              'regionName',
            ]}
            toggleVisibility={setColumnsVisibility}
            saveTableState={false}
          />
        </>
      )}
    </>
  )
}

export default ChallengesUsersList
