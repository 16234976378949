import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import LoyaltyUserService from '../../../../services/loyaltyUser.service'
import { DetailsData } from '../../../../store/Details/types'
import DetailsDialog from '../../../shared/DetailsDialog'

type LoyaltyUserAddDialogProps = {
  participantId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const LoyaltyUserAddDialog: React.FunctionComponent<
  LoyaltyUserAddDialogProps
> = ({ participantId, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [detailsParamsForDialog, setDetailsParamsForDialog] =
    useState<DetailsData>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const participantDetailsResponse =
          await LoyaltyUserService.getParticipantDetails(participantId)

        if (participantDetailsResponse.data) {
          setDetailsParamsForDialog({
            title: {
              label: `${t(
                'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.participantId',
              )}: ${participantId.toString()}`,
              value: `${participantDetailsResponse.data.firstname} ${participantDetailsResponse.data.lastname}`,
            },
            data: [
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.participantCentralId',
                ),
                value: participantDetailsResponse.data.participantCentralId,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.groupName',
                ),
                value: participantDetailsResponse.data.groupName,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.storeCentralId',
                ),
                value: participantDetailsResponse.data.storeCentralId,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.storeName',
                ),
                value: participantDetailsResponse.data.storeName,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.firstname',
                ),
                value: participantDetailsResponse.data.firstname,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.lastname',
                ),
                value: participantDetailsResponse.data.lastname,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.email',
                ),
                value: participantDetailsResponse.data.email,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.username',
                ),
                value: participantDetailsResponse.data.username,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.mobile',
                ),
                value: participantDetailsResponse.data.mobile,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.description',
                ),
                value: participantDetailsResponse.data.description,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.additionalDescription',
                ),
                value: participantDetailsResponse.data.additionalDescription,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.lastLoginDate',
                ),
                value: participantDetailsResponse.data.lastLoginDate,
              },
              {
                label: t(
                  'pages.loyaltyUsersprt.loyaltyParticipantDetailsDialog.isVisible',
                ),
                value: participantDetailsResponse.data.isVisible
                  ? t('common.yes')
                  : t('common.no'),
              },
            ],
          })
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, participantId])

  return (
    <>
      {detailsParamsForDialog && (
        <DetailsDialog
          detailsData={detailsParamsForDialog}
          open={open}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default LoyaltyUserAddDialog
