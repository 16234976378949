import React, { useCallback, useEffect, useState } from 'react'
import {
  Alert,
  CircularProgress,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import UsersPlansUpdateToolbar from '../partials/UsersPlansUpdateToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Column } from 'react-table'
import PeriodService from '../../../../services/period.service'
import UserPlanService from '../../../../services/userPlan.service'
import PlanService from '../../../../services/plan.service'
import CompanyService from '../../../../services/company.service'
import { thousandsSeparator } from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import { User } from '../../../../store/Auth/types'
import { UserIdValue, UserPlan } from '../../../../store/UserPlan/types'
import { PlanFilter } from '../../../../store/Plan/types'
import Table from '../../../Table/Table'
import PlanInput from '../partials/PlanInput'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { toast } from 'react-toastify'
import { isCompanyAdmin } from '../../../../helpers/checkRole'

type UsersPlansUpdateListProps = {
  path: string
  user: User
}

const UsersPlansUpdateList: React.FC<UsersPlansUpdateListProps> = ({
  path,
  user,
}) => {
  const { t } = useTranslation()
  const tableName = 'userPlansUpdate'
  const [loading, setLoading] = useState<boolean>(true)
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const [periodValue, setPeriodValue] = useState<string | null>(null)
  const [planValue, setPlanValue] = useState<PlanFilter | null>(null)
  const [companyValue, setCompanyValue] = useState<string | null>(null)

  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])

  const [filteredUsersPlansList, setFilteredUsersPlansList] = useState<
    UserPlan[]
  >([])
  const [periods, setPeriods] = useState<Period[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [companies, setCompanies] = useState<Option[]>([])
  const [plansFilter, setPlansFilter] = useState<PlanFilter[]>([])

  const [currentPlanSum, setCurrentPlanSum] = useState<number>(0)
  const [newPlanSum, setNewPlanSum] = useState<number>(0)
  const [usersPlans, setUsersPlans] = useState<UserIdValue[]>([])

  const updateUserPlanValues = (
    plans: UserIdValue[],
    value: string,
    userId: number,
  ) => {
    const foundUserPlan = plans.find((userPlan) => userPlan.userId === userId)
    if (foundUserPlan) {
      setUsersPlans((prevState) =>
        prevState.map((userPlan) => {
          if (userPlan.userId === userId) {
            return {
              userId,
              value: parseInt(value),
            }
          }
          return userPlan
        }),
      )
    }
  }

  useEffect(() => {
    setNewPlanSum(usersPlans.reduce((acc, obj) => acc + obj.value, 0))
  }, [usersPlans])

  const generateTableColumns = useCallback(
    (userPlans: UserPlan[], isEditable: boolean) => {
      const formattedUserPlans = userPlans.map((userPlan) => ({
        userId: userPlan.userId,
        value: userPlan.plan,
      }))
      const columns = []
      columns.push(
        {
          Header: t('pages.userPlansUpdate.table.planId').toString(),
          accessor: 'planId',
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.userPlansUpdate.table.periodName').toString(),
          accessor: 'periodName',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.userPlansUpdate.table.planName').toString(),
          accessor: 'planName',
          width: 220,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userCentralId',
          Header: t('pages.userPlansUpdate.table.userId').toString(),
          width: 90,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'email',
          Header: t('pages.userPlansUpdate.table.email').toString(),
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'firstname',
          Header: t('pages.userPlansUpdate.table.firstname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'lastname',
          Header: t('pages.userPlansUpdate.table.lastname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'companyName',
          Header: t('pages.userPlansUpdate.table.companyName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'plan',
          Header: t('pages.userPlansUpdate.table.plan').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.userPlansUpdate.table.none')}
            </Stack>
          ),
        },
        {
          accessor: 'realization',
          Header: t('pages.userPlansUpdate.table.realization').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.userPlansUpdate.table.none')}
            </Stack>
          ),
        },
        {
          accessor: 'percentRealization',
          Header: t(
            'pages.userPlansUpdate.table.percentRealization',
          ).toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true) + '%'
                : t('pages.userPlansUpdate.table.null')}
            </Stack>
          ),
        },
        {
          accessor: (data: any) => {
            return `plan-${data.userId}-${data.planId}`
          },
          Header: t('pages.userPlansUpdate.table.plan').toString(),
          width: 100,
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <PlanInput
              disabled={!isEditable}
              key={`input-${params.row.original.userId}-${params.row.original.planId}`}
              value={params.row.original.plan}
              setValue={(val) => {
                if (!isNaN(parseInt(val))) {
                  updateUserPlanValues(
                    formattedUserPlans,
                    val,
                    params.row.original.userId,
                  )
                } else {
                  updateUserPlanValues(
                    formattedUserPlans,
                    '0',
                    params.row.original.userId,
                  )
                }
              }}
            />
          ),
        },
      )

      return columns
    },
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const fetchData = useCallback(
    async (periodId: number, planId: number) => {
      if (periodValue && planValue && companyValue) {
        try {
          setIsTableLoading(true)
          const response = await UserPlanService.getUserPlansByPeriodAndPlan(
            [{ id: parseInt(companyValue) }],
            periodId,
            planId,
            searchValue,
            '',
            '',
            100000,
            1,
          )

          const userPlanList = response.data.userPlanList
          if (userPlanList) {
            setTableColumns(
              generateTableColumns(userPlanList, planValue.isEditable),
            )
            setFilteredUsersPlansList(userPlanList)

            const planSum = userPlanList.reduce((acc, obj) => acc + obj.plan, 0)
            setCurrentPlanSum(planSum)
            setNewPlanSum(planSum)
            setUsersPlans(
              userPlanList.map((userPlan) => ({
                userId: userPlan.userId,
                value: userPlan.plan,
              })),
            )
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setIsTableLoading(false)
        }
      }
    },
    [
      generateTableColumns,
      periodValue,
      searchValue,
      planValue,
      companyValue,
      t,
    ],
  )

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)

        const periodListResponse = await PeriodService.getPeriodList()
        let activePeriod = null

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
          activePeriod = periodListResponse.data.periods.find(
            (period) => period.isActive,
          )
          setPeriodValue(activePeriod ? activePeriod.id.toString() : null)
        }

        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          setCompanyValue(
            companyListResponse.data.companies[0].companyId.toString(),
          )
        }

        const plansFilterResponse = await PlanService.getPlanFilter(
          activePeriod?.id,
        )

        if (plansFilterResponse.data.plans) {
          setPlansFilter(plansFilterResponse.data.plans)
          const numOfPlans = plansFilterResponse.data.plans.length
          if (numOfPlans > 0) {
            const firstPlan = plansFilterResponse.data.plans[0]
            setPlanValue(firstPlan)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
  }, [t])

  useEffect(() => {
    const fetchPeriodPlans = async () => {
      try {
        const plansFilterResponse = await PlanService.getPlanFilter(
          parseInt(periodValue!),
        )

        if (plansFilterResponse.data.plans) {
          setPlansFilter(plansFilterResponse.data.plans)
          const numOfPlans = plansFilterResponse.data.plans.length
          if (numOfPlans > 0) {
            const firstPlan = plansFilterResponse.data.plans[0]
            setPlanValue(firstPlan)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
    if (periodValue) {
      fetchPeriodPlans()
    }
  }, [periodValue, t])

  useEffect(() => {
    if (periodValue && planValue) {
      fetchData(parseInt(periodValue), planValue.planId)
    }
  }, [periodValue, planValue, fetchData, searchValue])

  const saveUserPlans = async () => {
    if (planValue) {
      try {
        setIsSaving(true)

        const response = await UserPlanService.updateUserPlans({
          planId: planValue.planId,
          data: usersPlans,
        })

        if (response.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
          if (periodValue) {
            fetchData(parseInt(periodValue), planValue.planId)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setIsSaving(false)
      }
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          {periodValue && companyValue && (
            <UsersPlansUpdateToolbar
              periods={periods}
              periodValue={periodValue}
              plans={plansFilter}
              planValue={planValue}
              value={searchText}
              companies={companies}
              companyValue={companyValue}
              onChange={(event: { target: { value: string } }) => {
                setSearchText(event.target.value)
              }}
              submitSearch={(searchValue) => {
                setSearchValue(searchValue)
              }}
              clearSearch={() => {
                setSearchText('')
                setSearchValue('')
              }}
              filterPeriod={(event: SelectChangeEvent) => {
                setPeriodValue(event.target.value)
              }}
              filterPlan={(event: SelectChangeEvent) => {
                setPlanValue(
                  plansFilter.find(
                    (plan) => plan.planId === parseInt(event.target.value),
                  ) || null,
                )
              }}
              filterCompany={(event: SelectChangeEvent) => {
                setCompanyValue(event.target.value)
              }}
              user={user}
            />
          )}
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            pb={1}
            minHeight={44}
            marginBottom="-20px"
          >
            {filteredUsersPlansList.length > 0 &&
              planValue &&
              planValue?.isEditable && (
                <>
                  <Stack display="flex" flexDirection="row" alignItems="center">
                    <Typography variant="subtitle2">
                      {t('pages.userPlansUpdate.current')}:
                    </Typography>
                    <Alert
                      severity="info"
                      sx={{
                        padding: '0px 10px',
                        fontWeight: 'bold',
                        mr: 2,
                        ml: 1,
                      }}
                    >
                      {thousandsSeparator(currentPlanSum)}
                    </Alert>
                  </Stack>
                  <Stack display="flex" flexDirection="row" alignItems="center">
                    <Typography variant="subtitle2">
                      {t('pages.userPlansUpdate.new')}:
                    </Typography>
                    <Alert
                      severity={
                        isCompanyAdmin(user) && newPlanSum < currentPlanSum
                          ? 'error'
                          : 'success'
                      }
                      sx={{
                        padding: '0px 10px',
                        fontWeight: 'bold',
                        mr: 2,
                        ml: 1,
                      }}
                    >
                      {thousandsSeparator(newPlanSum)}
                    </Alert>
                  </Stack>
                  <PrimaryButton
                    size="small"
                    onClick={saveUserPlans}
                    disabled={
                      (isCompanyAdmin(user) && newPlanSum < currentPlanSum) ||
                      isSaving
                    }
                    style={{
                      padding: '4px 8px',
                    }}
                  >
                    {t('common.save')}
                    {isSaving && (
                      <CircularProgress
                        size={16}
                        color="primary"
                        style={{ marginLeft: '8px' }}
                      />
                    )}
                  </PrimaryButton>
                </>
              )}
            {planValue && !planValue?.isEditable && (
              <Alert
                severity="warning"
                sx={{
                  padding: '0px 10px',
                  fontWeight: 'bold',
                }}
              >
                {t('pages.userPlansUpdate.notEditable')}
              </Alert>
            )}
          </Stack>
          {planValue && (
            <Table
              name={tableName}
              columns={tableColumns}
              data={filteredUsersPlansList}
              height="calc(100vh - 330px)"
              loading={isTableLoading}
              columnsVisibility={[
                'periodName',
                'planName',
                'email',
                'companyName',
              ]}
            />
          )}
          {!planValue && (
            <Grid container>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: 20,
                  fontSize: 25,
                }}
              >
                {t('common.noData')}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default UsersPlansUpdateList
