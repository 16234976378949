import Map0Img from '../../assets/images/game/map/phone_preview/0.jpg'
import Map1Img from '../../assets/images/game/map/phone_preview/1.jpg'
import Map2Img from '../../assets/images/game/map/phone_preview/2.jpg'
import Map3Img from '../../assets/images/game/map/phone_preview/3.jpg'
import Map4Img from '../../assets/images/game/map/phone_preview/4.jpg'
import Map5Img from '../../assets/images/game/map/phone_preview/5.jpg'
import Map6Img from '../../assets/images/game/map/phone_preview/6.jpg'
import Map7Img from '../../assets/images/game/map/phone_preview/7.jpg'
import Map8Img from '../../assets/images/game/map/phone_preview/8.jpg'
import Map9Img from '../../assets/images/game/map/phone_preview/9.jpg'
import Map10Img from '../../assets/images/game/map/phone_preview/10.jpg'
import Map11Img from '../../assets/images/game/map/phone_preview/11.jpg'
import Map12Img from '../../assets/images/game/map/phone_preview/12.jpg'

export const backgroundsArray: string[] = [
  Map0Img,
  Map1Img,
  Map2Img,
  Map3Img,
  Map4Img,
  Map5Img,
  Map6Img,
  Map7Img,
  Map8Img,
  Map9Img,
  Map10Img,
  Map11Img,
  Map12Img,
]
