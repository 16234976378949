import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import ImportService from '../../../../services/import.service'
import { Stack } from '@mui/system'
import { Card, Grid, Typography } from '@mui/material'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { toast } from 'react-toastify'

const ImportFromFTP: React.FC = () => {
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const startFtpImport = async (importType: string) => {
    try {
      setIsSaving(true)
      let importFromFtpResponse
      switch (importType) {
        case 'participantLoyaltyPlanResult':
          importFromFtpResponse =
            await ImportService.importParticipantLoyaltyPlanResultFromFTP()
          break
      }

      if (importFromFtpResponse && importFromFtpResponse.data.success) {
        toast.success(t('messages.success.importSuccessful'))
      }
      if (importFromFtpResponse && !importFromFtpResponse.data.success) {
        toast.error(t('messages.success.importFailed'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Stack p={3}>
      <Card style={{ padding: '20px' }}>
        <Grid container>
          <Grid
            item
            md={12}
            sx={{
              fontSize: '20px',
              fontWeight: 'bolder',
              marginBottom: '20px',
            }}
          >
            {t('pages.configurationLoyalty.importFromFtp.title')}
          </Grid>
          <Grid item md={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <PrimaryButton
              style={{ marginRight: '10px', opacity: isSaving ? '0.3' : '1' }}
              onClick={() => {
                startFtpImport('participantLoyaltyPlanResult')
              }}
              disabled={isSaving}
            >
              {t('pages.configurationLoyalty.importFromFtp.startImportLabel')}
            </PrimaryButton>
            <Typography variant="subtitle2" gutterBottom>
              {t(
                'pages.configurationLoyalty.importFromFtp.importParticipantLoyaltyPlanResult',
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Stack>
  )
}

export default ImportFromFTP
