import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { wrapSvgText, thousandsSeparator } from '../../../../../helpers/utils'
import './CompanyChart.css'
import { ChallengeAnalyticsCompanies } from '../../../../../store/Challenge/types'
import { User } from '../../../../../store/Auth/types'

type CompanyChartProps = {
  chartData: ChallengeAnalyticsCompanies[]
  user: User
}

const CompanyChart: React.FunctionComponent<CompanyChartProps> = ({
  chartData,
  user,
}) => {
  const chartEl = useRef<SVGSVGElement>(null)

  const width = chartData.length * 80
  const height = 260
  const barColor = '#4e79a7'
  const asmBarColor = '#61aa0d'

  useEffect(() => {
    if (chartData.length > 0) {
      setTimeout(() => {
        const margin = { top: 50, right: 20, bottom: 40, left: 20 }

        const x = (d: ChallengeAnalyticsCompanies) => d.companyName // given d in data, returns the (ordinal) x-value
        const y = (d: ChallengeAnalyticsCompanies) => d.percentRealization // given d in data, returns the (quantitative) y-value

        // Compute values.
        const X = d3.map(chartData, x)
        const Y = d3.map(chartData, y)

        // Compute default domains, and unique the x- and z-domains.
        const xDomain = new d3.InternSet(X)
        const yDomain = [0, d3.max(Y) ?? 0]

        const xRange = [margin.left, width - margin.right]
        const yRange = [height - margin.bottom, margin.top]
        const xPadding = 0.5

        const yType = d3.scaleLinear

        const xScale = d3.scaleBand(xDomain, xRange).paddingInner(xPadding)
        const yScale = yType(yDomain, yRange)
        const xAxis = d3.axisBottom(xScale).tickSizeOuter(0)

        // Omit any data not present in both the x- and z-domain.
        const I = d3.range(X.length).filter((i) => xDomain.has(X[i]))

        // Compute titles.
        const formatValue = yScale.tickFormat(100)
        const title = (i: number) => `${X[i]}\n${formatValue(Y[i])}`

        const svg = d3.select(chartEl.current)

        svg
          .attr('width', width)
          .attr('height', height)
          .attr('viewBox', `0 0 ${width} ${height}`)
          .attr('style', 'height: auto; height: intrinsic;')

        svg.selectAll('*').remove()

        svg
          .append('g')
          .attr('transform', `translate(${margin.left},0)`)
          .call((g) => g.select('.domain').remove())

        const bar = svg
          .append('g')
          .selectAll('rect')
          .data(I)
          .join('rect')
          .attr('x', (i) => xScale(X[i]) ?? 0)
          .attr('y', (i) => yScale(Y[i]))
          .attr('width', xScale.bandwidth())
          .attr('height', (i) => yScale(0) - yScale(Y[i]))
          .attr('fill', (i) => {
            const companyId = chartData.find(
              (cd) => cd.companyName === X[i],
            )?.companyId
            return companyId === user.companyId ? asmBarColor : barColor
          })
          .attr('rx', 2)
          .attr('ry', 2)

        if (title) bar.append('title').text(title)

        svg
          .append('g')
          .attr('class', 'bar-label-group')
          .selectAll('.bar-label')
          .data(I)
          .join('text')
          .attr('width', xScale.bandwidth())
          .attr(
            'transform',
            (d, i) =>
              `translate(${(xScale(X[i]) ?? 0) + xScale.bandwidth() / 2},${
                yScale(Y[i]) - 10
              })`,
          )
          .attr('dominant-baseline', 'middle')
          .attr('text-anchor', 'middle')
          .text((i) => `${thousandsSeparator(Y[i])}`)

        svg
          .append('g')
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .call(xAxis)
          .selectAll('.tick text')
          .call((g) => wrapSvgText(g, xScale.bandwidth(), d3))

        svg.selectAll('.tick line').attr('y2', '0')
      }, 0)
    }
  }, [chartData, width, user.companyId])

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      {chartData.length > 0 && (
        <div style={{ overflowY: 'auto' }}>
          <svg ref={chartEl} />
        </div>
      )}
    </div>
  )
}

export default CompanyChart
