import axios from 'axios'
import {
  ManufacturerDetailsResponse,
  ManufacturerEditParams,
  ManufacturerEditResponse,
  ManufacturerListResponse,
  ManufacturersResponse,
} from '../store/Manufacturer/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getManufacturerList = (): Promise<ManufacturerListResponse> => {
  return axios.get(API_URL + '/api/admin/manufacturer/list', {
    headers: authHeader(),
  })
}

const getManufacturers = async (
  isActive: boolean | null,
  isVisible: boolean | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<ManufacturersResponse> => {
  return await axios.post(
    API_URL + '/api/admin/manufacturer/list',
    {
      isActive,
      isVisible,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const editManufacturer = async (
  params: ManufacturerEditParams,
): Promise<ManufacturerEditResponse> => {
  return await axios.post(
    API_URL + '/api/admin/manufacturer/edit',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const getManufacturerDetails = (
  manufacturerId: number,
): Promise<ManufacturerDetailsResponse> => {
  return axios.get(
    API_URL + `/api/admin/manufacturer/preview/${manufacturerId}`,
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getManufacturerList,
  getManufacturers,
  editManufacturer,
  getManufacturerDetails,
}

export default exportedObject
