import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { styled } from '@mui/material/styles'

export const BottomBarContainer = styled('div')(({ theme }) => ({
  '.bottomBar-buttons': {
    textDecoration: 'none !important',
  },
}))

export default function BottomBar() {
  const { t } = useTranslation()

  return (
    <BottomBarContainer
      style={{
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 65,
        maxWidth: 'calc(100% - 71px)',
        marginLeft: '-20px',
        backgroundColor: '#000',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <Link to="/loyalty-plans" className="bottomBar-buttons">
        <SecondaryButton>{t('common.back')}</SecondaryButton>
      </Link>
    </BottomBarContainer>
  )
}
