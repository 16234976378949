import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, Grid, SelectChangeEvent, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import StoresPlansToolbar from '../partials/StoresPlansToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Column, ColumnInstance } from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import PeriodService from '../../../../services/period.service'
import StorePlanService from '../../../../services/storePlan.service'
import PlanService from '../../../../services/plan.service'
import CompanyService from '../../../../services/company.service'
import UserService from '../../../../services/user.service'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
  thousandsSeparator,
} from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import { User } from '../../../../store/Auth/types'
import { pick } from 'lodash'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { StorePlan } from '../../../../store/StorePlan/types'
import { PlanFilter } from '../../../../store/Plan/types'
import { UserFilter } from '../../../../store/User/types'

type StoresPlansListProps = {
  path: string
  user: User
}

const StoresPlansList: React.FC<StoresPlansListProps> = ({ path, user }) => {
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'storePlans'

  const [loading, setLoading] = useState<boolean>(true)
  const [searchText, setSearchText] = useState<string>('')
  const [periodValue, setPeriodValue] = useState<string | null>(null)

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [filteredStoresPlansList, setFilteredStoresPlansList] = useState<
    StorePlan[]
  >([])
  const [periods, setPeriods] = useState<Period[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [plansFilter, setPlansFilter] = useState<PlanFilter[]>([])
  const [planValue, setPlanValue] = useState<PlanFilter | null>(null)
  const [users, setUsers] = useState<UserFilter[]>([])
  const [filteredUsers, setFilteredUsers] = useState<Option[]>([])
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([])

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])

  const generateTableColumns = useCallback(
    (storePlans: StorePlan[]) => {
      const columns = []
      columns.push(
        {
          Header: t('pages.storePlans.table.planId').toString(),
          accessor: 'planId',
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.storePlans.table.periodName').toString(),
          accessor: 'periodName',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.storePlans.table.planName').toString(),
          accessor: 'planName',
          width: 220,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userCentralId',
          Header: t('pages.storePlans.table.userId').toString(),
          width: 90,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'email',
          Header: t('pages.storePlans.table.email').toString(),
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'firstname',
          Header: t('pages.storePlans.table.firstname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'lastname',
          Header: t('pages.storePlans.table.lastname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'companyName',
          Header: t('pages.storePlans.table.companyName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'regionName',
          Header: t('pages.storePlans.table.regionName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeId',
          Header: t('pages.storePlans.table.storeId').toString(),
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeCentralId',
          Header: t('pages.storePlans.table.storeCentralId').toString(),
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeInternalId',
          Header: t('pages.storePlans.table.storeInternalId').toString(),
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'storeName',
          Header: t('pages.storePlans.table.storeName').toString(),
          width: 200,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'city',
          Header: t('pages.storePlans.table.city').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'street',
          Header: t('pages.storePlans.table.street').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'isVisible',
          Header: t('pages.storePlans.table.isVisible').toString(),
          width: 140,
          Cell: (params: any) =>
            params.value ? <>{t('common.yes')}</> : <>{t('common.no')}</>,
        },
        {
          accessor: 'isActive',
          Header: t('pages.storePlans.table.isActive').toString(),
          width: 140,
          Cell: (params: any) =>
            params.value ? <>{t('common.yes')}</> : <>{t('common.no')}</>,
        },
        {
          accessor: 'planValue',
          Header: t('pages.storePlans.table.planValue').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.storePlans.table.none')}
            </Stack>
          ),
        },
        {
          accessor: 'resultValue',
          Header: t('pages.storePlans.table.resultValue').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true)
                : t('pages.storePlans.table.none')}
            </Stack>
          ),
        },
        {
          accessor: 'resultPercentValue',
          Header: t('pages.storePlans.table.resultPercentValue').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true) + '%'
                : t('pages.storePlans.table.null')}
            </Stack>
          ),
        },
        {
          accessor: 'forecastValue',
          Header: t('pages.storePlans.table.forecastValue').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true)
                : t('pages.storePlans.table.none')}
            </Stack>
          ),
        },
        {
          accessor: 'forecastPercentValue',
          Header: t('pages.storePlans.table.forecastPercentValue').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true) + '%'
                : t('pages.storePlans.table.null')}
            </Stack>
          ),
        },
      )

      return columns
    },
    [t],
  )

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current && periodValue && planValue) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)
          const page = ++pageIndex
          const response = await StorePlanService.getStorePlans(
            selectedUsers.map((user) => ({ id: user.value })),
            selectedCompanies.map((company) => ({ id: company.value })),
            periodValue === 'all'
              ? periods.map((period) => ({ id: period.id }))
              : [{ id: parseInt(periodValue) }],
            [{ id: planValue.planId }],
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
            null,
            null,
          )

          if (response.data.storePlans) {
            setTableColumns(generateTableColumns(response.data.storePlans))

            setFilteredStoresPlansList(response.data.storePlans)

            setTotalCount(response.data.totalCount)
            setPageCount(Math.ceil(response.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    [t, searchValue, generateTableColumns, periods, planValue, selectedUsers], // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)

        const periodListResponse = await PeriodService.getPeriodList()
        let activePeriod = null

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
          activePeriod = periodListResponse.data.periods.find(
            (period) => period.isActive,
          )
          setPeriodValue(activePeriod ? activePeriod.id.toString() : null)
        }

        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          const selectedCompaniesState = getTableState(
            tableName,
            'selectedCompaniesState',
          )
          if (selectedCompaniesState) {
            setSelectedCompanies(JSON.parse(selectedCompaniesState))
          } else {
            setSelectedCompanies(multiSelectOptions)
          }
        }

        const plansFilterResponse = await PlanService.getPlanFilter(
          activePeriod?.id,
        )

        if (plansFilterResponse.data.plans) {
          const filteredPlansFilterResponse =
            plansFilterResponse.data.plans.filter(
              (item) => item.typeCode !== 'UP',
            )
          setPlansFilter(filteredPlansFilterResponse)
          const numOfPlans = filteredPlansFilterResponse.length
          if (numOfPlans > 0) {
            const firstPlan = filteredPlansFilterResponse[0]
            setPlanValue(firstPlan)
          }
        }

        const userListResponse = await UserService.getUserListFilter()

        if (userListResponse.data.users) {
          const multiSelectOptions: Option[] = []
          userListResponse.data.users.forEach((user) =>
            multiSelectOptions.push({
              value: user.id,
              label: `${user.lastname} ${user.firstname}`,
            }),
          )
          setUsers(userListResponse.data.users)
          const selectedUsersState = getTableState(
            tableName,
            'selectedUsersState',
          )
          if (selectedUsersState) {
            setSelectedUsers(JSON.parse(selectedUsersState))
          } else {
            setSelectedUsers(multiSelectOptions)
          }

          const filteredUsersState = getTableState(
            tableName,
            'filteredUsersState',
          )
          if (filteredUsersState) {
            setFilteredUsers(JSON.parse(filteredUsersState))
          } else {
            setFilteredUsers(multiSelectOptions)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
  }, [t])

  useEffect(() => {
    const fetchPeriodPlans = async () => {
      try {
        const plansFilterResponse = await PlanService.getPlanFilter(
          parseInt(periodValue!),
        )

        if (plansFilterResponse.data.plans) {
          const filteredPlansFilterResponse =
            plansFilterResponse.data.plans.filter(
              (item) => item.typeCode !== 'UP',
            )
          setPlansFilter(filteredPlansFilterResponse)
          const numOfPlans = filteredPlansFilterResponse.length
          if (numOfPlans > 0) {
            const firstPlan = filteredPlansFilterResponse[0]
            setPlanValue(firstPlan)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
    if (periodValue) {
      fetchPeriodPlans()
    }
  }, [periodValue, t])

  const handleSelectedCompaniesChange = (companies: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setSelectedCompanies(companies)
    setTableState(
      tableName,
      'selectedCompaniesState',
      JSON.stringify(companies),
    )

    const filteredUsersForMultiselect = users
      .filter((user) =>
        companies.map((company) => company.value).includes(user.companyId),
      )
      .map((u) => ({
        value: u.id,
        label: `${u.lastname} ${u.firstname}`,
      }))
    setFilteredUsers(filteredUsersForMultiselect)
    setTableState(
      tableName,
      'filteredUsersState',
      JSON.stringify(filteredUsersForMultiselect),
    )
    setSelectedUsers(filteredUsersForMultiselect)
    setTableState(
      tableName,
      'selectedUsersState',
      JSON.stringify(filteredUsersForMultiselect),
    )
  }
  const handleSelectedUsersChange = (users: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setSelectedUsers(users)
    setTableState(tableName, 'selectedUsersState', JSON.stringify(users))
  }

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    if (periodValue && planValue) {
      try {
        setIsDownloading(true)

        const response = await StorePlanService.getStorePlans(
          selectedUsers.map((user) => ({ id: user.value })),
          selectedCompanies.map((company) => ({ id: company.value })),
          periodValue === 'all'
            ? periods.map((period) => ({ id: period.id }))
            : [{ id: parseInt(periodValue) }],
          [{ id: planValue.planId }],
          searchValue,
          downloadSortBy,
          downloadSortOrder,
          100000,
          1,
          null,
          null,
        )

        const dataStorePlans = response.data.storePlans
        if (dataStorePlans) {
          // remove hidden columns for xlsx
          let visibleColumns = columnsVisibility
            .filter((col) => col.isVisible)
            .map((col2) => col2.id)

          if (visibleColumns.length === 0) {
            visibleColumns = [
              'planId',
              'periodName',
              'planName',
              'userCentralId',
              'email',
              'firstname',
              'lastname',
              'companyName',
              'regionName',
              'storeId',
              'storeCentralId',
              'storeInternalId',
              'storeName',
              'city',
              'street',
              'isVisible',
              'isActive',
              'planValue',
              'resultValue',
              'resultPercentValue',
              'forecastValue',
              'forecastPercentValue',
            ]
          }

          const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
          visibleColumns = visibleColumns.filter(
            (c) => !hiddenColumns.includes(c),
          )

          const filteredStoresPlansList = dataStorePlans.map((plan) => {
            plan.isVisible = plan.isVisible
              ? t('common.yes').toString()
              : t('common.no').toString()
            plan.isActive = plan.isActive
              ? t('common.yes').toString()
              : t('common.no').toString()
            return pick(plan, visibleColumns)
          })

          const translatedHeaders = {
            planId: t('excel.storePlans.planId'),
            periodName: t('excel.storePlans.periodName'),
            planName: t('excel.storePlans.planName'),
            userCentralId: t('excel.storePlans.userId'),
            email: t('excel.storePlans.email'),
            firstname: t('excel.storePlans.firstname'),
            lastname: t('excel.storePlans.lastname'),
            companyName: t('excel.storePlans.companyName'),
            regionName: t('excel.storePlans.regionName'),
            storeId: t('excel.storePlans.storeId'),
            storeCentralId: t('excel.storePlans.storeCentralId'),
            storeInternalId: t('excel.storePlans.storeInternalId'),
            storeName: t('excel.storePlans.storeName'),
            city: t('excel.storePlans.city'),
            street: t('excel.storePlans.street'),
            isVisible: t('excel.storePlans.isVisible'),
            isActive: t('excel.storePlans.isActive'),
            planValue: t('excel.storePlans.planValue'),
            resultValue: t('excel.storePlans.resultValue'),
            resultPercentValue: t('excel.storePlans.resultPercentValue'),
            forecastValue: t('excel.storePlans.forecastValue'),
            forecastPercentValue: t('excel.storePlans.forecastPercentValue'),
          }

          const headers = [
            Object.keys(filteredStoresPlansList[0]).map(
              (key) => (translatedHeaders as any)[key],
            ),
          ]

          //Had to create a new workbook and then add the header
          const ws: XLSX.WorkSheet = XLSX.utils.book_new()
          XLSX.utils.sheet_add_aoa(ws, headers)

          //Starting in the second row to avoid overriding and skipping headers
          XLSX.utils.sheet_add_json(ws, filteredStoresPlansList, {
            origin: 'A2',
            skipHeader: true,
          })

          // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
          const wb: XLSX.WorkBook = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, name)

          XLSX.writeFile(wb, fileName)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setIsDownloading(false)
      }
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('storePlans')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>

          {periodValue && (
            <StoresPlansToolbar
              periods={periods}
              periodValue={periodValue}
              value={searchText}
              companies={companies}
              selectedCompanies={selectedCompanies}
              users={filteredUsers}
              selectedUsers={selectedUsers}
              plans={plansFilter}
              planValue={planValue}
              filterPlan={(event: SelectChangeEvent) => {
                setPlanValue(
                  plansFilter.find(
                    (plan) => plan.planId === parseInt(event.target.value),
                  ) || null,
                )
                setSkipPageReset(false)
              }}
              onChange={(event: { target: { value: string } }) => {
                setSearchText(event.target.value)
              }}
              submitSearch={(searchValue) => {
                setSkipPageReset(false)
                setSearchValue(searchValue)
              }}
              clearSearch={() => {
                setSkipPageReset(false)
                setSearchText('')
                setSearchValue('')
              }}
              filterPeriod={(event: SelectChangeEvent) => {
                setPeriodValue(event.target.value)
              }}
              setSelectedCompanies={(companies: Option[]) => {
                handleSelectedCompaniesChange(companies)
              }}
              setSelectedUsers={(users: Option[]) => {
                handleSelectedUsersChange(users)
              }}
              user={user}
            />
          )}
          {planValue && (
            <TableControlled
              name={tableName}
              columns={tableColumns}
              data={filteredStoresPlansList}
              height="calc(100vh - 330px)"
              fetchData={fetchData}
              loading={tableLoading}
              pageIndex={controlledPageIndex}
              pageCount={pageCount}
              totalCount={totalCount}
              skipPageReset={skipPageReset}
              columnsVisibility={[
                'periodName',
                'planName',
                'userId',
                'email',
                'firstname',
                'lastname',
                'companyName',
                'regionName',
                'forecastValue',
                'forecastPercentValue',
              ]}
              toggleVisibility={setColumnsVisibility}
              saveTableState={false}
            />
          )}
          {!planValue && (
            <Grid container>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: 20,
                  fontSize: 25,
                }}
              >
                {t('common.noData')}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default StoresPlansList
