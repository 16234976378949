import axios from 'axios'
import authHeader from './authHeader'
import { LoyaltyParticipantPlansListReponse } from '../store/LoyaltyParticipantPlans/types'

const API_URL = process.env.REACT_APP_API_URL

const getLoyaltyParticipantPlansList = async (
  loyaltyPeriodId: number | null,
  participantCentralId: string | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<LoyaltyParticipantPlansListReponse> => {
  return axios.post(
    API_URL + '/api/admin/loyalty/participant/plan/summary',
    {
      loyaltyPeriodId,
      participantCentralId,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getLoyaltyParticipantPlansList,
}

export default exportedObject
