import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  CircularProgress,
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import LoyaltyPeriodService from '../../../../services/loyaltyPeriod.service'
import LoyaltyPlanService from '../../../../services/loyaltyPlan.service'
import { useTranslation } from 'react-i18next'
import LoyaltyPlansToolbar from '../partials/LoyaltyPlansToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Column, ColumnInstance } from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import { pick } from 'lodash'
import {
  getHiddenColumns,
  getTableState,
  handleNavigationClick,
  setTableState,
} from '../../../../helpers/utils'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import LoyaltyPlanDetailsDialog from '../partials/LoyaltyPlanDetailsDialog'
import { LoyaltyPeriod } from '../../../../store/LoyaltyPeriod/types'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import { ReactComponent as UserImportPlan } from '../../../../assets/images/icons/user_import_plan.svg'
import { ReactComponent as UserImportPlanRed } from '../../../../assets/images/icons/user_import_plan_red.svg'
import { ReactComponent as UserImportResult } from '../../../../assets/images/icons/user_import_result.svg'
import { ReactComponent as UserImportResultRed } from '../../../../assets/images/icons/user_import_result_red.svg'
import { isCentralAdmin, isSuperAdmin } from '../../../../helpers/checkRole'
import { RootStateOrAny, useSelector } from 'react-redux'
import { LoyaltyPlan } from '../../../../store/LoyaltyPlan/types'
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'
import ImportDialog from '../../../shared/ImportDialog'
import {
  isLpLoyaltyPointsVisible,
  isVpLoyaltyPointsVisible,
  isXpLoyaltyPointsVisible,
} from '../../../../helpers/environment'
import PercentIcon from '@mui/icons-material/Percent'

type PlansListProps = {
  path: string
}

const LoyaltyPlansList: FunctionComponent<PlansListProps> = ({ path }) => {
  const tableName = 'loyaltyPlans'
  const searchState = getTableState(tableName, 'search')
  const periodState = getTableState(tableName, 'period')

  const { t } = useTranslation()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredPlansList, setFilteredPlansList] = useState<LoyaltyPlan[]>([])
  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [searchValue, setSearchValue] = useState<string>(
    searchState ? searchState : '',
  )
  const [periodValue, setPeriodValue] = useState<string>(
    periodState ? periodState : 'all',
  )
  const [periods, setPeriods] = useState<LoyaltyPeriod[]>([])
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])

  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const fetchIdRef = useRef(0)

  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])

  const [openPeriodPlanDetailsDialog, setPeriodPlanDetailsDialogOpen] =
    useState(false)
  const [planId, setPlanId] = useState<number | null>(null)
  const [refresh, setRefresh] = useState(false)
  const [importName, setImportName] = useState<string | null>(null)
  const [importTitle, setImportTitle] = useState<string>()
  const [openImportDialog, setImportDialogOpen] = useState(false)

  const handleImportDialogClickOpen = (
    name: string,
    planId: number,
    planName: string,
    periodName: string,
  ) => {
    switch (name) {
      case 'participant_plan_results':
        setImportTitle(
          `Zasilanie wyników planu: ${planName} (period: ${periodName})`,
        )
        break
      case 'participant_plans':
        setImportTitle(
          `Zasilanie wartości planów uczestników dla planu: ${planName} (period: ${periodName})`,
        )
        break
      case 'participant_plan_target':
        setImportTitle(
          `Zasilanie targetów dla planu: ${planName} (period: ${periodName})`,
        )
        break

      default:
        break
    }
    setPlanId(planId)
    setImportName(name)
    setImportDialogOpen(true)
  }

  const handleImportDialogClose = () => {
    setImportDialogOpen(false)
  }

  const generateTableColumns = useCallback(
    (plans: LoyaltyPlan[]) => {
      const columns = []
      columns.push(
        {
          accessor: 'planId',
          Header: t('pages.loyaltyPlans.table.planId').toString(),
          width: 90,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.loyaltyPlans.table.periodName').toString(),
          accessor: 'periodName',
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'name',
          Header: t('pages.loyaltyPlans.table.name').toString(),
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'validFrom',
          Header: t('pages.loyaltyPlans.table.validFrom').toString(),
          width: 100,
          Cell: (params: any) => params.value.substring(0, 10),
        },
        {
          accessor: 'validTo',
          Header: t('pages.loyaltyPlans.table.validTo').toString(),
          width: 100,
          Cell: (params: any) => params.value.substring(0, 10),
        },
      )
      isXpLoyaltyPointsVisible() &&
        columns.push({
          accessor: 'xpStrategyDescription',
          Header: t(
            'pages.loyaltyPlans.table.xpStrategyDescription',
          ).toString(),
          width: 350,
          Cell: (params: any) => params.value,
        })
      isVpLoyaltyPointsVisible() &&
        columns.push({
          accessor: 'vpStrategyDescription',
          Header: t(
            'pages.loyaltyPlans.table.vpStrategyDescription',
          ).toString(),
          width: 350,
          Cell: (params: any) => params.value,
        })
      isLpLoyaltyPointsVisible() &&
        columns.push({
          accessor: 'lpStrategyDescription',
          Header: t(
            'pages.loyaltyPlans.table.lpStrategyDescription',
          ).toString(),
          width: 350,
          Cell: (params: any) => params.value,
        })
      columns.push({
        accessor: 'actions',
        Header: t('pages.loyaltyPlans.table.actions').toString(),
        width: 195,
        disableSortBy: true,
        sticky: 'right',
        Cell: (params: any) => (
          <Grid container>
            <Grid
              item
              sx={{
                width: '35px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Tooltip title={`${t('pages.loyaltyPlans.table.edit')}`}>
                <IconButton
                  onClick={() =>
                    handleNavigationClick(
                      `${path}/update/${params.row.values.planId}`,
                    )
                  }
                  size="small"
                  style={{
                    padding: 0,
                    opacity: !params.row.original.isEditable ? '.3' : '1',
                  }}
                  disabled={!params.row.original.isEditable}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              sx={{
                width: '35px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Tooltip title={`${t('pages.loyaltyPlans.table.preview')}`}>
                <IconButton
                  onClick={() =>
                    handlePeriodPlanDetailsDialogClickOpen(
                      params.row.original.planId,
                    )
                  }
                  size="small"
                  style={{ padding: 0, marginRight: '5px' }}
                >
                  <PreviewIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              sx={{
                width: '35px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Tooltip
                title={`${t('pages.loyaltyPlans.table.userImportResult')}`}
              >
                <IconButton
                  onClick={() =>
                    handleImportDialogClickOpen(
                      'participant_plan_results',
                      params.row.values.planId,
                      params.row.values.name,
                      params.row.values.periodName,
                    )
                  }
                  size="small"
                  style={{
                    padding: 0,
                    marginRight: '5px',
                    opacity:
                      !isSuperAdmin(currentUser) && !isCentralAdmin(currentUser)
                        ? '.3'
                        : '1',
                  }}
                  disabled={
                    !isSuperAdmin(currentUser) && !isCentralAdmin(currentUser)
                  }
                >
                  {Date.parse(params.row.original.validTo) < Date.now() ? (
                    <UserImportResultRed />
                  ) : (
                    <UserImportResult />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              sx={{
                width: '35px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Tooltip
                title={`${t('pages.loyaltyPlans.table.userImportPlan')}`}
              >
                <IconButton
                  onClick={() =>
                    handleImportDialogClickOpen(
                      'participant_plans',
                      params.row.values.planId,
                      params.row.values.name,
                      params.row.values.periodName,
                    )
                  }
                  size="small"
                  style={{
                    padding: 0,
                    marginRight: '5px',
                    opacity: !params.row.original.isEditable ? '.3' : '1',
                  }}
                  disabled={!params.row.original.isEditable}
                >
                  {Date.parse(params.row.original.validFrom) <= Date.now() &&
                  Date.parse(params.row.original.validTo) >= Date.now() ? (
                    <UserImportPlanRed />
                  ) : (
                    <UserImportPlan />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              sx={{
                width: '35px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Tooltip
                title={`${t('pages.loyaltyPlans.table.userImportTarget')}`}
              >
                <IconButton
                  onClick={() =>
                    handleImportDialogClickOpen(
                      'participant_plan_target',
                      params.row.values.planId,
                      params.row.values.name,
                      params.row.values.periodName,
                    )
                  }
                  size="small"
                  style={{
                    padding: 0,
                    opacity: !params.row.original.isEditable ? '.5' : '1',
                  }}
                  disabled={!params.row.original.isEditable}
                >
                  <PercentIcon
                    style={{
                      color:
                        Date.parse(params.row.original.validFrom) <=
                          Date.now() &&
                        Date.parse(params.row.original.validTo) >= Date.now()
                          ? 'rgb(196, 0, 0)'
                          : '',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ),
      })

      return columns
    },
    [t, path, currentUser],
  )

  const handlePeriodPlanDetailsDialogClickOpen = (planId: number) => {
    setPlanId(planId)
    setPeriodPlanDetailsDialogOpen(true)
  }

  const handlePeriodPlanDetailsDialogClose = (
    refreshTable: boolean = false,
  ) => {
    setPeriodPlanDetailsDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  useEffect(() => {
    const fetchPeriodData = async () => {
      try {
        const periodListResponse = await LoyaltyPeriodService.getPeriodList()

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchPeriodData()
  }, [t])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)

          const page = ++pageIndex
          const plansResponse = await LoyaltyPlanService.getPlanList(
            // periodValue === 'all' ? [] : [{ id: parseInt(periodValue) }],
            periodValue === 'all' ? null : parseInt(periodValue),
            null,
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )

          if (plansResponse.data.plans) {
            setTableColumns(generateTableColumns(plansResponse.data.plans))

            setFilteredPlansList(plansResponse.data.plans)

            setTotalCount(plansResponse.data.totalCount)
            setPageCount(Math.ceil(plansResponse.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      } else {
        setTableColumns(generateTableColumns([]))

        setFilteredPlansList([])

        setTotalCount(0)
        setPageCount(0)
      }
    },
    [t, periodValue, searchValue, generateTableColumns, refresh],
  )

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const plansResponse = await LoyaltyPlanService.getPlanList(
        // periodValue === 'all' ? [] : [{ id: parseInt(periodValue) }],
        periodValue === 'all' ? null : parseInt(periodValue),
        null,
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
      )

      const dataUsers = plansResponse.data.plans
      if (dataUsers) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'planId',
            'periodName',
            'name',
            'validFrom',
            'validTo',
            ...(isXpLoyaltyPointsVisible() ? ['xpStrategyDescription'] : []),
            ...(isVpLoyaltyPointsVisible() ? ['vpStrategyDescription'] : []),
            ...(isLpLoyaltyPointsVisible() ? ['lpStrategyDescription'] : []),
          ]
        }

        const hiddenColumns = getHiddenColumns('plans', visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredPeriodPlansData = dataUsers.map((plan) => {
          plan.validFrom = plan.validFrom.substring(0, 10)
          plan.validTo = plan.validTo.substring(0, 10)
          return pick(plan, visibleColumns)
        })

        const translatedHeaders = {
          planId: t('excel.loyaltyPlans.planId'),
          periodName: t('excel.loyaltyPlans.periodName'),
          name: t('excel.loyaltyPlans.name'),
          validFrom: t('excel.loyaltyPlans.validFrom'),
          validTo: t('excel.loyaltyPlans.validTo'),
          xpStrategyDescription: t('excel.loyaltyPlans.xpStrategyDescription'),
          vpStrategyDescription: t('excel.loyaltyPlans.vpStrategyDescription'),
          lpStrategyDescription: t('excel.loyaltyPlans.lpStrategyDescription'),
        }

        const headers = [
          Object.keys(filteredPeriodPlansData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredPeriodPlansData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredPeriodPlansData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <PrimaryButton
              variant="contained"
              onClick={() => handleNavigationClick(`${path}/create`)}
              sx={{ marginRight: 1 }}
            >
              {t('common.create')}
            </PrimaryButton>
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('loyaltyPlans')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <LoyaltyPlansToolbar
            periods={periods}
            periodValue={periodValue}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
              setTableState(tableName, 'search', searchValue)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
            filterPeriod={(event: SelectChangeEvent) => {
              setSkipPageReset(false)
              setControlledPageIndex(0)
              setPeriodValue(event.target.value)
              setTableState(tableName, 'period', event.target.value)
            }}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredPlansList}
            height="calc(100vh - 320px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              ...(isXpLoyaltyPointsVisible() ? ['xpStrategyDescription'] : []),
              ...(isVpLoyaltyPointsVisible() ? ['vpStrategyDescription'] : []),
              ...(isLpLoyaltyPointsVisible() ? ['lpStrategyDescription'] : []),
            ]}
            toggleVisibility={setColumnsVisibility}
          />
          {planId && (
            <LoyaltyPlanDetailsDialog
              open={openPeriodPlanDetailsDialog}
              handleClose={handlePeriodPlanDetailsDialogClose}
              planId={planId}
            />
          )}
          {planId && importName && (
            <ImportDialog
              open={openImportDialog}
              handleClose={handleImportDialogClose}
              name={importName}
              param1={planId}
              title={importTitle}
            />
          )}
        </>
      )}
    </>
  )
}

export default LoyaltyPlansList
