import axios from 'axios'
import {
  CompanyAddUpdateParams,
  CompanyAddUpdateResponse,
  CompanyImageReponse,
  CompanyListReponse,
  CompanyListResponse,
  CompanyDetailsResponse,
} from '../store/Company/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getCompanyList = (): Promise<CompanyListReponse> => {
  return axios.get(API_URL + '/api/admin/company', {
    headers: authHeader(),
  })
}

const getPaginatedCompanyList = async (
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
  regions: { id: number }[],
): Promise<CompanyListResponse> => {
  return axios.post(
    API_URL + '/api/admin/company/list',
    {
      search,
      sortBy,
      sortOrder,
      per,
      page,
      regions,
    },
    {
      headers: authHeader(),
    },
  )
}

const getImageContent = (companyId: number): Promise<CompanyImageReponse> => {
  return axios.get(API_URL + `/api/admin/image/company/${companyId}`, {
    headers: authHeader(),
  })
}

const addAndEditCompany = async (
  params: CompanyAddUpdateParams,
): Promise<CompanyAddUpdateResponse> => {
  return await axios.post(
    API_URL + '/api/admin/company',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const getCompanyDetails = (
  companyId: number,
): Promise<CompanyDetailsResponse> => {
  return axios.get(API_URL + `/api/admin/company/preview/${companyId}`, {
    headers: authHeader(),
  })
}

const exportedObject = {
  getCompanyList,
  getPaginatedCompanyList,
  getImageContent,
  addAndEditCompany,
  getCompanyDetails,
}

export default exportedObject
