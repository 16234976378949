import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'

import { Grid } from '@mui/material'
import CompaniesList from './Companies/list/CompaniesList'
import { handleNavigationClick } from '../../../helpers/utils'
import RegionsList from './Regions/list/RegionsList'
import InnerNavButton from '../../shared/InnerNavButton'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BusinessIcon from '@mui/icons-material/Business'
import WorkspacesIcon from '@mui/icons-material/Workspaces'

const Companies = () => {
  let { path } = useRouteMatch()
  const location = useLocation()
  const { t } = useTranslation()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div
            style={{ height: '100%', width: '100%', padding: '0 20px 0 20px' }}
          >
            <span>
              <InnerNavButton
                onClick={() => {
                  handleNavigationClick(`${path}/companies`)
                }}
                active={`${path}/companies` === location.pathname}
                name={t('pages.organization.navigation.companies')}
                icon={<BusinessIcon />}
              />
            </span>

            <InnerNavButton
              onClick={() => {
                handleNavigationClick(`${path}/regions`)
              }}
              active={`${path}/regions` === location.pathname}
              name={t('pages.organization.navigation.regions')}
              icon={<WorkspacesIcon />}
            />
            <Switch>
              <Route exact path={`${path}/companies`}>
                <CompaniesList path={`${path}/companies`} />
              </Route>
              <Route exact path={`${path}/regions`}>
                <RegionsList path={`${path}/regions`} />
              </Route>
            </Switch>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Companies
