import axios from 'axios'
import {
  OperationDetailsResponse,
  OperationListReponse,
  OperationTypesFilterListResponse,
} from '../store/Operation/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getOperationList = async (
  operationTypes: { id: number }[],
  companies: { id: number }[],
  users: { id: number }[],
  period: number | null,
  operationDateFrom: string | null,
  operationDateTo: string | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<OperationListReponse> => {
  return axios.post(
    API_URL + '/api/admin/game/operation/list',
    {
      operationTypes,
      companies,
      users,
      period,
      operationDateFrom,
      operationDateTo,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getOperationDetails = async (
  operationId: number,
): Promise<OperationDetailsResponse> => {
  return await axios.get(
    API_URL + `/api/admin/game/operation/preview/${operationId}`,
    {
      headers: authHeader(),
    },
  )
}

const getOperationTypes = (): Promise<OperationTypesFilterListResponse> => {
  return axios.get(API_URL + '/api/admin/game/operation/filter/type/list', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getOperationList,
  getOperationDetails,
  getOperationTypes,
}

export default exportedObject
