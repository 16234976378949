import axios from 'axios'
import {
  LoyaltyCreateOrUpdatePlanDetailsParams,
  LoyaltyCreateOrUpdatePlanDetailsResponse,
  LoyaltyCreateOrUpdatePlanStrategiesParams,
  LoyaltyCreateOrUpdatePlanStrategiesResponse,
  LoyaltyPlanDetailsReponse,
  LoyaltyPlanFilerReponse,
  LoyaltyPlanListReponse,
  LoyaltyPlansForecastReponse,
  LoyaltyPlanStrategiesReponse,
  LoyaltyPlanTypesResponse,
} from '../store/LoyaltyPlan/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getPlanList = async (
  loyaltyPeriodId: number | null,
  planTypeCode: string | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<LoyaltyPlanListReponse> => {
  return await axios.post(
    API_URL + '/api/admin/loyalty/plan/list',
    {
      loyaltyPeriodId,
      planTypeCode,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getPlanTypeList = (): Promise<LoyaltyPlanTypesResponse> => {
  return axios.get(API_URL + '/api/admin/loyalty/plan/type', {
    headers: authHeader(),
  })
}

const getPlanDetails = (planId: number): Promise<LoyaltyPlanDetailsReponse> => {
  return axios.get(API_URL + `/api/admin/loyalty/plan/preview/${planId}`, {
    headers: authHeader(),
  })
}

const createOrUpdatePlanDetails = async (
  params: LoyaltyCreateOrUpdatePlanDetailsParams,
): Promise<LoyaltyCreateOrUpdatePlanDetailsResponse> => {
  return await axios.post(API_URL + '/api/admin/loyalty/plan', params, {
    headers: authHeader(),
  })
}

const createOrUpdatePlanStrategies = async (
  params: LoyaltyCreateOrUpdatePlanStrategiesParams,
): Promise<LoyaltyCreateOrUpdatePlanStrategiesResponse> => {
  return await axios.post(
    API_URL + '/api/admin/loyalty/plan/strategy',
    params,
    {
      headers: authHeader(),
    },
  )
}

const getPlanStrategies = (
  strategy: string,
): Promise<LoyaltyPlanStrategiesReponse> => {
  return axios.get(API_URL + `/api/admin/loyalty/plan/strategy/${strategy}`, {
    headers: authHeader(),
  })
}

const getPlanFilter = (periodId?: number): Promise<LoyaltyPlanFilerReponse> => {
  let fetchUrl = '/api/admin/plan/list/simple'
  if (periodId) {
    fetchUrl = `${fetchUrl}/${periodId}`
  }
  return axios.get(API_URL + fetchUrl, {
    headers: authHeader(),
  })
}

const setPlansForecast = async (
  plans: { id: number }[],
  timeExpiration: number,
): Promise<LoyaltyPlansForecastReponse> => {
  return await axios.post(
    API_URL + '/api/admin/plan/forecast',
    {
      plans,
      timeExpiration,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getPlanList,
  getPlanDetails,
  getPlanTypeList,
  createOrUpdatePlanDetails,
  createOrUpdatePlanStrategies,
  getPlanStrategies,
  getPlanFilter,
  setPlansForecast,
}

export default exportedObject
