import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import LoyaltyPlanService from '../../../../services/loyaltyPlan.service'
import {
  isLpLoyaltyPointsVisible,
  isVpLoyaltyPointsVisible,
  isXpLoyaltyPointsVisible,
} from '../../../../helpers/environment'
import { DetailsData } from '../../../../store/Details/types'
import DetailsDialog from '../../../shared/DetailsDialog'

type LoyaltyPlanDetailsDialogProps = {
  planId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const LoyaltyPlanDetailsDialog: React.FunctionComponent<
  LoyaltyPlanDetailsDialogProps
> = ({ planId, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [detailsParamsForDialog, setDetailsParamsForDialog] =
    useState<DetailsData>()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const planDetailsResponse = await LoyaltyPlanService.getPlanDetails(
          planId,
        )
        if (planDetailsResponse.data) {
          const planDetailsParams: DetailsData = {
            title: {
              label: `${t(
                'pages.loyaltyPlans.planDetailsDialog.planId',
              )}: ${planId.toString()}`,
              value: planDetailsResponse.data.name,
            },
            data: [
              {
                label: t('pages.loyaltyPlans.planDetailsDialog.periodName'),
                value: planDetailsResponse.data.periodName,
              },
              {
                label: t('pages.loyaltyPlans.planDetailsDialog.validFrom'),
                value: planDetailsResponse.data.validFrom,
              },
              {
                label: t('pages.loyaltyPlans.planDetailsDialog.validTo'),
                value: planDetailsResponse.data.validTo,
              },
            ],
          }

          if (isXpLoyaltyPointsVisible()) {
            planDetailsParams.data.push({
              label: t(
                'pages.loyaltyPlans.planDetailsDialog.xpStrategyDescription',
              ),
              value: planDetailsResponse.data.xpStrategyDescription,
            })
          }
          if (isVpLoyaltyPointsVisible()) {
            planDetailsParams.data.push({
              label: t(
                'pages.loyaltyPlans.planDetailsDialog.vpStrategyDescription',
              ),
              value: planDetailsResponse.data.vpStrategyDescription,
            })
          }
          if (isLpLoyaltyPointsVisible()) {
            planDetailsParams.data.push({
              label: t(
                'pages.loyaltyPlans.planDetailsDialog.lpStrategyDescription',
              ),
              value: planDetailsResponse.data.lpStrategyDescription,
            })
          }
          setDetailsParamsForDialog(planDetailsParams)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, planId])

  return (
    <>
      {detailsParamsForDialog && (
        <DetailsDialog
          detailsData={detailsParamsForDialog}
          open={open}
          loading={loading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

export default LoyaltyPlanDetailsDialog
