import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import CompanyService from '../../../../services/company.service'
import UserService from '../../../../services/user.service'
import ConfigService from '../../../../services/config.service'
import PeriodService from '../../../../services/period.service'
import AccountOperationService from '../../../../services/accountOperation.service'
import { useTranslation } from 'react-i18next'
import UsersToolbar from '../partials/UsersToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { User } from '../../../../store/Auth/types'
import {
  User as UserDetails,
  UserState,
  UserTypeCode,
} from '../../../../store/User/types'
import {
  CellProps,
  Column,
  ColumnInstance,
  HeaderProps,
  Row,
} from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import { pick } from 'lodash'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
  thousandsSeparator,
} from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import UserAddDialog from '../partials/UserAddDialog'
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import UserEditDialog from '../partials/UserEditDialog'
import UserDetailsDialog from '../partials/UserDetailsDialog'
import IndeterminateCheckbox from '../../../Table/IndeterminateCheckbox'
// import LockIcon from '@mui/icons-material/Lock'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import moment from 'moment'
import UserChangePointsDialog from '../partials/UserChangePointsDialog'
import {
  isCentralAdmin,
  isCompanyAdmin,
  isSuperAdmin,
} from '../../../../helpers/checkRole'
import UserDeactivationDialog from '../partials/UserDeactivationDialog'
import { ApplicationVersion } from '../../../../store/Config/types'
import VisibilityIcon from '@mui/icons-material/Visibility'
import UserHideDialog from '../partials/UserHideDialog'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'
import ImportDialog from '../../../shared/ImportDialog'
import { Period } from '../../../../store/Period/types'
import { AccountOperation } from '../../../../store/AccountOperation/types'

type UsersListProps = {
  user: User
  type: UserTypeCode
}

const UsersList: FunctionComponent<UsersListProps> = ({ user, type }) => {
  const tableName = `users${type.toLowerCase()}`

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredUsersList, setFilteredUsersList] = useState<UserDetails[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')

  const [userStateValue, setUserStateValue] = useState<string>('all')
  const [userStates, setUserStates] = useState<UserState[]>([])
  const [applicationReleaseValue, setApplicationReleaseValue] =
    useState<string>('all')
  const [applicationReleases, setApplicationReleases] = useState<
    ApplicationVersion[]
  >([])
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [companiesActive, setCompaniesActive] = useState<boolean>(false)

  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const fetchIdRef = useRef(0)

  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [selectedRows, setSelectedRows] = useState<Row<object>[]>([])
  const [selectedUserIds, setSelectedUserIds] = useState<
    Pick<UserDetails, 'userId'>[]
  >([])

  const [openUserAddDialog, setUserAddDialogOpen] = useState(false)
  const [openUserEditDialog, setUserEditDialogOpen] = useState(false)
  const [openUserDetailsDialog, setUserDetailsDialogOpen] = useState(false)
  const [openUserChangePointsDialog, setUserChangePointsDialogOpen] =
    useState(false)
  const [openUserDeactivationDialog, setUserDeactivationDialogOpen] =
    useState(false)
  const [openUserHideDialog, setUserHideDialogOpen] = useState(false)
  const [userId, setUserId] = useState<number | null>(null)
  const [refresh, setRefresh] = useState(false)

  const [lastLoginDateFrom, setLastLoginDateFrom] = useState<Date | null>(null)
  const [lastLoginTimeFrom, setLastLoginTimeFrom] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [lastLoginDateTo, setLastLoginDateTo] = useState<Date | null>(null)
  const [lastLoginTimeTo, setLastLoginTimeTo] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )

  const [openImportDialog, setImportDialogOpen] = useState(false)
  const [periodValue, setPeriodValue] = useState<string>('')
  const [periods, setPeriods] = useState<Period[]>([])

  const [operationTypes, setOperationTypes] = useState<AccountOperation[]>([])
  const [operationTypesValue, setOperationTypesValue] = useState<string>('')

  const [exportTypeValue, setExportTypeValue] = useState<string>('1')

  const [importName, setImportName] = useState<string | null>(null)
  const [importTitle, setImportTitle] = useState<string>()
  const [importParamPeriod, setImportParamPeriod] = useState<number>()
  const [importParamOperationType, setImportParamOperationType] =
    useState<number>()

  const [canDeactivate, setCanDeactivate] = useState<boolean>(true)

  const generateTableColumns = useCallback(
    (users: UserDetails[]) => {
      const columns = []
      columns.push(
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          width: 40,
          Cell: ({ row }: CellProps<any>) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          sticky: 'left',
        },
        {
          Header: t('pages.users.table.userId').toString(),
          accessor: 'userId',
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.users.table.userCentralId').toString(),
          accessor: 'userCentralId',
          width: 110,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'firstname',
          Header: t('pages.users.table.firstname').toString(),
          width: 130,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'lastname',
          Header: t('pages.users.table.lastname').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'email',
          Header: t('pages.users.table.email').toString(),
          width: 270,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'mobile',
          Header: t('pages.users.table.mobile').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'companyName',
          Header: t('pages.users.table.companyName').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'stateName',
          Header: t('pages.users.table.stateName').toString(),
          width: 110,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'isVisible',
          Header: t('pages.users.table.isVisible').toString(),
          width: 110,
          Cell: (params: any) =>
            params.value ? <>{t('common.yes')}</> : <>{t('common.no')}</>,
        },
        {
          accessor: 'lastLoginDate',
          Header: t('pages.users.table.lastLoginDate').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'edit',
          Header: t('pages.users.table.actions').toString(),
          width: 100,
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <>
              {(type === UserTypeCode.SE || type === UserTypeCode.ASM) && (
                <Grid container>
                  <Grid
                    item
                    sx={{
                      width: '35px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip title={`${t('pages.users.table.edit')}`}>
                      <IconButton
                        onClick={() =>
                          handleUserEditDialogClickOpen(
                            params.row.original.userId,
                          )
                        }
                        size="small"
                        style={{ padding: 0 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: '35px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip title={`${t('pages.users.table.show')}`}>
                      <IconButton
                        onClick={() =>
                          handleUserDetailsDialogClickOpen(
                            params.row.original.userId,
                          )
                        }
                        size="small"
                        style={{ padding: 0, marginRight: '5px' }}
                      >
                        <PreviewIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </>
          ),
        },
      )
      if (type === UserTypeCode.SE) {
        columns.splice(10, 0, {
          accessor: 'bankAccount',
          Header: t('pages.users.table.bankAccount').toString(),
          width: 230,
          Cell: (params: any) => params.value,
        })
        columns.splice(11, 0, {
          accessor: 'applicationVersion',
          Header: t('pages.users.table.applicationVersion').toString(),
          width: 80,
          Cell: (params: any) => params.value,
        })
        columns.splice(12, 0, {
          accessor: 'deviceOsVersion',
          Header: t('pages.users.table.deviceOsVersion').toString(),
          width: 60,
          Cell: (params: any) => params.value,
        })
        columns.splice(13, 0, {
          accessor: 'deviceModelName',
          Header: t('pages.users.table.deviceModelName').toString(),
          width: 200,
          Cell: (params: any) => params.value,
        })
      }
      if (type === UserTypeCode.ASM && isXpPointsVisible()) {
        columns.splice(10, 0, {
          accessor: 'totalXpValue',
          Header: t('pages.users.table.totalXpValue').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">{thousandsSeparator(params.value)}</Stack>
          ),
        })
        columns.splice(11, 0, {
          accessor: 'currentXpValue',
          Header: t('pages.users.table.currentXpValue').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">{thousandsSeparator(params.value)}</Stack>
          ),
        })
        columns.splice(12, 0, {
          accessor: 'subtractedXpValue',
          Header: t('pages.users.table.subtractedXpValue').toString(),
          width: 120,
          Cell: (params: any) => (
            <Stack textAlign="right">{thousandsSeparator(params.value)}</Stack>
          ),
        })
      }
      return columns
    },
    [t, type],
  )

  const handleUserAddDialogClickOpen = () => {
    setUserAddDialogOpen(true)
  }

  const handleUserAddDialogClose = (refreshTable: boolean = false) => {
    setUserAddDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserEditDialogClickOpen = (userId: number) => {
    setUserId(userId)
    setUserEditDialogOpen(true)
  }

  const handleUserEditDialogClose = (refreshTable: boolean = false) => {
    setUserEditDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserDetailsDialogClickOpen = (userId: number) => {
    setUserId(userId)
    setUserDetailsDialogOpen(true)
  }

  const handleUserDetailsDialogClose = (refreshTable: boolean = false) => {
    setUserDetailsDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserChangePointsDialogClickOpen = (selectedRows: Row<any>[]) => {
    setSelectedUserIds(
      selectedRows.map((row) => ({ userId: row.original.userId })),
    )
    setUserChangePointsDialogOpen(true)
  }

  const handleUserChangePointsDialogClose = (refreshTable: boolean = false) => {
    setUserChangePointsDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserDeactivationDialogClickOpen = (selectedRows: Row<any>[]) => {
    setSelectedUserIds(
      selectedRows.map((row) => ({ userId: row.original.userId })),
    )
    setUserDeactivationDialogOpen(true)
  }

  const handleUserDeactivationDialogClose = (refreshTable: boolean = false) => {
    setUserDeactivationDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserHideDialogClickOpen = (selectedRows: Row<any>[]) => {
    setSelectedUserIds(
      selectedRows.map((row) => ({ userId: row.original.userId })),
    )
    setUserHideDialogOpen(true)
  }

  const handleUserHideDialogClose = (refreshTable: boolean = false) => {
    setUserHideDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleImportDialogClose = () => {
    setImportDialogOpen(false)
  }

  const handleImportDialogClickOpen = (
    userType: UserTypeCode,
    periodId?: string,
    operationType?: string,
  ) => {
    setImportTitle(
      t('pages.users.import.pointsImportTitle', {
        periodName: periods.find((p) => p.id === parseInt(periodId!))?.name,
      }),
    )
    setImportParamPeriod(parseInt(periodId!))
    setImportParamOperationType(parseInt(operationType!))
    if (userType === UserTypeCode.SE) {
      setImportName('users_points_operations')
    } else {
      setImportName('users_group_points_operations')
    }
    setImportDialogOpen(true)
  }

  useEffect(() => {
    if (selectedRows.length > 0) {
      let amountOfDeactivated: number = 0
      const count = selectedRows.map((cell: any) => {
        return cell
      })
      for (let i = 0; i < count.length; i++) {
        if (count[i].original.stateCode === 'INACTIVE') amountOfDeactivated++
      }

      amountOfDeactivated > 0 ? setCanDeactivate(false) : setCanDeactivate(true)
    }
  }, [selectedRows])

  useEffect(() => {
    const fetchUserStates = async () => {
      try {
        const userStatesResponse = await UserService.getUserStateList()

        if (userStatesResponse.data.userStates) {
          setUserStates(userStatesResponse.data.userStates)
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
    fetchUserStates()

    const fetchApplicationReleases = async () => {
      try {
        const applicationVersionsResponse =
          await ConfigService.getApplicationReleases('app1')

        if (applicationVersionsResponse.data.applicationVersions) {
          setApplicationReleases(
            applicationVersionsResponse.data.applicationVersions,
          )
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
    if (type === UserTypeCode.SE) {
      fetchApplicationReleases()
    }

    const fetchCompaniesData = async () => {
      try {
        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          const companiesState = getTableState(tableName, 'companies')
          if (companiesState) {
            setSelectedCompanies(JSON.parse(companiesState))
          } else {
            setSelectedCompanies(multiSelectOptions)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchCompaniesData()

    const getFiltersData = async () => {
      const companiesActiveState = getTableState(tableName, 'companiesActive')
      const searchState = getTableState(tableName, 'search')
      const statusState = getTableState(tableName, 'status')
      const releaseState = getTableState(tableName, 'release')
      const lastLoginDateFromState = getTableState(
        tableName,
        'last-login-date-from',
      )
      const lastLoginTimeFromState = getTableState(
        tableName,
        'last-login-time-from',
      )
      const lastLoginDateToState = getTableState(
        tableName,
        'last-login-date-to',
      )
      const lastLoginTimeToState = getTableState(
        tableName,
        'last-login-time-to',
      )

      if (companiesActiveState) {
        setCompaniesActive(companiesActiveState === 'true' ? true : false)
      } else {
        setCompaniesActive(false)
      }

      if (searchState) {
        setSearchText(searchState)
        setSearchValue(searchState)
      } else {
        setSearchText('')
        setSearchValue('')
      }

      if (statusState) {
        setUserStateValue(statusState)
      } else {
        setUserStateValue('all')
      }

      if (releaseState) {
        setApplicationReleaseValue(releaseState)
      } else {
        setApplicationReleaseValue('all')
      }

      if (lastLoginDateFromState && lastLoginDateFromState !== 'null') {
        setLastLoginDateFrom(new Date(JSON.parse(lastLoginDateFromState)))
      } else {
        setLastLoginDateFrom(null)
      }

      if (lastLoginTimeFromState) {
        setLastLoginTimeFrom(new Date(JSON.parse(lastLoginTimeFromState)))
      } else {
        setLastLoginTimeFrom(new Date(new Date().setHours(0, 0, 0, 0)))
      }

      if (lastLoginDateToState && lastLoginDateToState !== 'null') {
        setLastLoginDateTo(new Date(JSON.parse(lastLoginDateToState)))
      } else {
        setLastLoginDateTo(null)
      }

      if (lastLoginTimeToState) {
        setLastLoginTimeTo(new Date(JSON.parse(lastLoginTimeToState)))
      } else {
        setLastLoginTimeTo(new Date(new Date().setHours(0, 0, 0, 0)))
      }
    }
    getFiltersData()
    const fetchPeriodsData = async () => {
      try {
        const periodListResponse = await PeriodService.getPeriodList()

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods.reverse())
          setPeriodValue(
            periodListResponse.data.periods
              .find((period) => period.isActivated)
              ?.id.toString() || '',
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchPeriodsData()

    const fetchOperationTypesData = async () => {
      try {
        const operationTypesListResponse =
          type === UserTypeCode.ASM
            ? await AccountOperationService.getGroupAccountOperationList()
            : await AccountOperationService.getAccountOperationList()

        if (operationTypesListResponse.data.operationTypes) {
          setOperationTypes(operationTypesListResponse.data.operationTypes)

          setOperationTypesValue(
            operationTypesListResponse.data.operationTypes
              .find((operation) => operation.id === 1)
              ?.id.toString() || '',
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (!isCompanyAdmin(user)) {
      fetchOperationTypesData()
    }
  }, [t, tableName, type, user])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      const checkSortColumn = (sortColumn: string) => {
        if (
          ['totalXpValue', 'currentXpValue', 'subtractedXpValue'].includes(
            sortColumn,
          ) &&
          ['usersse', 'usersadm'].includes(tableName)
        ) {
          return ''
        }

        return sortColumn
      }

      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        if (selectedCompanies.length) {
          setTableLoading(true)
          try {
            let sortColumn = ''
            let sortDirection = ''
            if (sortBy.length) {
              sortColumn = sortBy[0].id
              sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
            }

            setDownloadSortBy(sortColumn)
            setDownloadSortOrder(sortDirection)

            const validFrom = lastLoginDateFrom
              ? moment(lastLoginDateFrom).format('YYYY-MM-DD') +
                ' ' +
                moment(lastLoginTimeFrom).format('HH:mm') +
                ':00'
              : null

            const validTo = lastLoginDateTo
              ? moment(lastLoginDateTo).format('YYYY-MM-DD') +
                ' ' +
                moment(lastLoginTimeTo).format('HH:mm') +
                ':59'
              : null

            const page = ++pageIndex
            const usersResponse = await UserService.getUsers(
              type === UserTypeCode.ADM && !companiesActive
                ? []
                : selectedCompanies.map((company) => {
                    return {
                      id: company.value,
                    }
                  }),
              userStateValue === 'all' ? null : parseInt(userStateValue),
              type,
              searchValue,
              checkSortColumn(sortColumn),
              sortDirection,
              pageSize,
              page,
              validFrom,
              validTo,
              applicationReleaseValue === 'all'
                ? null
                : parseInt(applicationReleaseValue),
            )

            if (usersResponse.data.users) {
              setTableColumns(generateTableColumns(usersResponse.data.users))

              setFilteredUsersList(usersResponse.data.users)

              setTotalCount(usersResponse.data.totalCount)
              setPageCount(Math.ceil(usersResponse.data.totalCount / pageSize))
            }
          } catch (error) {
            errorHandler(error, t)
          } finally {
            setSkipPageReset(true)
            setTableLoading(false)
          }
        } else {
          setTableColumns(generateTableColumns([]))

          setFilteredUsersList([])

          setTotalCount(0)
          setPageCount(0)
        }
      }
    },
    [
      t,
      selectedCompanies,
      userStateValue,
      searchValue,
      generateTableColumns,
      type,
      refresh,
      lastLoginDateFrom,
      lastLoginTimeFrom,
      lastLoginDateTo,
      lastLoginTimeTo,
      tableName,
      companiesActive,
      applicationReleaseValue,
    ],
  )

  const handleSelectedCompaniesChange = (companies: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setTableState(tableName, 'companies', JSON.stringify(companies))
    setSelectedCompanies(companies)
  }

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const validFrom = lastLoginDateFrom
        ? moment(lastLoginDateFrom).format('YYYY-MM-DD') +
          ' ' +
          moment(lastLoginTimeFrom).format('HH:mm') +
          ':00'
        : null

      const validTo = lastLoginDateTo
        ? moment(lastLoginDateTo).format('YYYY-MM-DD') +
          ' ' +
          moment(lastLoginTimeTo).format('HH:mm') +
          ':59'
        : null

      const usersResponse = await UserService.getUsers(
        type === UserTypeCode.ADM && !companiesActive
          ? []
          : selectedCompanies.map((company) => {
              return {
                id: company.value,
              }
            }),
        userStateValue === 'all' ? null : parseInt(userStateValue),
        type,
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
        validFrom,
        validTo,
        applicationReleaseValue === 'all'
          ? null
          : parseInt(applicationReleaseValue),
      )

      const dataUsers = usersResponse.data.users
      if (dataUsers) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'userId',
            'userCentralId',
            'firstname',
            'lastname',
            'email',
            'mobile',
            'companyName',
            'stateName',
            'isVisible',
            'bankAccount',
            'lastLoginDate',
            'applicationVersion',
            'deviceOsVersion',
            'deviceModelName',
            'totalXpValue',
            'currentXpValue',
            'subtractedXpValue',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredUsersData = dataUsers.map((user) => {
          user.isVisible = user.isVisible
            ? t('common.yes').toString()
            : t('common.no').toString()
          return pick(user, visibleColumns)
        })

        const translatedHeaders = {
          userId: t('excel.users.table.userId'),
          typeId: t('excel.users.table.typeId'),
          userCentralId: t('excel.users.table.userCentralId'),
          firstname: t('excel.users.table.firstname'),
          lastname: t('excel.users.table.lastname'),
          email: t('excel.users.table.email'),
          mobile: t('excel.users.table.mobile'),
          companyId: t('excel.users.table.companyId'),
          companyName: t('excel.users.table.companyName'),
          regionId: t('excel.users.table.regionId'),
          regionName: t('excel.users.table.regionName'),
          typeName: t('excel.users.table.typeName'),
          lastLoginDate: t('excel.users.table.lastLoginDate'),
          bankAccount: t('excel.users.table.bankAccount'),
          stateName: t('excel.users.table.stateName'),
          isVisible: t('excel.users.table.isVisible'),
          totalXpValue: t('excel.users.table.totalXpValue'),
          currentXpValue: t('excel.users.table.currentXpValue'),
          subtractedXpValue: t('excel.users.table.subtractedXpValue'),
          applicationVersion: t('excel.users.table.applicationVersion'),
          deviceOsVersion: t('excel.users.table.deviceOsVersion'),
          deviceModelName: t('excel.users.table.deviceModelName'),
        }

        const headers = [
          Object.keys(filteredUsersData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredUsersData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredUsersData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  const downloadUsersPointsXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const usersPointsResponse = await UserService.getUserPointsExportData()

      const dataUsersPoints = usersPointsResponse.data.results
      if (dataUsersPoints) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'userId',
            'userCentralId',
            'firstname',
            'lastname',
            'companyName',
            'isActive',
            'isVisible',
          ]
          if (isXpPointsVisible()) visibleColumns.push('xpValue')
          if (isVpPointsVisible()) visibleColumns.push('vpValue')
          if (isEpPointsVisible()) visibleColumns.push('epValue')
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredUsersData = dataUsersPoints.map((userPoints) => {
          userPoints.isVisible = userPoints.isVisible
            ? t('common.yes').toString()
            : t('common.no').toString()
          userPoints.isActive = userPoints.isActive
            ? t('common.yes').toString()
            : t('common.no').toString()
          return pick(userPoints, visibleColumns)
        })

        const translatedHeaders = {
          userId: t('excel.users.points.userId'),
          userCentralId: t('excel.users.points.userCentralId'),
          firstname: t('excel.users.points.firstname'),
          lastname: t('excel.users.points.lastname'),
          companyName: t('excel.users.points.companyName'),
          isActive: t('excel.users.points.isActive'),
          isVisible: t('excel.users.points.isVisible'),
          xpValue: t('excel.users.points.xpValue'),
          vpValue: t('excel.users.points.vpValue'),
          epValue: t('excel.users.points.epValue'),
        }

        const headers = [
          Object.keys(filteredUsersData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredUsersData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredUsersData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  const shouldRenderChangePointsButton = (): boolean => {
    if (
      type === UserTypeCode.SE &&
      (isXpPointsVisible() || isVpPointsVisible() || isEpPointsVisible())
    ) {
      return true
    } else if (type === UserTypeCode.ASM && isXpPointsVisible()) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            alignItems="center"
            flexDirection="row"
            justifyContent={
              isCompanyAdmin(user) && type === UserTypeCode.ADM
                ? 'flex-end'
                : ''
            }
            marginBottom={1}
          >
            {(type === UserTypeCode.SE || type === UserTypeCode.ASM) &&
              (isCentralAdmin(user) || isSuperAdmin(user)) && (
                <PrimaryButton
                  variant="contained"
                  onClick={handleUserAddDialogClickOpen}
                >
                  {t('common.create')}
                </PrimaryButton>
              )}
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              width={
                type === UserTypeCode.SE || type === UserTypeCode.ASM
                  ? '100%'
                  : ''
              }
            >
              {(type === UserTypeCode.SE || type === UserTypeCode.ASM) &&
                (isCentralAdmin(user) || isSuperAdmin(user)) && (
                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    padding={'5px'}
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: 5,
                      marginLeft: 'auto',
                      marginRight: '10px',
                    }}
                  >
                    <FormControl size="small">
                      <Select
                        id="period-select"
                        onChange={(event: SelectChangeEvent) => {
                          setOperationTypesValue(event.target.value)
                        }}
                        value={operationTypesValue}
                        defaultValue={operationTypesValue}
                        style={{ marginRight: 5 }}
                      >
                        {operationTypes.map((operation) => (
                          <MenuItem value={operation.id} key={operation.id}>
                            {operation.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl size="small">
                      <Select
                        id="period-select"
                        onChange={(event: SelectChangeEvent) => {
                          setPeriodValue(event.target.value)
                        }}
                        value={periodValue}
                        defaultValue={periodValue}
                        style={{ marginRight: 5 }}
                      >
                        {periods.map((period) => (
                          <MenuItem value={period.id} key={period.id}>
                            {period.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <PrimaryButton
                      variant="contained"
                      onClick={() =>
                        handleImportDialogClickOpen(
                          type,
                          periodValue,
                          operationTypesValue,
                        )
                      }
                      style={{ marginRight: 5 }}
                    >
                      {t('pages.users.pointsImport')}
                    </PrimaryButton>
                  </Stack>
                )}
              {(type === UserTypeCode.SE || type === UserTypeCode.ASM) &&
                (isCentralAdmin(user) || isSuperAdmin(user)) && (
                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    padding={'5px'}
                    style={{
                      border: '1px solid #ccc',
                      borderRadius: 5,
                    }}
                  >
                    <FormControl size="small">
                      <Select
                        id="period-select"
                        onChange={(event: SelectChangeEvent) => {
                          setExportTypeValue(event.target.value)
                        }}
                        value={exportTypeValue}
                        defaultValue={exportTypeValue}
                        style={{ marginRight: 5 }}
                      >
                        <MenuItem value="1">
                          {t('pages.users.tableExportOptions.tableXlsx')}
                        </MenuItem>
                        <MenuItem value="2">
                          {t('pages.users.tableExportOptions.tablePoints')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <PrimaryButton
                      variant="contained"
                      onClick={() => {
                        exportTypeValue === '1'
                          ? downloadXLSX('users_table')
                          : downloadUsersPointsXLSX('users_points')
                      }}
                      sx={{
                        marginLeft:
                          type !== UserTypeCode.SE && type !== UserTypeCode.ASM
                            ? 'auto'
                            : '',
                      }}
                      disabled={isDownloading}
                    >
                      {isDownloading && (
                        <CircularProgress
                          style={{ height: 12, width: 12, marginRight: 10 }}
                        />
                      )}
                      {isDownloading
                        ? t('common.generatingFile')
                        : t('common.download')}
                    </PrimaryButton>
                  </Stack>
                )}
            </Stack>
            {type === UserTypeCode.ADM && (
              <SecondaryButton
                variant="contained"
                onClick={() => downloadXLSX('users')}
                sx={{
                  marginLeft: 'auto',
                }}
                disabled={isDownloading}
              >
                {isDownloading && (
                  <CircularProgress
                    style={{ height: 12, width: 12, marginRight: 10 }}
                  />
                )}
                {isDownloading ? t('common.generatingFile') : t('common.xlsx')}
              </SecondaryButton>
            )}
          </Stack>
          <UsersToolbar
            user={user}
            companies={companies}
            selectedCompanies={selectedCompanies}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
              setTableState(tableName, 'search', searchValue)
            }}
            setSelectedCompanies={(companies) =>
              handleSelectedCompaniesChange(companies)
            }
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
            userStates={userStates}
            userStateValue={userStateValue}
            filterUserState={(event: SelectChangeEvent) => {
              setUserStateValue(event.target.value)
              setTableState(tableName, 'status', event.target.value)
            }}
            applicationReleases={applicationReleases}
            applicationReleaseValue={applicationReleaseValue}
            filterApplicationRelease={(event: SelectChangeEvent) => {
              setApplicationReleaseValue(event.target.value)
              setTableState(tableName, 'release', event.target.value)
            }}
            lastLoginDateFrom={lastLoginDateFrom}
            lastLoginTimeFrom={lastLoginTimeFrom}
            lastLoginDateTo={lastLoginDateTo}
            lastLoginTimeTo={lastLoginTimeTo}
            setLastLoginDateFrom={(date) => {
              setTableState(
                tableName,
                'last-login-date-from',
                JSON.stringify(date),
              )
              setLastLoginDateFrom(date)
            }}
            setLastLoginTimeFrom={(time) => {
              setTableState(
                tableName,
                'last-login-time-from',
                JSON.stringify(time),
              )
              setLastLoginTimeFrom(time)
            }}
            setLastLoginDateTo={(date) => {
              setTableState(
                tableName,
                'last-login-date-to',
                JSON.stringify(date),
              )
              setLastLoginDateTo(date)
            }}
            setLastLoginTimeTo={(time) => {
              setTableState(
                tableName,
                'last-login-time-to',
                JSON.stringify(time),
              )
              setLastLoginTimeTo(time)
            }}
            companiesActive={companiesActive}
            handleCompaniesActiveChange={(_, checked) => {
              setCompaniesActive(checked)
              setTableState(
                tableName,
                'companiesActive',
                checked ? 'true' : 'false',
              )
            }}
            type={type}
          />
          {(isCentralAdmin(user) || isSuperAdmin(user)) && (
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
              style={{ marginBottom: '-22px' }}
            >
              <>
                {shouldRenderChangePointsButton() && (
                  <Button
                    size="small"
                    endIcon={<ChangeCircleIcon />}
                    style={{ textTransform: 'none', marginRight: 5 }}
                    disabled={selectedRows.length === 0}
                    onClick={() =>
                      handleUserChangePointsDialogClickOpen(selectedRows)
                    }
                  >
                    {t('pages.users.changePoints')}
                  </Button>
                )}
                <Button
                  size="small"
                  endIcon={<HighlightOffIcon />}
                  style={{ textTransform: 'none', marginRight: 5 }}
                  disabled={selectedRows.length === 0 || !canDeactivate}
                  onClick={() =>
                    handleUserDeactivationDialogClickOpen(selectedRows)
                  }
                >
                  {t('pages.users.deactivation')}
                </Button>
                <Button
                  size="small"
                  endIcon={<VisibilityIcon />}
                  style={{ textTransform: 'none' }}
                  disabled={selectedRows.length === 0}
                  onClick={() => handleUserHideDialogClickOpen(selectedRows)}
                >
                  {t('pages.users.hide')}
                </Button>
              </>

              {/* <Button
              size="small"
              endIcon={<LockIcon />}
              style={{ textTransform: 'none', marginRight: '15px' }}
              disabled={selectedRows.length === 0}
            >
              {t('pages.users.changePassword')}
            </Button> */}
            </Stack>
          )}
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredUsersList}
            height="calc(100vh - 320px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'userCentralId',
              'companyId',
              'mobile',
              'bankAccount',
              'lastLoginDate',
              'deviceOsVersion',
              'deviceModelName',
            ]}
            toggleVisibility={setColumnsVisibility}
            handleSelectedRows={setSelectedRows}
          />
          <UserAddDialog
            open={openUserAddDialog}
            handleClose={handleUserAddDialogClose}
            type={type}
          />
          {userId && (
            <UserEditDialog
              open={openUserEditDialog}
              handleClose={handleUserEditDialogClose}
              type={type}
              userId={userId}
            />
          )}
          {userId && (
            <UserDetailsDialog
              open={openUserDetailsDialog}
              handleClose={handleUserDetailsDialogClose}
              userId={userId}
            />
          )}
          <UserChangePointsDialog
            users={selectedUserIds}
            open={openUserChangePointsDialog}
            handleClose={handleUserChangePointsDialogClose}
            type={type}
          />
          <UserDeactivationDialog
            users={selectedUserIds}
            open={openUserDeactivationDialog}
            handleClose={handleUserDeactivationDialogClose}
          />
          <UserHideDialog
            users={selectedUserIds}
            open={openUserHideDialog}
            handleClose={handleUserHideDialogClose}
          />
          {importName && (
            <ImportDialog
              open={openImportDialog}
              handleClose={handleImportDialogClose}
              name={importName}
              param1={importParamOperationType}
              param2={importParamPeriod}
              title={importTitle}
            />
          )}
        </>
      )}
    </>
  )
}

export default UsersList
