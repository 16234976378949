import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { Period } from '../../../../store/Period/types'
import { ChangeEvent } from 'react'

interface LoyaltyParticipantPlansProps {
  clearSearch: () => void
  submitSearch: (value: string) => void
  onChange: (event: { target: { value: string } }) => void
  filterPeriod: (event: SelectChangeEvent) => void
  periods: Period[]
  periodValue: string
  value: string
  participantCentralId: string | null
  setParticipantCentralId: (event: ChangeEvent<HTMLInputElement>) => void
  storeCentralId: string | null
  storeName: string | null
  storeDataLoader: boolean
}

export default function LoyaltyParticipantPlansToolbar(
  props: LoyaltyParticipantPlansProps,
) {
  const { t } = useTranslation()

  const onSearchKeyDown = (e: { keyCode: number }) => {
    // on enter press
    if (e.keyCode === 13) {
      props.submitSearch(props.value)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 10,
        alignItems: 'flex-end',
      }}
    >
      <div style={{ width: '100%', display: 'flex' }}>
        <FormControl size="small" sx={{ marginRight: 1 }}>
          <label>
            {t('pages.loyaltyParticipantPlans.toolbar.filters.period')}
          </label>
          <Select
            id="period-select"
            onChange={props.filterPeriod}
            value={props.periodValue}
            defaultValue={props.periodValue}
          >
            <MenuItem value="all">{t('common.all')}</MenuItem>
            {props.periods.map((period) => (
              <MenuItem value={period.id} key={period.id}>
                {period.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          size="small"
          sx={{ width: '100%', maxWidth: 180, marginRight: 1 }}
        >
          <label>
            {t(
              'pages.loyaltyParticipantPlans.toolbar.filters.participantCentralId',
            )}
          </label>
          <TextField
            variant="outlined"
            size="small"
            value={props.participantCentralId}
            onChange={props.setParticipantCentralId}
            inputProps={{ maxLength: 100 }}
          />
        </FormControl>
        <div
          style={{
            width: '100%',
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'flex-end',
            paddingBottom: '8px',
            color: 'gray',
            fontStyle: 'italic',
            maxWidth: 600,
            marginRight: 1,
          }}
        >
          {' '}
          {props.storeDataLoader && (
            <CircularProgress
              sx={{
                color: 'gray',
                width: '20px !important',
                height: '20px !important',
                marginBottom: '3px',
              }}
            />
          )}
          {!props.storeDataLoader && (
            <>
              {props.storeCentralId ? props.storeCentralId : ''}{' '}
              {props.storeName ? props.storeName : ''}
            </>
          )}
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <TextField
          variant="outlined"
          size="small"
          value={props.value}
          onChange={props.onChange}
          placeholder={t('common.searchPlaceholder')}
          onKeyDown={onSearchKeyDown}
          InputProps={{
            style: {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            } as React.CSSProperties,
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          inputProps={{ maxLength: 100 }}
        />
        <PrimaryButton
          onClick={() => props.submitSearch(props.value)}
          style={{
            padding: '7px 5px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: '40px',
          }}
        >
          {t('common.search')}
        </PrimaryButton>
      </div>
    </div>
  )
}
