import axios from 'axios'
import {
  ChallengeListResponse,
  ChallengeStateListResponse,
  ChallengeStep1Response,
  ChallengeStep1RequestParams,
  ChallengeStep2Response,
  ChallengeStep2RequestParams,
  ChallengeStep3ProductRequestParams,
  ChallengeStep3ProductResponse,
  ChallengeStep3ManufacturerRequestParams,
  ChallengeStep3ManufacturerResponse,
  ChallengeStep4RequestParams,
  ChallengeStep4Response,
  ChallengeUnitListResponse,
  ChallengeDetailsResponse,
  ChallengeLevelsImportParams,
  ChallengeLevelsImportResponse,
  ChallengeResultsImportParams,
  ChallengeResultsImportResponse,
  ChallengeImportTemplateReponse,
  ChallengCompleteResponse,
  ChallengeParametersResponse,
  CheckChallengeProductCodeResponse,
  ChallengeResultListResponse,
  ChallengeFilerReponse,
  ChallengeDifficultyLevelReponse,
  ChallengeStoresResponse,
  ChallengeAnalyticsResponse,
  ChallengeSummaryReportResultsResponse,
  ChallengeTypesResponse,
} from '../store/Challenge/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getChallengeDetails = (
  challengeId: number,
): Promise<ChallengeDetailsResponse> => {
  return axios.get(API_URL + `/api/admin/challenge/preview/${challengeId}`, {
    headers: authHeader(),
  })
}

const getChallengeList = async (
  periodId: number | null,
  challengeStateCode: string | null,
  challengeTypeId: number | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<ChallengeListResponse> => {
  return axios.post(
    API_URL + '/api/admin/challenge/list',
    {
      periodId,
      challengeStateCode,
      challengeTypeId,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getChallengeResultList = async (
  companies: { id: number }[],
  periods: { id: number }[],
  challenges: { id: number }[],
  challengeStateCode: string | null,
  challengeDifficultyLevels: { id: number | null }[],
  challengeTypeId: number | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<ChallengeResultListResponse> => {
  return axios.post(
    API_URL + '/api/admin/challenge/summary',
    {
      companies,
      periods,
      challenges,
      challengeStateCode,
      challengeDifficultyLevels,
      challengeTypeId,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getChallengeStateList = (): Promise<ChallengeStateListResponse> => {
  return axios.get(API_URL + '/api/admin/challenge/state', {
    headers: authHeader(),
  })
}

const getChallengeUnitList = (): Promise<ChallengeUnitListResponse> => {
  return axios.get(API_URL + '/api/admin/challenge/unit', {
    headers: authHeader(),
  })
}

const createOrUpdateChallengeStep1 = async (
  params: ChallengeStep1RequestParams,
): Promise<ChallengeStep1Response> => {
  return await axios.post(API_URL + '/api/admin/challenge', params, {
    headers: authHeader(),
  })
}

const updateChallengeStep2 = async (
  params: ChallengeStep2RequestParams,
): Promise<ChallengeStep2Response> => {
  return await axios.post(API_URL + '/api/admin/challenge/details', params, {
    headers: authHeader(),
  })
}

const updateChallengeStep3Product = async (
  params: ChallengeStep3ProductRequestParams,
): Promise<ChallengeStep3ProductResponse> => {
  return await axios.post(
    API_URL + '/api/admin/challenge/unit/product',
    params,
    {
      headers: authHeader(),
    },
  )
}

const updateChallengeStep3Manufacturer = async (
  params: ChallengeStep3ManufacturerRequestParams,
): Promise<ChallengeStep3ManufacturerResponse> => {
  return await axios.post(
    API_URL + '/api/admin/challenge/unit/manufacturer',
    params,
    {
      headers: authHeader(),
    },
  )
}

const getChallengeParameters =
  async (): Promise<ChallengeParametersResponse> => {
    return await axios.get(
      API_URL + `/api/admin/config/parameter/CHALLENGE_PARAMETERS`,
      {
        headers: authHeader(),
      },
    )
  }

const updateChallengeStep4 = async (
  params: ChallengeStep4RequestParams,
): Promise<ChallengeStep4Response> => {
  return await axios.post(API_URL + '/api/admin/challenge/points', params, {
    headers: authHeader(),
  })
}

const importUserChallengeLevels = async (
  params: ChallengeLevelsImportParams,
): Promise<ChallengeLevelsImportResponse> => {
  return await axios.post(
    API_URL + '/api/admin/challenge/import/user-challenge-target',
    params,
    {
      headers: authHeader(),
    },
  )
}

const importUserChallengeResults = async (
  params: ChallengeResultsImportParams,
): Promise<ChallengeResultsImportResponse> => {
  return await axios.post(
    API_URL + '/api/admin/challenge/import/user-challenge-result',
    params,
    {
      headers: authHeader(),
    },
  )
}

const getChallengeImportTemplate = (
  challengeId: number,
): Promise<ChallengeImportTemplateReponse> => {
  return axios.get(API_URL + `/api/admin/challenge/template/${challengeId}`, {
    headers: authHeader(),
  })
}

const challengeComplete = async (
  challengeId: number,
): Promise<ChallengCompleteResponse> => {
  return axios.post(
    API_URL + '/api/admin/challenge/complete',
    {
      challengeId,
    },
    {
      headers: authHeader(),
    },
  )
}

const checkChallengeProductCode = async (
  productCode: string,
): Promise<CheckChallengeProductCodeResponse> => {
  return axios.post(
    API_URL + '/api/admin/challenge/unit/product/code/check',
    {
      productCode,
    },
    {
      headers: authHeader(),
    },
  )
}

const getChallengeFilter = (): Promise<ChallengeFilerReponse> => {
  return axios.get(API_URL + '/api/admin/challenge/list/simple', {
    headers: authHeader(),
  })
}

const getChallengeDifficultyLevel =
  (): Promise<ChallengeDifficultyLevelReponse> => {
    return axios.get(API_URL + '/api/admin/challenge/difficulty-level', {
      headers: authHeader(),
    })
  }

const getChallengeStores = async (
  challenges: { id: number }[],
  companies: { id: number }[],
  users: { id: number }[],
  challengeTypeId: number | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<ChallengeStoresResponse> => {
  return axios.post(
    API_URL + '/api/admin/challenge/stores',
    {
      challenges,
      companies,
      users,
      challengeTypeId,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getChallengeChartResult = async (
  challenges: { id: number }[],
  challengeTypeId: number | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<ChallengeAnalyticsResponse> => {
  return axios.post(
    API_URL + '/api/admin/challenge/chart/result',
    {
      challenges,
      challengeTypeId,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getChallengeSummaryReport = (
  challengeId: number,
): Promise<ChallengeSummaryReportResultsResponse> => {
  return axios.get(
    API_URL + `/api/admin/challenge/summary/report/${challengeId}`,
    {
      headers: authHeader(),
    },
  )
}

const getChallengeTypes = (): Promise<ChallengeTypesResponse> => {
  return axios.get(API_URL + `/api/admin/challenge/type/list`, {
    headers: authHeader(),
  })
}

const exportedObject = {
  getChallengeDetails,
  getChallengeList,
  getChallengeStateList,
  getChallengeUnitList,
  createOrUpdateChallengeStep1,
  updateChallengeStep2,
  updateChallengeStep3Product,
  updateChallengeStep3Manufacturer,
  updateChallengeStep4,
  importUserChallengeLevels,
  importUserChallengeResults,
  getChallengeImportTemplate,
  challengeComplete,
  getChallengeParameters,
  checkChallengeProductCode,
  getChallengeResultList,
  getChallengeFilter,
  getChallengeDifficultyLevel,
  getChallengeStores,
  getChallengeChartResult,
  getChallengeSummaryReport,
  getChallengeTypes,
}

export default exportedObject
