import axios from 'axios'
import {
  AddUserResponse,
  EditUserResponse,
  UserAddParams,
  UserDataCheckResponse,
  UserDeactivateResponse,
  UserDetailsResponse,
  UserEditParams,
  UserHideResponse,
  UserImportListReponse,
  UserListFilterResponse,
  UserPointsExportXlsxResponse,
  UsersResponse,
  UserStateListResponse,
  UserTypeCode,
} from '../store/User/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getUserImportList = (): Promise<UserImportListReponse> => {
  return axios.get(API_URL + '/api/admin/user/import', {
    headers: authHeader(),
  })
}

const getUsers = async (
  companies: { id: number }[],
  userStateId: number | null,
  userTypeCode: UserTypeCode,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
  loginDateFrom: string | null,
  loginDateTo: string | null,
  applicationVersionId: number | null,
): Promise<UsersResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user/list',
    {
      companies,
      userStateId,
      userTypeCode,
      search,
      sortBy,
      sortOrder,
      per,
      page,
      loginDateFrom,
      loginDateTo,
      applicationVersionId,
    },
    {
      headers: authHeader(),
    },
  )
}

const addUser = async (params: UserAddParams): Promise<AddUserResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user/create',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const editUser = async (params: UserEditParams): Promise<EditUserResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user/edit',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const getUserDetails = async (userId: number): Promise<UserDetailsResponse> => {
  return await axios.get(API_URL + `/api/admin/user/preview/${userId}`, {
    headers: authHeader(),
  })
}

const userDeactivate = async (
  users: { id: number }[],
): Promise<UserDeactivateResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user/deactivate',
    {
      users,
    },
    {
      headers: authHeader(),
    },
  )
}

const userHide = async (users: { id: number }[]): Promise<UserHideResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user/visible',
    {
      users,
    },
    {
      headers: authHeader(),
    },
  )
}

const getUserStateList = async (): Promise<UserStateListResponse> => {
  return await axios.get(API_URL + '/api/admin/user/state', {
    headers: authHeader(),
  })
}

const getUserListFilter = async (): Promise<UserListFilterResponse> => {
  return await axios.get(API_URL + '/api/admin/user/list/simple', {
    headers: authHeader(),
  })
}
const getUserData = async (
  centralId: string,
): Promise<UserDataCheckResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user/check',
    {
      centralId,
    },
    {
      headers: authHeader(),
    },
  )
}

const getUserPointsExportData =
  async (): Promise<UserPointsExportXlsxResponse> => {
    return await axios.get(API_URL + '/api/admin/user/summary/points/report', {
      headers: authHeader(),
    })
  }

const exportedObject = {
  getUserImportList,
  getUsers,
  addUser,
  editUser,
  getUserDetails,
  getUserStateList,
  userDeactivate,
  getUserListFilter,
  userHide,
  getUserData,
  getUserPointsExportData,
}

export default exportedObject
